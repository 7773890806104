QuizView.prototype = new View();
QuizView.prototype.constructor = QuizView.constructor;

function QuizView() {
    this.currentQuestionIndex = 0;
    this.allQuestions = '';
    this.currentQuestionView = null;
    this.modeCount = 0;
    this.activeQuizLanguage = '';
    this.gameMode = false;
    this.forceOnline = (typeof QUIZ_MODE_ONLINE != 'undefined' && QUIZ_MODE_ONLINE == 'true');
    this.attemptLog = [];
    this.lastSentLogIndex = 0;
    this.forceRegisterAttempt = false;
}

QuizView.prototype.render = function(params) {
    this.setDefaultStyles(params);
    this.renderHeader(params);
    this.renderContent(params);
    this.renderFooter(params);
    this.setbodystyle(params);
    this.handleDeviceResize(params);
};

QuizView.prototype.setDefaultStyles = function () {
    $(window).off("resize");
    $('body, #wrapper, #header_wrapper, #header, #content_wrapper, #quiz_wrapper, #footer_wrapper, #footer_wrapper .footer').css({
        "width": "100vw"
    });
    $('body, #wrapper').css({
        "height": "100vh"
    });
    $('#content_wrapper, #quiz_wrapper').css({
        "overflow-y": "auto"
    });
};

QuizView.prototype.handleDeviceResize = function (params) {
    this.setDefaultStyles();
    var THIS = this;
    $(window).off("resize");
    $(window).on("resize", function () {
        $(".title_text").css("width", $(window).width() - 170);
        THIS.setbodystyle(params);
    });
};

QuizView.prototype.setbodystyle = function()
{
    var ParentView = CONTROLLER.getVariable("activeParentView");
    var screenwidth = $(window).width() + "px";
    var screenheight = $(window).height() + "px";
    $("body,#wrapper").css({
        width: screenwidth,
        height: screenheight
    });
    $('#quiz_wrapper').css({
        width: screenwidth,
        height: 'auto'
    });

    $("#content_wrapper").css({
        width: screenwidth,
        height: (screenheight - 63)
    });

    $("#header_wrapper").css({
        width: screenwidth + "px"
    });

    $("#footer_wrapper").css({
        width: $(window).width() + "px"
    });

    $('#header_wrapper').css('width', $(window).width());
    $("body,#wrapper,#quiz_wrapper").css('background', '#FFF');

    var catid = "";
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        catid = QUIZ_RESOURCE_PARAMS.catid;
    }
    else{
        catid = CONTROLLER.getVariable("activecatid");
    }

    $(".title_text").css("width", $(window).width() - 170);

     $('#wrapper').css({'overflow':'hidden'});

    if ($('#header_wrapper').css('position') == 'fixed') {
        if (ParentView != "DashBoardQuiz") {
            if (($(window).width() < 380)) {
                $('#quiz_wrapper').css({ 'margin-top': '50px' })
            }
            else {
                $('#quiz_wrapper').css({ 'margin-top': '55px' });
            }
        }
        else {
            if ($(window).width() < 380) {
                $('#quiz_wrapper').css({ 'margin-top': '12px' });
            }
            else {
                $('#quiz_wrapper').css({ 'margin-top': '12px' });
            }
        }
    }
};

QuizView.prototype.onBack = function()
{
    var THIS = this;
    setTimeout(function() {
        THIS.QuizOnBack();
    }, 300);
};

QuizView.prototype.QuizOnBack = function()
{
    var THIS = this;
    //clears all the quiz related cached data.
    clearAllQuizRelatedCachedData();
    var notificationtype = CONTROLLER.getVariable('notificationType');
    var notificationUrlType = CONTROLLER.getVariable('notificationUrlType');
    var notificationview = CONTROLLER.getVariable('notificationView');
    if ($(".solution_embed_wrapper").length == 1) {
        THIS.removeSolutionWrapper();
        return "";
    }
    var courseid = "";
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        courseid = QUIZ_RESOURCE_PARAMS.courseid;
    }
    else {
        courseid = CONTROLLER.getVariable("activecourseid");
    }
    $('#wrapper').remove();
    $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
    <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
    var searchtext = CONTROLLER.getVariable("activesearchtext");
    if(typeof notificationview != 'undefined' && notificationview == 'true'){
        CONTROLLER.setVariable('notificationView', 'false');
        CONTROLLER.loadView("Notifications");
    }
    else if (typeof QUIZ_EMBED != "undefined" && QUIZ_EMBED == true)
    {
        if (QUIZ_EMBED == true && $(window).width() > $(window).height() && EXEC_MODE == "mobile") {
            Platform.setDeviceOrientation("portrait");
            $(window).on("resize", function() {
                CONTROLLER.loadView("Course", courseid);
                $(window).off("resize");
            });
        } else {
            THIS.gobackToPreviousView(searchtext, courseid);
        }
    }
    else {
        THIS.gobackToPreviousView(searchtext, courseid);
    }
   QUIZ_RESOURCE_PARAMS = "";
};

QuizView.prototype.renderHeader = function(params) {
    var title = this.getTitle(params);
    this.renderTemplate("quiz_header.tl", {'title': title}, "#header_wrapper", true);
};

QuizView.prototype.getTitle = function(params) {
    var courseid = ""; var catid = "";
    var preView = CONTROLLER.getVariable("previousView");
    var myactivity=CONTROLLER.getVariable("myactivity");
    var notificationtype = CONTROLLER.getVariable('notificationType');
    var listallquizzesview= CONTROLLER.getVariable("listallquizzes");
    var quiz_title = CONTROLLER.getVariable("quiz_title");
    if (typeof ALWAYS_SHOW_QUIZ_TITLE != "undefined" && ALWAYS_SHOW_QUIZ_TITLE == "true") {
        return this.getQuizTitle(params.nid);
    }
    if (typeof TEST_COLL_ID != 'undefined' && typeof TEST_COLL_ID['title'] != 'undefined' && TEST_COLL_ID['title'] == "Active Tests") {
        return TEST_COLL_ID['title'];
    }
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        courseid = QUIZ_RESOURCE_PARAMS.courseid;
        catid = QUIZ_RESOURCE_PARAMS.catid;
    }
    else {
        courseid = CONTROLLER.getVariable("activecourseid");
        catid = CONTROLLER.getVariable("activecatid");
    }
    if ((typeof preView !== 'undefined' && preView === "AllSubjects") || (notificationtype != "" && notificationtype == 'action')) {
        if (typeof listallquizzesview !== 'undefined' && listallquizzesview === "true") {
            return QUIZZES_TITLE;
        }
        else if (typeof preView !== 'undefined' && preView === "TestSeries") {
            if (typeof TEST_COLL_ID != 'undefined' && typeof TEST_COLL_ID['title'] != 'undefined' && TEST_COLL_ID['title'] != "") {
                return TEST_COLL_ID['title'];
            }
        }
        else if (typeof params.nid != 'undefined') {
            var nid = params.nid;
            this.allQuestions = this.quiz.getQuiz(nid);
            if ((typeof this.allQuestions.courses != "undefined" && this.allQuestions.courses.length != 0) || (notificationtype != "" && notificationtype == 'action'))
            {
                if(notificationtype != "" && notificationtype == 'action'){
                   courseid = CONTROLLER.getVariable("activecourseid");
                   return this.allQuestions.title;
                }else{
                   var courseid = this.allQuestions.courses[0];
                }
                var course = Platform.getObjectProperty(OBJECT_COURSE, courseid, "content");
                course = $.parseJSON(course);
                if (course != "" && course != null) {
                    return course.title
                }else if(typeof quiz_title != 'undefined' && quiz_title != ''){
                    return quiz_title;
                }
                else {
                     return "Quiz Of the Day";
                }
            }
            else if((typeof myactivity !='undefined' && myactivity =='true') || (typeof params != 'undefined' && typeof params.gamemode != 'undefined' && params.gamemode == true)){
                return this.allQuestions.title;
            }else if(typeof quiz_title != 'undefined' && quiz_title != ''){
                return quiz_title;
            }else {
                return "Quiz Of the Day";
            }
        }else if(typeof quiz_title != 'undefined' && quiz_title != ''){
            return quiz_title;
        }
        else {
            return "Quiz Of the Day";
        }
    }
    else if (typeof preView !== 'undefined' && preView === "TestSeries") {
       if (typeof TEST_COLL_ID != 'undefined' && typeof TEST_COLL_ID['title'] != 'undefined' && TEST_COLL_ID['title'] != "") {
            return TEST_COLL_ID['title'];
        }
    }
    else{
        var courseService = CONTROLLER.getService("Course");
        if(CLIENT_NAME == "Aplet"){
            var quiz_title=this.getQuizTitle(params.nid);
            return quiz_title;
        }
        return courseService.getTitle(catid, courseid, CONTROLLER.getVariable("activegroupid"));
    }

};

QuizView.prototype.renderContent = function (params) {
    try {
        this.quiz_nid = params['nid'];
        this.beginQuiz(params['nid']);
        Client.closeProgressBar();
    } catch (e) {
        Client.closeProgressBar();
        console.log('QuizView - renderContent :' + e.message);
    }
};
QuizView.prototype.getQuizTitle = function(nid) {
    var quiz=this.quiz.getQuiz(nid);
    return quiz.title;
};
QuizView.prototype.renderQuestion = function(type)
{
    var THIS = this;
    var questiontype = "";
    if (typeof this.allQuestions.questions[this.currentQuestionIndex].questiontype == "undefined") {
        questiontype = this.allQuestions.questions[this.currentQuestionIndex].type;
        this.allQuestions.questions[this.currentQuestionIndex].questiontype=questiontype;
    }
    else {
        questiontype = this.allQuestions.questions[this.currentQuestionIndex].questiontype;
    }
    
    if(this.currentQuestionIndex == 0){
        $('#flipQuestionback').addClass("hidden");
    }
    else{
        $('#flipQuestionback, #flipQuestion').removeClass("hidden");
    }

    if(typeof this.allQuestions.repeat_untill_correct != "undefined"){
        var repeat_untill_correct = this.allQuestions.repeat_untill_correct;
    }
    var params = {"quiz": this.allQuestions.questions[this.currentQuestionIndex]
        , "index": this.currentQuestionIndex
        , "total_questions": this.allQuestions.questions.length
        , "type": this.allQuestions.type
        , "replace_item": '#quiz_wrapper'
        , "quizAttrs": this.allQuestions
        , "replace": true
        , "mode": 'all'
        , "questiontype": questiontype
    };
    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
        var id = params.quizAttrs.nid;
        params.replace_item = '#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id;
    }
    if (questiontype == "truefalse") {
        CONTROLLER.loadChildView('Truefalse', function (view) {
            THIS.renderView(type, view, params);
        });
    } else if (questiontype == "matching") {
        CONTROLLER.loadChildView('Match', function (view) {
           THIS.renderView(type, view, params);
        });
    }
    else if(questiontype == "short_answer") {
        CONTROLLER.loadChildView('ShortAnswer', function (view) {
            THIS.renderView(type, view, params);
        });
    }
    else if(questiontype == "long_answer") {
        CONTROLLER.loadChildView('LongAnswer', function (view) {
            THIS.renderView(type, view, params);
        });
    }
    else {
        CONTROLLER.loadChildView('Mcq', function(view) {
           THIS.renderView(type, view, params);
        });
    }

    //clears all the quiz related cached data.
    clearAllQuizRelatedCachedData();

};

QuizView.prototype.renderView = function(type, view, params) {
    var THIS = this;
    view.renderContent(params);
    THIS.currentQuestionView = view;
    $('#certificate_placeholder').show();
    $('#countDown, #revise_back').hide();
    if (type == true) {
        THIS.currentQuestionView.setPreviousData(THIS.allQuestions.questions[THIS.currentQuestionIndex],"",THIS.allQuestions);
    }
    THIS.quizStyleBack();

    //Previous and next call back on swipe
    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
         var id = CONTROLLER.getVariable('breakquizid');
        $('#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id + ' #swipe_wrapper').swipe('enable');
        $('#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id + ' #swipe_wrapper').swipe({
            swipeLeft: function (e) {
                if (!$('.quiz_next').data('clicked')) {
                    $('.quiz_next').data('clicked', true);
                    THIS.quizNext();
                }
            },
            swipeRight: function (e) {
                if (!$('.quiz_back').hasClass('flipQuestionback_light')) {
                    if (!$('.quiz_back').data('clicked')) {
                        $('.quiz_back').data('clicked', true);
                        THIS.quizBack();
                    }
                }
            }
        });
    }
    $("#swipe_wrapper").swipe('destroy');
    $("#swipe_wrapper").swipe({
        swipeLeft: function () {
            if (!$('.quiz_next').data('clicked')) {
                $('.quiz_next').data('clicked', true);
                THIS.quizNext();
            }
        },
        swipeRight: function () {
            if (!$('.quiz_back').hasClass('flipQuestionback_light')) {
                if (!$('.quiz_back').data('clicked')) {
                    $('.quiz_back').data('clicked', true);
                    THIS.quizBack();
                }
            }
        }
    });
    $('.quiz_next, .quiz_back').removeData('clicked');
    var currQuestion = this.allQuestions.questions[this.currentQuestionIndex];
    var currQuestionResult = currQuestion['question_result'];
    var log = 'Question ' + (this.currentQuestionIndex + 1) + ' Started';
    var logExtras = this.getLogExtrasForQuestion(currQuestion, currQuestionResult);
    this.logUserAction(log, logExtras);
    if (typeof MathJax != "undefined") {
        MathJax.Hub.Queue(["Typeset",MathJax.Hub,'quiz_wrapper']);
    }
};

QuizView.prototype.reportRender = function(action)
{
    if (action == true) {
        var answer_result = this.currentQuestionView.checkUserScore('', false);
        this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
        this.questionResultSave(answer_result['user_result_answer']);
    }
    //save quiz results at end
    this.quizResultSave();
        var params = {"report": this.allQuestions
            , "replace_item": '#quiz_wrapper'
            , "replace": true
        };
    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
        var id = CONTROLLER.getVariable('breakquizid');
        params.replace_item = '#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id;
        $('#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_'+id+'#footer_wrapper').addClass('hidden');
    }
    CONTROLLER.loadChildView('Report', function(view) {
        view.render(params);
    });
};



QuizView.prototype.quizNext = function(button) {
    //load the next question
    if ((this.allQuestions.questions.length > this.currentQuestionIndex + 1)) {
        //get current question result
        if(this.allQuestions.questions[this.currentQuestionIndex]["type"] != "truefalse") {
            var answer_result = this.currentQuestionView.checkUserScore(button, false,this.allQuestions);
            if (answer_result['user_result_answer'].is_correct == 1) {
                this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
                this.questionResultSave(answer_result['user_result_answer']);
                this.currentQuestionIndex = this.currentQuestionIndex + 1;
                this.renderQuestion(true);
            } else {
                //submitting quiz without filling option
                if (answer_result['user_result_answer'].is_correct == 0 && answer_result['user_result_answer'].is_correct == 2) {
                    alert('Please fill the option before submitting');
                } else {
                    alert('Please enter correct answer');
                }
				$('.quiz_next, .quiz_back').removeData('clicked');
            }
        }
    } else {
        //printing report form and updating quiz results
        this.reportRender(true);
    }
};


QuizView.prototype.quizBack = function(button) {
    var log = 'Clicked previous button';
    this.logUserAction(log);
    if (this.currentQuestionIndex >= 1) {
         var answer_result = this.currentQuestionView.checkUserScore(button, false,this.allQuestions);
         this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
        this.currentQuestionIndex = this.currentQuestionIndex - 1;
        this.renderQuestion(true);
    }

};

QuizView.prototype.quizStyleBack = function(button) {
    if(this.currentQuestionIndex == 0){
        $('#flipQuestionback').addClass("hidden")
    }
    else{
        $('#flipQuestionback, #flipQuestion').removeClass("hidden")
    }
};


QuizView.prototype.renderFooter = function(params) {
    var THIS = this;
    if($("#footer_wrapper").hasClass("hidden")){
        $("#footer_wrapper").removeClass("hidden");
    }
    this.renderTemplate("quiz_footer.tl", "", "#footer_wrapper", true);
    if(CONTROLLER.targetViewname == "Certificatequiz"
            && (typeof ENABLE_QUIZ_CLEAR_RESPONSE != "undefined" && ENABLE_QUIZ_CLEAR_RESPONSE == "true")
            && (typeof params.gamemode == 'undefined' || params.gamemode == false)){
        $('#flipQuestion_clearresponse').css('display','block');
    }
    if(typeof BREAK_MODE != "undefined" && BREAK_MODE == "true"){
        this.renderTemplate("quiz_footer.tl", "", "#quiz_breaks_outer_wrapper #footer_wrapper", true);
        $('.flipQuestion_endquiz').css('display','inline-block');
    }

    //Prebview and next call back
    $('.quiz_back').click(function(e) {
        var element = $(this);
        if($(".break_dialog").length > 0){
            $(".break_dialog").css("opacity","1");
        }
        if (element.data('clicked')) {
            // Previously clicked, stop actions
            e.preventDefault();
            e.stopPropagation();
        } else {
            element.data('clicked', true);
            if (!$(this).hasClass('flipQuestionback_light')) {
                THIS.quizBack();
            }
        }
    });

    $('.quiz_next').click(function(e) {
        var element = $(this);
        if (element.data('clicked')) {
            // Previously clicked, stop actions
            e.preventDefault();
            e.stopPropagation();
        } else {
            element.data('clicked', true);
            THIS.quizNext();
        }
    });


    // for showing the correct options
    $('.correctOption').click(function(e) {
        var id = $(this).attr('nid');
        if (THIS.allQuestions.questions[THIS.currentQuestionIndex].questiontype == "multichoice") {
            THIS.currentQuestionView.showCorrectOptions(THIS.allQuestions.questions[THIS.currentQuestionIndex].alternatives);
        } else {
            THIS.currentQuestionView.showCorrectOptions(THIS.allQuestions.questions[THIS.currentQuestionIndex]);
        }
    });
    //show legend bar in menu
    $('.quiz_menu_button').click(function(e) {
        $('#question_nav_show').remove();
        if (THIS.show_legend(this) != true) {
            $(this).addClass('active_legend');
            e.stopImmediatePropagation();
        }
        return false;
    });

    $('#gameinfo_wrapper').click(function(e) {
        THIS.getQuizInstructions();
        e.stopPropagation();
        return false;
    });

    //clearing the options selected by users
    $('#flipQuestion_clearresponse').click(function(){
        THIS.clearOptionsSelected();
    });

};

QuizView.prototype.beginQuiz = function(nid)
{
    var THIS = this;
    this.allQuestions['questions'] = [];
    this.allQuestions['quiz_result'] = [];
    this.attemptLog = [];
    //get quiz attribuytes
    this.allQuestions = this.quiz.getQuiz(nid);

    if(isArray(this.allQuestions)){
        this.allQuestions = this.allQuestions[0];
    }
    if ((Platform.getNetworkState() == true) && (CHECK_QUIZ_ATTEMPTS != undefined && CHECK_QUIZ_ATTEMPTS == "true")) {
        var quiz_attempts = this.quiz.getQuizAttempts(nid);
        THIS.checkOnAttempts(quiz_attempts, nid);
    }
    else if ((Platform.getNetworkState() == false) && (CHECK_QUIZ_ATTEMPTS != undefined && CHECK_QUIZ_ATTEMPTS == "true")) {
        var quiz_attempts = this.quiz.getQuizAttemptsinOffline(nid);
        THIS.checkOnAttempts(quiz_attempts, nid);
    }
    else{
        THIS.renderQuiz(nid);
    }
};

QuizView.prototype.QuizResume = function(quiz_result_id, callback) {
    try {
        var isResume = false;
        var quiz_result = this.quiz.getQuizResult(this.allQuestions.nid);
        if (quiz_result == '' || quiz_result === null) {
            quiz_result = { 'quiz_result': '' };
        }
        if(Platform.getNetworkState() == true && (typeof quiz_result_id != 'undefined' && quiz_result_id != "")){
            var matchFound = false;
            if (quiz_result['quiz_result'] != '') {
                for (var i in quiz_result['quiz_result']) {
                    if (quiz_result['quiz_result'][i]['is_evaluated'] == ''
                        || quiz_result['quiz_result'][i]['is_evaluated'] == 0) {
                        if (quiz_result_id === quiz_result['quiz_result'][i].result_id) {
                            matchFound = true;
                            break;
                        }
                    }
                }
            }
            if (matchFound == false) {
                var quiz_result_from_result_id = this.quiz.getQuizResultFromResultId(quiz_result_id);
                if (!this.checkOnlineQuizResponse(quiz_result_from_result_id)) {
                    if (typeof callback == "function") {
                        callback("error");
                    } else {
                        return "error";
                    }
                }
                quiz_result = {'quiz_result': []};
                quiz_result['quiz_result'].push(quiz_result_from_result_id);
            }
        }

        this.currentQuestionIndex = 0;
        this.allQuestions['quiz_result'] = '';
        this.allQuestions['quiz_result'] = this.generate_results();

        // get resumed Quiz data if result evaluatation is null
        if (quiz_result['quiz_result'] != '') {
            for (var i in  quiz_result['quiz_result']) {
                if (quiz_result['quiz_result'][i]['is_evaluated'] == ''
                    || quiz_result['quiz_result'][i]['is_evaluated'] == 0) {
                    if (quiz_result['quiz_result'][i].result_id.indexOf("temp_") > -1 || quiz_result_id === quiz_result['quiz_result'][i].result_id) {
                        var timeTaken = 0;
                        this.addIdleTimeToQuizResult(quiz_result['quiz_result'][i]);
                        for (var j  in  quiz_result['quiz_result'][i]) {
                            if (typeof quiz_result['quiz_result'][i][j]['question_result'] !== 'undefined') {
                                isResume = true;
                                var curr_question_result = quiz_result['quiz_result'][i][j]['question_result'];
                                var index = findIndexByKeyValue(this.allQuestions.questions,'nid',curr_question_result.nid);
                                curr_question_result['time_taken'] = parseInt(curr_question_result['time_taken']);
                                this.allQuestions.questions[index]['question_result'] = curr_question_result;
                                this.allQuestions['quiz_result'][index] = {'question_result': curr_question_result};
                                if(typeof this.allQuestions.question_count != 'undefined' && typeof index != 'undefined'){
                                    if(index + 1 == this.allQuestions.question_count){
                                        this.currentQuestionIndex = index
                                    }else{
                                        this.currentQuestionIndex = index + 1;
                                    }
                                }else{
                                    this.currentQuestionIndex = index + 1;
                                }
                                timeTaken = timeTaken + parseInt(curr_question_result['time_taken']);
                            }
                        }
                        this.allQuestions['quiz_result'].attempt_log = quiz_result['quiz_result'][i]['attempt_log'] ? quiz_result['quiz_result'][i]['attempt_log'] : "";
                        this.allQuestions['quiz_result'].result_id = quiz_result['quiz_result'][i].result_id;
                        this.allQuestions['quiz_result'].time_start = parseInt(quiz_result['quiz_result'][i].time_start);
                        if(typeof this.MAX_TIME != 'undefined' && this.MAX_TIME != null && this.MAX_TIME != '') {
                            if(quiz_result['quiz_result'][i]['time_left'] == '' || quiz_result['quiz_result'][i]['time_left'] == '0') {
                                quiz_result['quiz_result'][i]['time_left'] = parseInt(this.allQuestions.time_limit) - timeTaken;
                            }
                            // Checking for Large exam certificate quiz with allow resume disabled  
                            // to show remaining time which comes from server as background time tracked
                            if(Platform.getNetworkState() == true && (this.allQuestions.type == "certificate" && this.allQuestions.quiz_template == "2")
                            && (this.allQuestions.allow_resume == "0")){
                                // show only remaining duration
                                var quiz_result_id = CONTROLLER.getVariable("inprogress_result_id_"+this.allQuestions.nid);
                                if(typeof quiz_result_id != 'undefined' && quiz_result_id != ''){
                                    var remaining_time = this.quiz.getTestRemainingDuration(quiz_result_id);
                                    if(typeof remaining_time !== "undefined" && remaining_time !== ""){
                                        this.MAX_TIME = remaining_time;
                                    }else{
                                        this.MAX_TIME = parseInt(quiz_result['quiz_result'][i]['time_left']);
                                    }
                                }else{
                                    this.MAX_TIME = parseInt(quiz_result['quiz_result'][i]['time_left']);
                                }
                            }else{
                                this.MAX_TIME = parseInt(quiz_result['quiz_result'][i]['time_left']);
                            }
                        }
                    }
                }
            }
        }


        /**
         * Attempt needs to be registered online
         * before starting/resuming the quiz.
         */
        if (this.gameMode || this.forceOnline || this.forceRegisterAttempt) {
            var resumeQuiz = this.gameMode ? 'false' : 'true';
            var attemptLogJson = false;
            var attemptLogs = false;
            if (this.forceOnline) {
                attemptLogs = this.getAttemptLog();
                attemptLogJson = JSON.stringify({ "attempt_log": attemptLogs });
            }
            var resultid = this.quiz.createResultId(this.allQuestions['nid'], this.allQuestions['quiz_result'].result_id, resumeQuiz, attemptLogJson);
            var status = this.updateResultId(resultid);
            if (status == false) {
                return false
            }else{
                if (typeof callback == "function") {
                    callback(true);
                } else {
                    return true;
                }
            }
        } else {
            if (typeof callback == "function") {
                callback(isResume);
            } else {
                return isResume;
            }
        }
    } catch (e) {
    }
};

QuizView.prototype.QuizGameResume = function() {
    this.currentQuestionIndex = 0;
    this.allQuestions['quiz_result'] = this.generate_results();
    this.currentQuestionIndex = 0;
};

QuizView.prototype.questionResultSave = function(question_result) {
    try {
        this.allQuestions['quiz_result'][this.currentQuestionIndex] = {'question_result': question_result};
        if(typeof this.MAX_TIME != 'undefined' && this.MAX_TIME != null && this.MAX_TIME != '')
            this.allQuestions['quiz_result']['time_left'] = this.MAX_TIME;

        var currQuestion = this.allQuestions.questions[this.currentQuestionIndex];
        var currQuestionResult = this.allQuestions['quiz_result'][this.currentQuestionIndex]['question_result'];
        var log = 'Question Saved';
        var logExtras = this.getLogExtrasForQuestion(currQuestion, currQuestionResult);
        this.logUserAction(log, logExtras);

        if (!this.allQuestions['quiz_result']['attempt_log']) {
            this.allQuestions['quiz_result']['attempt_log'] = "";
        }
        var attemptLogs = this.getAttemptLog();
        this.allQuestions['quiz_result']['attempt_log'] += attemptLogs;

        if (this.allQuestions['quiz_result'].result_id.indexOf("temp_") < 0) {
            this.saveOnlineQuizResult()
            var curr_question_result = $.extend(true, {}, this.allQuestions['quiz_result'][this.currentQuestionIndex]['question_result']);
            curr_question_result['attempt_log'] = attemptLogs;
            this.quiz.saveQuestionResult(this.allQuestions['quiz_result'].result_id, this.allQuestions.nid, JSON.stringify(curr_question_result));
        } else {
            this.quiz.quizResultSave(this.allQuestions['quiz_result'].result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']));
        }
        if (typeof this.questionIdleTime != "undefined") {
            this.resetQuestionIdleTime();
        }
    } catch (e) {
    }
};


QuizView.prototype.generate_results = function() {
    //Generate the Result data.
    var generate_result = {};
    var time_start = getSyncedServerTime();
    var temp_resultid = 'temp_quiz_' + this.allQuestions['nid'] + '_' + time_start;
    generate_result['result_id'] = temp_resultid;
    generate_result['nid'] = this.allQuestions['nid'];
    generate_result['vid'] = this.allQuestions['vid'];
    generate_result['uid'] = OBJECT_USER_ID;
    generate_result['time_start'] = time_start;
    generate_result['time_end'] = "";
    generate_result['score'] = "";
    generate_result['is_invalid'] = "";
    generate_result['is_evaluated'] = "";
    generate_result['time_left'] = "";

    // if allow resume is disabled, saving "allow_resume" in quiz result obj, so that after sync
    // if the remaining time for that user is 0, then submit the quiz.
    if(this.allQuestions.type == "certificate" && this.allQuestions.quiz_template == "2" && 
        this.allQuestions.allow_resume == "0"){
            generate_result['allow_resume'] = this.allQuestions.allow_resume;
    }

    return generate_result;
};

QuizView.prototype.updateResultId = function(resultid, attemptLog) {
    if (this.checkOnlineQuizResponse(resultid)) {
        if (attemptLog) {
            if (!this.allQuestions['quiz_result']['attempt_log']) {
                this.allQuestions['quiz_result']['attempt_log'] = "";
            }
            this.allQuestions['quiz_result']['attempt_log'] += attemptLog;
        }

        if (resultid.indexOf("temp_") > -1) {
            this.allQuestions['quiz_result'].result_id = resultid;
            return true;
        }
	    if ((typeof SAVE_QUESTION_RESULT_OFFLINE != 'undefined' && SAVE_QUESTION_RESULT_OFFLINE == 'true')
	        || ((typeof this.allQuestions['allow_offline_attempts'] != 'undefined'
	            && this.allQuestions['allow_offline_attempts'] == '1')
	            || (typeof this.allQuestions['quiz_template'] == 'undefined'
	                || this.allQuestions['quiz_template'] == '2'))) {
            /**
             * 1. For a dated quiz with allow_offline_attempt or quiz_template=2, attempt is registered online
             * before starting the quiz. And from here on the attempt can be continued in offline.
             * 2. gen_result_id stores the value of the generated result id.
             * 3. gen_result_id needs to be replaced with result_id before submitting the quiz.
             */
            this.allQuestions['quiz_result']['gen_result_id'] = resultid;
	        if (typeof this.MAX_TIME != 'undefined' && this.MAX_TIME != null && this.MAX_TIME != '')
	            this.allQuestions['quiz_result'].time_left = this.MAX_TIME;
            if (this.allQuestions['quiz_result']) {
                this.quiz.quizResultSave(this.allQuestions['quiz_result'].result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']));
            }
        } else {
            this.allQuestions['quiz_result']['gen_result_id'] = resultid;
            this.allQuestions['quiz_result']['result_id'] = resultid;
            this.saveOnlineQuizResult();
        }
        return true;
    }
    return false;
};

QuizView.prototype.checkOnlineQuizResponse = function (response) {
    if (typeof response != 'undefined' && (response == USER_LOGOUT_ERROR || response == ERROR_MESSAGE || response == QUIZ_ATTEMPTS_EXCEEDED)) {
        window.clearInterval(QUIZ_TIMER);
        QUIZ_TIMER = null;
        if (response == USER_LOGOUT_ERROR) {
            onAccessDenied();
        } else {
            this.exitFromQuiz(response);
        }
        $('#footer_wrapper').empty();
        return false;
    }
    return true;
};

QuizView.prototype.quizResultSave = function(callback) {
    try {
        $.each(this.allQuestions['quiz_result'], function (index, value) {
            if (typeof value != 'undefined' && typeof value['question_result'] != 'undefined' && value['question_result'].is_skipped > IS_SKIPPED['answered'] && (value['question_result'].user_answer == null
            || value['question_result'].user_answer.length == 0 || Object.keys(value['question_result'].user_answer).length == 0)) {
              value['question_result'].is_skipped = IS_SKIPPED['skipped']; /* marked for review and no user answer then it is considered as skipped */
            }
            else if (typeof value != 'undefined' && typeof value['question_result'] != 'undefined' && value['question_result'].is_skipped > IS_SKIPPED['answered'] && (value['question_result'].user_answer != null
			&& (value['question_result'].user_answer.length > 0 || Object.keys(value['question_result'].user_answer).length > 0))) {
              value['question_result'].is_skipped = IS_SKIPPED['answered']; /* marked for review and user has given answer then it is considered as attempted */
            }
        });
        if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
            if (typeof QUIZ_RESOURCE_PARAMS.type != 'undefined' && (typeof RESOURCE_TYPE_TEST_COLLECTION != 'undefined' && QUIZ_RESOURCE_PARAMS.type == RESOURCE_TYPE_TEST_COLLECTION)) {
                var testCollectionId = QUIZ_RESOURCE_PARAMS.url;
                this.allQuestions['quiz_result']['testCollectionId'] = testCollectionId;
                this.allQuestions['quiz_result']['courseid'] = QUIZ_RESOURCE_PARAMS.courseid;
                this.allQuestions['quiz_result']['resourceid'] = QUIZ_RESOURCE_PARAMS.resourceid;
            }
        }
        this.allQuestions['quiz_result']['score'] = this.getTotalScore();
        this.allQuestions['quiz_result']['is_evaluated'] = 1;
        if (typeof this.MAX_TIME != 'undefined' && this.MAX_TIME != null && this.MAX_TIME != '')
            this.allQuestions['quiz_result']['time_left'] = this.MAX_TIME;
        else
            this.allQuestions['quiz_result']['time_left'] = 0;
        this.allQuestions['quiz_result']['time_end'] = getSyncedServerTime();

        var log = 'Quiz Submit';
        this.logUserAction(log);

        if (!this.allQuestions['quiz_result']['attempt_log']) {
            this.allQuestions['quiz_result']['attempt_log'] = "";
        }
        var attemptLogs = this.getAttemptLog();
        this.allQuestions['quiz_result']['attempt_log'] += attemptLogs;

        if (this.allQuestions['quiz_result'].result_id.indexOf("temp_") > -1) {
            this.quiz.quizResultSave(this.allQuestions['quiz_result'].result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']), testCollectionId);
        }
        this.saveResultstoAdmin(this.allQuestions['quiz_result'].result_id, attemptLogs, callback);
    } catch (e) {
        console.log(e.message);
    }
};

QuizView.prototype.saveResultstoAdmin = function(result_id, attemptLogs, callback) {
    try {
        var THIS = this;
        var status = false;
        if (Platform.getNetworkState() == true) {
            Client.showProgressBar("Submitting your test...");
            if (result_id.indexOf("temp_") == -1) {
                this.saveOnlineQuizResult();
            } else {
                this.replaceResultId(result_id);
            }
            Platform.markQuizAsAttempted(this.quiz_nid);
            if(result_id.indexOf("temp_") == -1){
                THIS.uploadQuizLog();
            }
            var quiz_results_data = JSON.parse(JSON.stringify(this.allQuestions['quiz_result']));
            Platform.uploadQuiz(quiz_results_data, function (uploadStatus) {
                if (uploadStatus == QUIZ_NOT_SUBMITTED || uploadStatus == USER_LOGOUT_ERROR) {
                    THIS.handleSubmitFailure();
                    CONTROLLER.setVariable('quizSubmitErrorMessage', uploadStatus);
                    status = false;

                    if (typeof QUIZ_SUBMIT_FAILURE == 'undefined') {
                        var log = 'Quiz Submit Failed. Retrying Auto Submit';
                        THIS.logUserAction(log);
                        QUIZ_SUBMIT_FAILURE = true;
                    }
                } else {
                    if(result_id.indexOf("temp_") > -1){
                        THIS.uploadQuizLog();
                    }
                    Platform.removeRelation(OBJECT_QUIZ, THIS.quiz_nid, 'quiz_result');
                    Platform.removeObject('quiz_result', result_id);
                    status = true;
                }
                Client.closeProgressBar();
                if (typeof callback == 'function')
                    return callback(status);
            });
        } else {
            CONTROLLER.setVariable('quizSubmitErrorMessage', QUIZ_NO_INTERNET_NOT_SUBMITTED);
            this.handleSubmitFailure();
            Platform.markQuizAsAttempted(this.quiz_nid);
            setTimeout(function () {
                if (typeof callback == 'function') {
                    callback(status);
                    Client.closeProgressBar();
                }
            }, 1000);
        }
    } catch (e) {
        Client.closeProgressBar();
        console.log(e.message);
    }
};

QuizView.prototype.handleSubmitFailure = function () {
    this.allQuestions['quiz_result']['is_evaluated'] = '1';
    if (this.allQuestions['quiz_result']['result_id'].indexOf("temp_") == -1) {
        if (this.allQuestions['quiz_result']['temp_result_id'] && this.allQuestions['quiz_result']['temp_result_id'].indexOf("temp_") > -1) {
            this.allQuestions['quiz_result']['result_id'] = this.allQuestions['quiz_result']['temp_result_id'];
        } else {
            var temp_result_id = 'temp_quiz_' + this.allQuestions['nid'] + '_' + this.allQuestions['quiz_result']['time_start'];
            this.allQuestions['quiz_result']['result_id'] = temp_result_id;
        }
    }
    this.quiz.quizResultSave(this.allQuestions['quiz_result'].result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']));
};

QuizView.prototype.saveOnlineQuizResult = function () {
    var temp_result_id = "";
    if (this.allQuestions['quiz_result'] && typeof this.allQuestions['quiz_result']['temp_result_id'] != "undefined" && this.allQuestions['quiz_result']['temp_result_id'].indexOf("temp_") > -1) {
        temp_result_id = this.allQuestions['quiz_result']['temp_result_id'];
    } else {
        temp_result_id = 'temp_quiz_' + this.allQuestions['nid'] + '_' + this.allQuestions['quiz_result']['time_start'];
        this.allQuestions['quiz_result']['temp_result_id'] = temp_result_id;
    }
    this.quiz.quizResultSave(temp_result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']));
};

QuizView.prototype.replaceResultId = function (result_id) {
    /**
     * 1. gen_result_id needs to be replaced with result_id before submitting the quiz.
     * This is done only for quizzes with attempt being registered on start/resume of the quiz.
     * 2. temp_result_id is used by App Platform team to update the result obtained from server after submit api call. temp_result_id stores the result in "temp_quiz_<quizId>_<timestamp>" format.
     */
    if (typeof this.allQuestions['quiz_result']['gen_result_id'] != 'undefined') {
        this.allQuestions['quiz_result']['temp_result_id'] = this.allQuestions['quiz_result']['result_id'];
        this.allQuestions['quiz_result']['result_id'] = this.allQuestions['quiz_result']['gen_result_id'];
        this.quiz.quizResultSave(result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']));
    }
};

$('.match_options_select').each(function () {
        select = this;
        if ($(select).val()) {
            $.each(THIS.question.match, function (key, value) {
                var id = $(select).find(":selected").attr("title");
                if (id == value["match_id"]) {
                    correct = 1;
                } else {
                    wrong_answer = true;
                }

                selected = selected++;
            });
        }
        else{
            wrong_answer = true;
        }
    });

QuizView.prototype.getTotalMarks=function(questions) {
    var tot_marks=0;
 for(var i in  questions){
    tot_marks+= parseInt(questions[i].max_score);
 }
   return tot_marks;
};

QuizView.prototype.getTotalScore = function() {
    var correct = 0;
    var totalLength = this.getTotalMarks(this.allQuestions.questions);
    var answer = "";
    for (var i in  this.allQuestions.questions) {
        if (typeof this.allQuestions.questions[i]['question_result'] !== 'undefined') {

                correct += parseInt(this.allQuestions.questions[i]['question_result']['points_awarded']);
        }
    }
    var user_total_percentage = parseFloat((correct * 100 / totalLength), 2);
    if(user_total_percentage < 0){
        user_total_percentage = 0;
    }
    this.allQuestions['quiz_result']['total_score'] = user_total_percentage;
    return user_total_percentage;
}
;



QuizView.prototype.show_legend = function(button, number) {
    var THIS = this;
    var questions = [];
    if (typeof this.allQuestions.questions !== 'undefined') {
        questions['questions'] = this.allQuestions.questions;
        questions['quiztype'] = this.allQuestions.type;
        questions['questStates'] = IS_SKIPPED;
        this.renderTemplate('nav_list.tl', questions, '#quiz_wrapper', false);
        var catid = "";
        if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
            catid = QUIZ_RESOURCE_PARAMS.catid;
        }
        else {
            catid = CONTROLLER.getVariable("activecatid");
        }
        $('.question_nav_title_no').click(function(e) {
            var page_no = $(this).attr('page');
            THIS.modeCount = parseInt(page_no) + 1;
            THIS.goQuestion(this, page_no);
        });

        $('body').click(function(e) {
            if (!$(e.target).hasClass('question_nav_title_no') && !$(e.target).hasClass('question_nav_show_tilde') && !$(e.target).hasClass('question_nav_title_no') && !$(e.target).hasClass('active_legend') && $(e.target).attr('id') != 'search_icon') {
                $('#question_nav_show').remove();
                $(e.target).removeClass('active_legend');
            }
        });

        $('.quiz_submit').click(function() {
            THIS.quizEnd(this);
        });
    }

};

QuizView.prototype.goQuestion = function(button, number) {
    var answer_result = this.currentQuestionView.checkUserScore(button, true);
    this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
    this.questionResultSave(answer_result['user_result_answer']);
    this.currentQuestionIndex = parseInt(number);
    this.renderQuestion(true);
};


QuizView.prototype.quizEnd = function() {
    if(BREAK_MODE == 'true'){
        $("#quiz_wrapper").css("height",$(window).height())
    }
    var attempt_all = this.allQuestions.all_questions_mandatory;
    var answer_result = this.currentQuestionView.checkUserScore('', true);
    this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
    this.questionResultSave(answer_result['user_result_answer']);
    if (typeof attempt_all != "undefined" && attempt_all == 1) {
        var count = this.getResultsLength();
        if (this.allQuestions.questions.length != count)
        {
            if (this.allQuestions.questions.length - 1 == count) {
                var answer_result = this.currentQuestionView.checkUserScore('', this.revise);
                if (answer_result['user_result_answer'].answer.length == 0) {
                    alert('Please enter the correct answer');
                    return "";
                } else if (answer_result['user_result_answer'].is_skipped == IS_SKIPPED['skipped'] || answer_result['user_result_answer'].is_skipped == IS_SKIPPED['review_skipped']) {
                    alert(SKIP_ERROR);
                    return "";
                }
                else if (answer_result['user_result_answer'].is_skipped == IS_SKIPPED['review_answered'] || answer_result['user_result_answer'].is_skipped == IS_SKIPPED['review_skipped']) {
                    alert(QUIZ_SKIP_ERROR);
                    return;
                }
                else {
                    this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
                    this.questionResultSave(answer_result['user_result_answer']);
                    var skip = this.containsSkip();
                    if (skip != undefined && skip == true) {
                        alert(QUIZ_SKIP_ERROR);
                        return;
                    }
                    this.reportRender(false);
                }
            }
            else {
                alert(QUIZ_SKIP_ERROR);
                return;
            }
        }
        else {
            var skip = this.containsSkip();
            if (skip != undefined && skip == true) {
                alert(QUIZ_SKIP_ERROR);
                return;
            }
            this.reportRender(false);
        }
    }else{
        this.reportRender(false);
    }
};
QuizView.prototype.getResultsLength = function() {
    count = 0;
    var results = this.allQuestions.quiz_result;
    $.each(results, function(index, value) {
        if (value && typeof value['question_result'] != "undefined") {
            count++;
        }
    });
    return count;
};
QuizView.prototype.containsSkip = function() {
    var results = this.allQuestions.quiz_result;
    skipped = false;
    $.each(results, function(index, value) {
        if (value && typeof value['question_result'] != "undefined") {
            if (value['question_result'].is_skipped > IS_SKIPPED['answered'] && $.isEmptyObject(value['question_result']['user_answer'])) {
                skipped = true;
            }
        }
    });
    return skipped;
};
QuizView.prototype.getQuizAttempts = function() {
     var quiz_result = this.quiz.getQuizResult(this.allQuestions.nid);
     return quiz_result['quiz_result'].length;
};

//get quiz instructions in dialogue
QuizView.prototype.getQuizInstructions = function (lang) {
    var quiz_instructions = this.getActiveLangInstructions(lang);
    if (typeof quiz_instructions.instructions != 'undefined' && quiz_instructions.instructions != "") {
        quiz_instructions.instructions = this.replaceInstructionPlaceholders(quiz_instructions.instructions);
        if ($('.instructions_outer_wrapper').length == 0) {
            $('body').append('<div class="instructions_outer_wrapper"></div>');
        }
        $('.instructions_outer_wrapper').dialog({
            resizable: false,
            modal: true,
            height: 500,
            width: 361,
            dialogClass: 'dlgfixed',
            position: "center",
            closeOnEscape: false
        });
        this.renderTemplate("quiz_start_instruction.tl", quiz_instructions, ".instructions_outer_wrapper", true);
        this.bindQuizLanguageChange();
        if (typeof this.allQuestions.language != 'undefined' && this.allQuestions.language.length > 1) {
            $('#selectQuizLang_wrapper').removeClass('hidden');
            var select_height = $('#selectQuizLang_wrapper').height() + 10;
            $('.instructions_inner_wrapper').css({ 'padding-bottom': select_height + 'px' });
            this.bindQuizChooseLanguage();
            this.bindContinueClick(true);
        } else {
            this.bindContinueClick(false);
        }
        $(".dlgfixed").center(false);
        $('.instructions_outer_wrapper').parent().addClass('alert_wrapper_dialog');
        $('.instructions_outer_wrapper #selectedQuizLang, instructions_outer_wrapper #chooseLang').css({'height':'auto'});
        $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
        $('.ui-dialog-buttonpane').css({
            "text-align": "center",
            margin: "15px"
        });
        $('.ui-button').css({ 'display': 'none' });
        $('.inst_title').css({ "font-size": "20px", 'font-family': 'Roboto Bold', 'display': 'inline-block', 'width': '70%', 'font-weight':'600' });
        $('.instructions_outer_wrapper').parent().addClass('alert_wrapper_dialog');
        $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
        $('html body .ui-dialog').css({
            'z-index': '99999999',
            'background': '#ffffff', 'position': 'fixed', 'outline': 'none', 'padding': 0
        });
        $(".ui-dialog-titlebar").hide();
        $('html body .ui-dialog').css({
            "height": $(window).height() + "px", "width": $(window).width() + "px", "display": "block", 'z-index': '99999999', "overflow-x": " auto",
            'background': '#ffffff', 'position': 'fixed', 'outline': 'none', 'overflow-y': 'scroll', "left": 0, "top": 0, "margin-left": "0", "margin-top": "0"
        });

        $('.instructions_inner_wrapper,.instructions_outer_wrapper').css('height', $(window).height() - 71 + "px");
        $('.instructions_outer_wrapper').css({ "margin-top": "20px", "padding": 0 });
        if ($(window).width() < 380) {
            $('.continue, .instructions_inner_wrapper, .inst_title').css({ 'font-size': '16px' });
        }
        $('html body .ui-dialog').css({ "height": $(window).height() - 3 + "px" });
        $('.instructions_inner_wrapper img').css({ 'max-width': '100%', 'max-height': '100%' });
        if (typeof MathJax != "undefined") {
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, 'instructions_outer_wrapper']);
        }
  } else {
      $('#gameinfo_wrapper').addClass('hidden');
      this.startQuiz();
  }
};

QuizView.prototype.bindContinueClick = function (checkLanguage) {
    var THIS = this;
    $('.cont_wrapper').unbind("click").bind("click", function () {
        if (checkLanguage == true) {
            var value = $('#selectedQuizLang').find('option:selected').attr('value');
            if (value != '') {
                THIS.startQuizFromInstructionsContinue();
            } else {
                $('.selectQuizLang_text').css('color', 'red');
            }
        } else {
            THIS.startQuizFromInstructionsContinue();
        }
    });
}

QuizView.prototype.startQuizFromInstructionsContinue = function () {
    try{
        var THIS = this;
        if(THIS.forceRegisterAttempt){
            if(Platform.getNetworkState() == true){
                setTimeout(function () {
                    THIS.startQuiz();
                }, 500);
            }else{
                alert(INTERNET_ERROR, "", true);
                return;
            }
        }else{
            THIS.startQuiz();
        }
        $('.instructions_outer_wrapper').dialog("close");
        $('#quiz_inst_overlay, .instructions_outer_wrapper').remove();
    }catch(e){
        console.log("function : startQuizFromInstructionsContinue - " + e.message);
    }
};

QuizView.prototype.bindQuizLanguageChange = function () {
    var THIS = this;
    $('#selectedInsLang').on('change', function (e) {
        $('#selectedInsLang').off();
        $('.instructions_outer_wrapper').dialog("close");
        $('#quiz_inst_overlay, .instructions_outer_wrapper').remove();
        THIS.getQuizInstructions(this.value);
    });
};

QuizView.prototype.bindQuizChooseLanguage = function () {
    var THIS = this;
    $('#selectedQuizLang').on('change', function (e) {
        if (this.value != '') {
            $('.selectQuizLang_text').css('color','#000');
            THIS.activeQuizLanguage = this.value;
            CONTROLLER.setVariable('activeQuizLanguage', this.value);
        }
    });
};

QuizView.prototype.getActiveLangInstructions = function (lang) {
    if (typeof lang == 'undefined') {
        lang = '';
    }
    var instructions_json = {
        'title': this.allQuestions.title,
        'instructions': this.allQuestions.quiz_instruction,
    }
    if (typeof this.allQuestions.language != 'undefined' && this.allQuestions.language.length > 1) {
        instructions_json.defaultLang = this.quiz.getQuestionLocaleInfo(this.allQuestions.language, this.activeQuizLanguage);

        if (typeof this.allQuestions.updatedLocale != 'undefined' && typeof this.allQuestions.updatedLocale.quiz_instruction != 'undefined') {
            var updatedLocale = this.allQuestions.updatedLocale.quiz_instruction;
            if (Object.keys(updatedLocale).length > 1) {
                instructions_json.localeInfo = this.quiz.getQuestionLocaleInfo(Object.keys(updatedLocale), lang);
                for (var i in updatedLocale) {
                    if (i == lang) {
                        instructions_json.instructions = updatedLocale[i];
                        break;
                    }
                }
            }
        }
    }
    return instructions_json;
}

QuizView.prototype.replaceInstructionPlaceholders = function(instruction){
   var quiz = this.allQuestions;
   var max_attempts = "";
    var user_attempts = '';
    max_attempts = quiz.takes;
    user_attempts = quiz.attempts;
    var activeContent_type = CONTROLLER.getVariable("content_type");
    if (typeof activeContent_type != 'undefined' && typeof OBJECT_TEST_COLLECTION != "undefined" && activeContent_type == OBJECT_TEST_COLLECTION) {
        max_attempts = TEST_COLLECTION_MAX_ATTEMPTS;
        user_attempts = TEST_COLLECTION_USER_ATTEMPTS;
    }
   instruction = instruction.replace(/@user_attempt/g, user_attempts + 1);
   instruction = instruction.replace(/@max_attempt/g, max_attempts);
   instruction = instruction.replace(/@test_time/g, Math.round(quiz.time_limit/60));
   instruction = instruction.replace(/@pass_marks/g, quiz.pass_rate);
   instruction = instruction.replace(/@total_question/g, quiz.question_count);
   return instruction;
}

QuizView.prototype.gobackToPreviousView = function(searchtext, courseid)
{
    var preView = CONTROLLER.getVariable("previousView");
    var  listallquizzesview = CONTROLLER.getVariable("listallquizzes");
    var myactivity=CONTROLLER.getVariable("myactivity");
    var notificationtype = CONTROLLER.getVariable('notificationType');
    var notificationUrlType = CONTROLLER.getVariable('notificationUrlType');
    var quiz_tab_view = CONTROLLER.getVariable('tab_view');
    var quizStartPlanner = CONTROLLER.getVariable("quiz_start_planner");
    CONTROLLER.setVariable('tab_view','');
    if ($(".solution_embed_wrapper").length == 1) {
        backFromSolution();
        return;
    }
    if (notificationtype != "" && notificationtype == "action" && notificationUrlType != "course") {
        CONTROLLER.loadView("AllSubjects");
        CONTROLLER.setVariable('notificationType', "");
    } else if (typeof listallquizzesview !== 'undefined' && listallquizzesview === "true") {
        CONTROLLER.loadView("ListAllQuizzes");
        CONTROLLER.setVariable("listallquizzes","");
    } else if (typeof quizStartPlanner != 'undefined' && quizStartPlanner == "true") {
        CONTROLLER.setVariable("quiz_start_planner", "false");
        CONTROLLER.loadView("Planner");
        CONTROLLER.setVariable("preView" , "");
    } else if(typeof preView !== 'undefined' && preView === "AllSubjects" && quiz_tab_view !== 'quiz' ){
        if (typeof myactivity !== 'undefined' && myactivity === "true") {
            CONTROLLER.loadView("MyActivities");
            CONTROLLER.setVariable("myactivity", "");
             $('#header_wrapper').css({'position':''});
        }else {
            CONTROLLER.loadView("AllSubjects");
            CONTROLLER.setVariable("previousView", "");
        }
    } else if (typeof preView !== undefined && preView === "TestSeries") {
        if(Platform.getNetworkState() == true){
            CONTROLLER.loadView("TestSeries");
            CONTROLLER.setVariable("previousView","");
        }else{
            CONTROLLER.loadView("AllSubjects");
            CONTROLLER.setVariable("previousView", "");
        }
    } else if (typeof searchtext != "undefined" && searchtext != "") {
        CONTROLLER.setVariable("activesearchtext", "");
        CONTROLLER.loadView("Search", searchtext);
    } else if(typeof preView !== undefined && preView === "Category"){
        var catid = CONTROLLER.getVariable("activecatid");
        CONTROLLER.loadView("Category", catid);
        CONTROLLER.setVariable("previousView","");
    } else {
        CONTROLLER.loadView("Course", courseid);
    }
};

QuizView.prototype.checkOnAttempts = function(quiz_attempts, nid) {
            var quiz_res = ""; var attempts = 0;   var questions = '';
            var question_result = ""; var THIS = this;
            if (quiz_attempts != undefined) {
                if (Platform.getNetworkState() == false) {
                    attempts = quiz_attempts;
                }
                else{
                    attempts = quiz_attempts['attempts'].total_attempts;
                }
            }
            this.allQuestions.attempts = attempts;
            if (this.allQuestions.takes != 0 && attempts >= this.allQuestions.takes) {
                 questions = this.quiz.getQuestionsForQuiz(nid, this.allQuestions.changed);
                 this.allQuestions['questions'] = questions['question'];
                var results = this.quiz.getQuizResult(nid);
                if (results != '' && results != null && results['quiz_result'].length != 0) {
                    quiz_res = results['quiz_result'][results['quiz_result'].length - 1];
                    THIS.allQuestions.quiz_result = quiz_res;
                     $.each(quiz_res, function (key, value) {
                       if(!isNaN(key)){
                           THIS.allQuestions['questions'][key].question_result = quiz_res[key].question_result;
                       }
                     });
                }
                var params = {"report": THIS.allQuestions
                    , "replace_item": '#quiz_wrapper'
                    , "replace": true
                };
        if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
            var id=CONTROLLER.getVariable('breakquizid');
            params.replace_item = '#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id;
            $('#footer_wrapper').remove();
        }
                CONTROLLER.loadChildView('Report', function(view) {
                    view.render(params);
                });
            }
            else {
                this.renderQuiz(nid);
            }
};

QuizView.prototype.startQuiz = function () {
    if (this.currentQuestionIndex >= this.allQuestions.questions.length) {
        this.currentQuestionIndex = this.currentQuestionIndex - 1;
        this.renderQuestion(true);
    } else {
        this.renderQuestion(false);
    }
}

QuizView.prototype.renderQuiz = function(nid) {
     var questions = '';
    //load all questions
    questions = this.quiz.getQuestionsForQuiz(nid, this.allQuestions.changed);
    this.allQuestions['questions'] = questions['question'];
    //resume quiz from start
    var resume = this.QuizResume();
    if (resume == true) {
		var log = 'Quiz Resumed';
		this.logUserAction(log);
        this.startQuiz();
    } else if (resume == false) {
		var log = 'Quiz Started';
		this.logUserAction(log);
        this.getQuizInstructions();
    }
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        if (typeof OBJECT_TEST_COLLECTION != "undefined" && QUIZ_RESOURCE_PARAMS.type == OBJECT_TEST_COLLECTION) {
            var content_type = OBJECT_TEST_COLLECTION;
        } else {
             var content_type = OBJECT_QUIZ;
         }
        var currentTime = Math.floor(getSyncedServerTime() / 1000);
        var videodetails = '{"totalduration":2,"duration":1,"count":"1","timestamp":"","updatedtime":"","createdtime":"","uid":"' + OBJECT_USER_ID + '","resourceid":"' + CONTROLLER.getVariable("activeresourceid") + '","starttime":"' + currentTime + '","endtime":"0","agent":"mobile"' + ',"courseid":"' + QUIZ_RESOURCE_PARAMS.courseid + '","groupid":"' + QUIZ_RESOURCE_PARAMS.groupid + '","categoryid":"' + QUIZ_RESOURCE_PARAMS.catid + '","chapterid":"' + QUIZ_RESOURCE_PARAMS.chapterid + '","session":"' + OBJECT_USER_ID + '-' + currentTime + '","type":"' + content_type + '"}';
        OBJECT_VIDEO_DETAILS = $.parseJSON(videodetails);
        OBJECT_VIDEO_DETAILS.viewmode = CONTROLLER.getVariable("resourcevvhmode");
        Platform.addOrUpdateVideoViewHistory(videodetails);
    }
};


QuizView.prototype.clearOptionsSelected = function(nid) {
    var THIS = this;
    var qsn_type = THIS.allQuestions.questions[THIS.currentQuestionIndex].questiontype;
    if (qsn_type == "multichoice") {
        if (THIS.allQuestions.questions[THIS.currentQuestionIndex].choice_multi == 0) {
            $('.form-radio').attr('checked', false);
        } else {
            $('.form-checkbox').attr('checked', false);
        }
       $('.option_selected').removeClass('correctAnswer wrongAnswer selectedOption');
    } else if (qsn_type == "matching") {
        if (THIS.allQuestions.questions[THIS.currentQuestionIndex].match_type == "matrix") {
            $('.form-checkbox').attr('checked', false);
        } else if (THIS.allQuestions.questions[THIS.currentQuestionIndex].match_type == "sequence") {
            $.each(THIS.allQuestions.questions[THIS.currentQuestionIndex].matchanswers, function(key, value) {
                var temp = $('#' + value["match_id"]);
                $("#sortable").append(temp);
            });
        }
        else {
            $(".match_option_choose dt span,.match_option_choose dt span p").html("Choose the Answer");
            $(".match_option_choose dt a .user_option").attr("id","");
        }
    }else if(qsn_type == "truefalse"){
             $('.form-radio').attr('checked', false);
             $('.option_selected').removeClass('correctAnswer wrongAnswer selectedOption');
    }else if(qsn_type == "short_answer"){
        var ansr_eval = THIS.allQuestions.questions[THIS.currentQuestionIndex].correct_answer_evaluation;
        if (ansr_eval == 4 || ansr_eval == 5) {
            $('#edit-tries option:nth(0)').prop("selected","selected");
        }else if(ansr_eval == 6){
             $('.short-radio-input').attr('checked', false);
        }else{
            $("#edit-tries").val('');
        }
    }else if(qsn_type == "long_answer"){
         $(".textarea_resizable").val('');
    }

    if (THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']) {
        THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'].is_correct = 0;
        THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'].points_awarded = 0;
        if (typeof THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'].is_skipped != "undefined") {
            if (THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'].is_skipped == IS_SKIPPED['review_answered']
                || THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'].is_skipped == IS_SKIPPED['review_skipped']) {
                THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'].is_skipped = IS_SKIPPED['review_skipped'];
            } else {
                THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'].is_skipped = IS_SKIPPED['skipped'];
            }
        }
        if (qsn_type == "multichoice") {
            THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['answer'] = [];
            THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['user_answer'] = [];
        }else if (qsn_type == "short_answer" || qsn_type == "long_answer") {
            THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['user_answer'] = "";
        }else if (qsn_type == "matching") {
            if (THIS.allQuestions.questions[THIS.currentQuestionIndex].match_type == "matching") {
                $.each(THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['user_answer'], function (key, value) {
                    THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['user_answer'][key] = "0";
                });
            } else if (THIS.allQuestions.questions[THIS.currentQuestionIndex].match_type == "matrix") {
                THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['answer'] = [];
                THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['user_answer'] = [];
            }
        }
    }
    this.logUserAction("Clear Response");
};


QuizView.prototype.removeSolutionWrapper = function(){
    try {
        hPlayer.dispose();
    } catch (ex) {
    }
    $('.title-overlay-wrapper').removeClass("hidden");
    $("#viewport").attr("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no");
    closeDialog();
    var title=CONTROLLER.getVariable("resourcetitle");
    if (typeof QUIZ_RESOURCE_PARAMS.resourceid != 'undefined' && QUIZ_RESOURCE_PARAMS.resourceid != '') {
        CONTROLLER.setVariable('activeresourceid', QUIZ_RESOURCE_PARAMS.resourceid);
    }
    if (SOLUTION_EMBED == true) {
        if (SOLUTION_LANDSCAPE == false) {
            Platform.setDeviceOrientation("portrait");
        } else {
            Platform.setDeviceOrientation("landscape");
        }
        SOLUTION_EMBED = false;
    } else {
        Platform.setDeviceOrientation("portrait");
    }
    clearTimeout(titleTimer);
    clearInterval(FULLSCREENTIMER);
    clearInterval(FULLSCREENRESIZETIMER);
    $("#viewport").attr("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no");
    if (SOLUTION_LANDSCAPE == true) {
        Platform.setDeviceOrientation("landscape");
    } else {
        Platform.setDeviceOrientation("portrait");
    }

    if ($(window).width() > $(window).height() && EXEC_MODE == "mobile") {
        $(window).on("resize", function() {
            setBodyStyle();
        });
    }
    else {
        setBodyStyle();
    }
    $('.flipQuestion_endquiz').css('display', 'none');
       var height = $(window).height() - 155;
    $("#quizContainer_wrap").css({
        height: height + "px"
    })
    if (typeof title != 'undefined' && title != '') {
        $('.title-overlay-inner').text(title);
    }
}
QuizView.prototype.removeSolutionWrapper = function(){
    try {
        hPlayer.dispose();
    } catch (ex) {
    }
    $('.title-overlay-wrapper').removeClass("hidden");
    $("#viewport").attr("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no");
    closeDialog();
    var title=CONTROLLER.getVariable("resourcetitle");
    if (typeof QUIZ_RESOURCE_PARAMS.resourceid != 'undefined' && QUIZ_RESOURCE_PARAMS.resourceid != '') {
        CONTROLLER.setVariable('activeresourceid', QUIZ_RESOURCE_PARAMS.resourceid);
    }
    if (SOLUTION_EMBED == true) {
        if (SOLUTION_LANDSCAPE == false) {
            Platform.setDeviceOrientation("portrait");
        } else {
            Platform.setDeviceOrientation("landscape");
        }
        SOLUTION_EMBED = false;
    } else {
        Platform.setDeviceOrientation("portrait");
    }
    clearTimeout(titleTimer);
    clearInterval(FULLSCREENTIMER);
    clearInterval(FULLSCREENRESIZETIMER);
    $("#viewport").attr("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no");
    if (SOLUTION_LANDSCAPE == true) {
        Platform.setDeviceOrientation("landscape");
    } else {
        Platform.setDeviceOrientation("portrait");
    }

    if ($(window).width() > $(window).height() && EXEC_MODE == "mobile") {
        $(window).on("resize", function() {
            setBodyStyle();
        });
    }
    else {
        setBodyStyle();
    }
    $('.flipQuestion_endquiz').css('display', 'none');
       var height = $(window).height() - 155;
    $("#quizContainer_wrap").css({
        height: height + "px"
    })
    if (typeof title != 'undefined' && title != '') {
        $('.title-overlay-inner').text(title);
    }
}

QuizView.prototype.allowAttempt = function (msg) {
    var preView = CONTROLLER.getVariable("previousView");
    var previousView = CONTROLLER.getVariable("preView");
    if ((typeof preView !== 'undefined' && preView === "TestSeries")
        || (typeof previousView !== 'undefined' && previousView === "Planner")) {
        if (((typeof this.allQuestions['allow_offline_attempts'] != 'undefined'
            && this.allQuestions['allow_offline_attempts'] == '1')
            || (typeof this.allQuestions['quiz_template'] == 'undefined' || this.allQuestions['quiz_template'] == '2'))) {
            return true;
        } else {
            this.forceOnline = true;
            if (Platform.getNetworkState() == false) {
                if (typeof msg != 'undefined') {
                    this.exitFromQuiz(msg)
                    return false;
                } else {
                    alert(FORCE_ONLINE_QUIZ_MESSAGE);
                    Client.closeProgressBar();
                    return false;
                }
            }
            return true;
        }
    }
    return true;
}

QuizView.prototype.exitFromQuiz = function (msg) {
    var THIS = this;
    if (typeof QUIZ_TIMER != 'undefined') {
        window.clearInterval(QUIZ_TIMER);
        QUIZ_TIMER = null;
    }
    if (typeof QUIZ_MODULE != 'undefined' && QUIZ_MODULE == 'true') {
        $('#footer_wrapper').remove();
        if (typeof QUIZ_END_BUTTON_TEXT != 'undefined') {
            var end_button = QUIZ_END_BUTTON_TEXT;
        } else {
            var end_button = 'Logout'
        }
        if(typeof msg != 'undefined' && msg == QUIZ_ATTEMPTS_EXCEEDED){
            var end_button = QUIZ_END_BUTTON_TEXT;
        }
        var data = { 'message': msg, 'end_button': end_button }
        THIS.renderTemplate("quiz_end_message.tl", data, "#quiz_wrapper", true);
        $('#quizinfo_wrapper').addClass('hidden');
        window.removeEventListener('beforeunload', onBeforeUnloadEvent);
        $('.quiz_end_logout_button').unbind("click").bind("click",function(e) {
            if (typeof FAILURE_REDIRECT_PATH != 'undefined')
                window.location.href = FAILURE_REDIRECT_PATH;
            else
                window.location.href = '/';
        });
    } else {
        replaceBody();
        setTimeout(function () {
            Client.closeProgressBar();
            $('#footer_wrapper').empty();
            var check = alert(msg, function () {
                var preView = CONTROLLER.getVariable("previousView");
                if (Platform.getNetworkState() == true && (typeof preView !== 'undefined' && preView === "TestSeries")) {
                    CONTROLLER.loadView("TestSeries");
                } else {
                    CONTROLLER.loadView("AllSubjects");
                }
            });
        }, 200);
    }
    return;
};

/**
 * To extract the extra information that is needed while logging action performed on a question.
 */
QuizView.prototype.getLogExtrasForQuestion = function (currQuestion, currQuestionResult) {
    try {
        var index = this.currentQuestionIndex;
        if (currQuestionResult) {
            var user_answer = (currQuestionResult['user_answer']) ? currQuestionResult['user_answer'] : "";
            var is_skipped = currQuestionResult.is_skipped;
            var is_skipped_state = PORTAL_IS_SKIPPED_STATES[is_skipped];
        } else {
            var user_answer = "";
            var is_skipped = "";
            var is_skipped_state = PORTAL_IS_SKIPPED_STATES['4'];
        }
        var logExtras = {
            'index': index + 1,
            'nid': currQuestion.nid,
            'vid': currQuestion.vid ? currQuestion.vid : "",
            'user_answer': user_answer,
            'state': is_skipped_state,
        };
        return logExtras;
    } catch (e) {
        console.log('Error in function - getLogExtrasForQuestion : \n' + e.message);
    }
};

/**
 * To log user actions while attempting a quiz.
 */
QuizView.prototype.logUserAction = function (logMessage, logExtras) {
    try {
        if (typeof TRACK_USER_ACTIONS != 'undefined' && TRACK_USER_ACTIONS) {
            var questionInfo = [];
            var message = logMessage;
            if (!logExtras) {
                questionInfo.push("questionnid-");
                questionInfo.push("questionvid-");
                questionInfo.push("options-");
            } else {
                questionInfo.push("questionnid-" + logExtras.nid);
                questionInfo.push("questionvid-" + logExtras.vid);
                var userAnswer = logExtras.user_answer;
                if (typeof userAnswer == "object") {
                    if(isArray(userAnswer)) {
                        userAnswer = userAnswer.join(" ");
                    } else {
                        var temp = Object.keys(userAnswer).map(function (key) {
                            return userAnswer[key];
                        });
                        userAnswer = temp.join(" ");
                    }
                }
                questionInfo.push("options-" + userAnswer);

                if(logMessage == "Question Saved")
                    message = "Question " + (this.currentQuestionIndex + 1) + " " + logExtras.state;
            }

            var resultId = "no-resultid";
            var remainingTime = "";
            if (typeof this.allQuestions['quiz_result'] != "undefined") {
                if (this.allQuestions['quiz_result']['gen_result_id']) {
                    resultId = this.allQuestions['quiz_result']['gen_result_id'];
                } else if (this.allQuestions['quiz_result']['result_id'] && this.allQuestions['quiz_result']['result_id'].indexOf("temp_") < 0) {
                    resultId = this.allQuestions['quiz_result']['result_id'];
                }
                remainingTime = this.allQuestions['quiz_result'].time_left ? this.allQuestions['quiz_result'].time_left : "";
            }

            var currentTime = new Date(getSyncedServerTime()).toString().replace(/.GMT.*/g, '');

            var log = [];
            log.push("ip-address");
            log.push("source-" + this.getUserAgent());
            log.push("uid-" + OBJECT_USER_ID);
            log.push("resultid-" + resultId);
            log.push("quiznid-" + this.allQuestions.nid);
            log.push("quizvid-" + this.allQuestions.vid);
            log = log.concat(questionInfo);
            log.push("remainingtime-" + remainingTime);
            log.push("dateandtime-" + currentTime);
            log.push("message-" + message);

            this.attemptLog.push(log.join());
        }
    } catch (e) {
        console.log('Error in function - logUserAction \n' + log + '\n' + e.message);
    }
};

QuizView.prototype.getUserAgent = function () {
    try {
        var ua = navigator.userAgent.toLowerCase();
        if (typeof DESKTOP_BUILD != 'undefined' && DESKTOP_BUILD == true) {
            return "windows";
        } else if (ua.indexOf("android") > -1 && typeof EXEC_MODE != 'undefined' && EXEC_MODE == "mobile") {
            return "android";
        } else if (ua.indexOf("android") > -1 && typeof EXEC_MODE != 'undefined' && EXEC_MODE == "online") {
            return "mobile web";
        } else {
            return "offline web";
        }
    } catch (e) {
        console.log("function : QuizView - getUserAgent - " + e.message);
    }
};

QuizView.prototype.getLogsFromIndex = function (index) {
    try{
        var logs = [];
        if(this.attemptLog.length > 0){
            logs = this.attemptLog.slice(index, this.attemptLog.length);
        }
        var logsString = "";
        for (var i = 0; i < logs.length; i++){
            logsString += logs[i] + "\r\n";
        }
        return logsString;
    } catch (e) {
        console.log('Error in function - getLogsFromIndex :' + e.message);
    }
};

QuizView.prototype.getAttemptLog = function () {
    if (this.attemptLog.length > 0) {
        var attemptLog = this.getLogsFromIndex(this.lastSentLogIndex);
        this.lastSentLogIndex = this.attemptLog.length;
        return attemptLog;
    }
}

QuizView.prototype.showSolutionForQuestion = function (id, type, duration, questionId) {
    try {
        if (type == 'Document') {
            type = OBJECT_BOOK;
        } else if (type == 'Video') {
            type = OBJECT_VIDEO;
        }
		if (typeof QPM != "undefined" && QPM.widget && QPM.widget == "true") {
            var width = $("#wrapper").width();
            if (type == OBJECT_VIDEO) {
                var height = Math.round(width * 9 / 16);
            } else {
                var height = "";
            }
            var resourcepath = PORTAL_QUESTION_SOLUTION + questionId + "&width=" + width + "&height=" + height;
            $(".portal-iframe-container").remove();
            $("#wrapper").append("<div class='portal-iframe-container'><div class='portal-iframe-close'></div><iframe id='flash_iframe' allowfullscreen frameborder='0' style='display:flex;height:100%;width:100%;' width=" + width + " src=" + resourcepath + "></iframe></div>");
            $(".portal-iframe-close").click(function (e) {
                $(".portal-iframe-container").remove();
                return false;
            });
            return false;
        }
        var params = {
            "resourceid": id, "solution_embed": true, "type": type,
            "activeViewName": CONTROLLER.activeViewName, "activeView": CONTROLLER.activeView, "courseid": CONTROLLER.getVariable("activecourseid"),
            "catid": CONTROLLER.getVariable("activecatid"), "groupid": CONTROLLER.getVariable("activegroupid"),
            "solutionDuration": duration, 'solution_type': type
        };
        var resource = $.parseJSON(Platform.getObjectProperty(type, id, 'content'));
        if (!resource) {
            if (Platform.getNetworkState() == true) {
                var resourceService = CONTROLLER.getService("Resource");
                resource = resourceService.getResourceInfoById(id);
                resource = resource[0];
            } else {
                alert(INTERNET_ERROR);
                Client.closeProgressBar();
                return false;
            }
        }
        if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
            params['courseid'] = QUIZ_RESOURCE_PARAMS.courseid;
            params['catid'] = QUIZ_RESOURCE_PARAMS.catid;
            params['chapterid'] = QUIZ_RESOURCE_PARAMS.chapterid;
        }
        if (params['chapterid'] == "" || typeof params['chapterid'] == 'undefined') {
            params['chapterid'] = resource[OBJECT_CHAPTER] && resource[OBJECT_CHAPTER][0];
        }
        if (params['catid'] == "" || typeof params['catid'] == 'undefined') {
            params['catid'] = resource[OBJECT_CATEGORY] && resource[OBJECT_CATEGORY][0];
        }
        if (params['groupid'] == "" || typeof params['groupid'] == 'undefined') {
            params['groupid'] = resource[OBJECT_GROUP] && resource[OBJECT_GROUP][0];
        }
        params.resourceData = resource;
        Client.showProgressBar("Loading...");
        showSolutionDialog(type);
        setTimeout(function () {
            if (Platform.getNetworkState() == true) {
                var itutView = CONTROLLER.getView("iTutor");
                var isResourcePlayable = itutView.isResourcePlayable(id, params['courseid'], OBJECT_VIDEO, params['catid'], true, params);
                if (isResourcePlayable == false) {
                    Client.closeProgressBar();
                    return false;
                } else {
                    CONTROLLER.setVariable("showpopup_for_unsubscribed_video", '');
                }
            } else {
                alert(INTERNET_ERROR);
                Client.closeProgressBar();
                return false;
            }
            if (type == OBJECT_BOOK) {
                Platform.setDeviceOrientation("portrait");
                resource.page = params.solutionDuration
                resource.solution_embed = params.solution_embed;
                resource.activeView = params.activeView;
                resource.activeViewName = params.activeViewName;
                CONTROLLER.loadView('BookItem', resource);
            } else {
                Platform.setDeviceOrientation("landscape");
                CONTROLLER.loadView("Resource", params);
            }
            Client.closeProgressBar();
        }, 200);
        return false;
    } catch (e) {
        console.log("function - showSolutionForQuestion : " + e.message);
    }
};

QuizView.prototype.addIdleTimeToQuizResult = function (local_result) {
    try {
        var local_result_id = local_result.result_id;
        var questionIdleTimeId = local_result_id + '-' + OBJECT_USER_ID + '-time';
        var questionIdleTime = $.parseJSON(Platform.getObjectProperty("questionIdleTime", questionIdleTimeId, "content"));
        if (questionIdleTime !== null) {
            if (local_result.hasOwnProperty(questionIdleTime.qIndex) == false) {
                local_result[questionIdleTime.qIndex] = {};
                local_result[questionIdleTime.qIndex]['question_result'] = questionIdleTime;
            }
            for (var i in local_result) {
                if (i == questionIdleTime.qIndex) {
                    var currQuestion = local_result[i]['question_result'];
                    if (typeof currQuestion['nid'] != "undefined" && currQuestion['nid'] == questionIdleTime.nid) {
                        currQuestion['answer_timestamp'] = questionIdleTime.answer_timestamp;
                        currQuestion['time_taken'] = parseInt(currQuestion['time_taken']) + parseInt(questionIdleTime.idle_time);
                        local_result['time_left'] = parseInt(local_result['time_left']) - parseInt(questionIdleTime.idle_time);
                        delete currQuestion['idle_time'];
                        delete currQuestion['qIndex'];
                        this.quiz.quizResultSave(local_result_id, local_result.nid, JSON.stringify(local_result));
                        Platform.removeObject("questionIdleTime", questionIdleTimeId);
                    }
                    break;
                }
            }
        }
    } catch (e) {
        console.log("function - addIdleTimeToQuizResult : " + e.message);
    }
};

QuizView.prototype.uploadQuizLog = function () {
    try {
        var attempt_log = this.allQuestions['quiz_result']['attempt_log'];
        var resultid = "";
        if(typeof this.allQuestions['quiz_result']['gen_result_id'] != "undefined"){
            resultid = this.allQuestions['quiz_result']['gen_result_id'];
        }
        else{
            resultid = this.allQuestions['quiz_result']['result_id'];
        }
        if(resultid.indexOf('temp_') > -1 && typeof SUBMITTED_RESULT_ID != 'undefined' && SUBMITTED_RESULT_ID != ''){
            resultid = SUBMITTED_RESULT_ID;
        }
        var callback = function (status) {
            if (status == ERROR_MESSAGE) {
                console.log("Unable to update log to the server - " + ERROR_MESSAGE);
            }
        };
        this.quiz.uploadQuizLog(this.quiz_nid, resultid, attempt_log, "attempt_log", callback);
    } catch(e) {
        console.log("function : QuizView - uploadQuizLog - " + e.message);
    }
};
CertificatequizView.prototype = new QuizView();
CertificatequizView.prototype.constructor = CertificatequizView.constructor;


function CertificatequizView() {
    this.quiz = CONTROLLER.getService("Quiz");
    this.currentQuestionIndex = 0;
    this.allQuestions = '';
    this.currentQuestionView = null;
    this.MAX_TIME = null;
    REMINDER_TIME = null;
    if (typeof QUIZ_TIMER != 'undefined') {
        window.clearInterval(QUIZ_TIMER);
    }
    QUIZ_TIMER = null;
    this.revise = false;
    this.mode = 'none';
    this.lifeInGame = 0;
    this.remainingQuestionTime = 0;
    QUIZ_GAME_TIMER = null;
    QUESTION_TIMER = null;
    COOLDOWN_TIMER = null;
    this.modeCount = 0;
    this.flagCount = [];
    if (typeof TASK_ENABLED != "undefined" && TASK_ENABLED == "true") {
        this.task = CONTROLLER.getService("Task");
    }
};

CertificatequizView.prototype.render = function (params) {
    this.setDefaultStyles(params);
    TASKID = ""; TASK_STATUS = ""; var THIS = this;
    CONTROLLER.setVariable('activeQuizLanguage', '');
    if (typeof TASK_ENABLED != "undefined" && TASK_ENABLED == "true") {
        if (CONTROLLER.activeViewName == "ListTask") {
            TASKID = params.task_id;
            TASK_STATUS = params.status;
        }
    }
    if (typeof params.embed == "undefined" || params.embed != "true") {
        this.renderHeader(params);
    }
    else if (params.embed == "true") {
        QUIZ_EMBED = true;
    }
    if (typeof ZOPIM_CHAT_ENABLE != 'undefined' && ZOPIM_CHAT_ENABLE == 'true') {
        if (typeof hideChat != "undefined") {
            hideChat();
        }
    }
    this.renderContent(params);
    this.renderFooter(params);
    this.setbodystyle(params);
    if (typeof TASK_ENABLED != "undefined" && TASK_ENABLED == "true") {
        if (TASKID != undefined && TASKID != "" && TASK_STATUS != "" && TASK_STATUS == 0)
        {
            if (Platform.getNetworkState() == true) {
                var status = THIS.task.updateTask(TASKID);
                if (status == 403) {
                    onAccessDenied();
                    return;
                }
            }
        }
    }
    if (this.gameMode == true) {
        $('#footer_wrapper').addClass('hidden');
    }
    $('.flipQuestion_endquiz').click(function (e) {
        if (!THIS.allowAttempt()) {
            return false;
        }
        THIS.flagCount = [];
        THIS.quizEnd();
    });
    this.handleDeviceResize(params);
};

CertificatequizView.prototype.renderContent = function (params) {
    try {
        this.quiz_break = "";
        this.quiz_nid = params['nid'];
        if (typeof params.gamemode != 'undefined') {
            this.gameMode = params.gamemode;
            $('.quiz_menu_button ').addClass('hidden');
            $('#lang_wrapper').addClass('hidden');
            $('#gameinfo_wrapper').removeClass('hidden');
        }
        if (typeof params.quiz_break != undefined) {
            this.quiz_break = params.quiz_break;
        }
        this.beginQuiz(params['nid']);
        Client.closeProgressBar();
    } catch (e) {
        Client.closeProgressBar();
        console.log('CertificatequizView - renderContent :'+ e.message);
    }
};


CertificatequizView.prototype.beginQuiz = function(nid)
{
    try {
        var THIS = this;
        var quiz_res = "";
        this.allQuestions['questions'] = [];
        this.allQuestions['quiz_result'] = [];
        this.attemptLog = [];
        //get quiz attribuytes
        this.allQuestions = this.quiz.getQuiz(nid);

        if (isArray(this.allQuestions)) {
            this.allQuestions = this.allQuestions[0];
        }
        if (!this.allowAttempt(FORCE_ONLINE_QUIZ_MESSAGE)) {
            return false;
        }
        this.checkForceRegisterAttempt();
        if ((Platform.getNetworkState() == true) && !this.gameMode && (typeof CHECK_QUIZ_ATTEMPTS != 'undefined' && CHECK_QUIZ_ATTEMPTS == "true")) {
            var currentQuizAttempt = CONTROLLER.getVariable('currentQuizAttempt');
            if (typeof currentQuizAttempt == 'undefined' || currentQuizAttempt == '') {
                var quiz_attempts = this.quiz.getQuizAttempts(nid);
            } else {
                var quiz_attempts = currentQuizAttempt;
                CONTROLLER.setVariable('currentQuizAttempt', '');
            }
            if (quiz_attempts == ERROR_MESSAGE  || quiz_attempts == USER_LOGOUT_ERROR) {
                alert(quiz_attempts);
                Client.closeProgressBar();
                return "";
            }
            // for online test collection if there is offline test then checking offline attempt  //
            var quiz_attempts_offline = this.quiz.getQuizAttemptsinOffline(nid);
            if(!this.forceRegisterAttempt && quiz_attempts_offline >  quiz_attempts['attempts'].total_attempts){
                quiz_attempts['attempts'].total_attempts = quiz_attempts_offline;
            }
            THIS.checkOnAttempts(quiz_attempts, nid);
        }
        else if((Platform.getNetworkState() == false) && !this.gameMode && (typeof CHECK_QUIZ_ATTEMPTS != 'undefined' && CHECK_QUIZ_ATTEMPTS == "true")){
            var quiz_attempts = this.quiz.getQuizAttemptsinOffline(nid);
            if (quiz_attempts == ERROR_MESSAGE) {
                alert(ERROR_MESSAGE);
                Client.closeProgressBar();
                return "";
            }
            THIS.checkOnAttempts(quiz_attempts, nid);
        }
        else {
            this.allQuestions.gameMode = this.gameMode;
            if (this.gameMode == true) {
                //TODO : Currently using the quiz 'takes' obj for Life. Needs to be updated when there is a specific value for Life.
                this.lifeInGame = this.allQuestions.takes;
            }
            THIS.renderQuiz(nid);
        }
    }
    catch (e) {
        console.log('Error in beginQuiz :'+ e);
    }
};

CertificatequizView.prototype.renderQuestion = function(type)
{
    var THIS = this;
    var questiontype = "";
    if (typeof this.allQuestions.questions[this.currentQuestionIndex].questiontype == "undefined") {
        questiontype = this.allQuestions.questions[this.currentQuestionIndex].type;
    }
    else {
        questiontype = this.allQuestions.questions[this.currentQuestionIndex].questiontype;
    }
    this.allQuestions.questions[this.currentQuestionIndex].questiontype = questiontype;
    var params = {"quiz": this.allQuestions.questions[this.currentQuestionIndex]
        , "index": this.currentQuestionIndex
        , "quizAttrs": this.allQuestions
        , "total_questions": this.allQuestions.questions.length
        , "type": this.allQuestions.type
        , "replace_item": '#quiz_wrapper'
        , "replace": true
        , "mode": 'all'
        , "questiontype": questiontype
        , "start_question": getSyncedServerTime()
        , "activeQuizLanguage": this.activeQuizLanguage
    };
    this.activeQuizLanguage = CONTROLLER.getVariable('activeQuizLanguage');
    if(typeof this.allQuestions.questions[this.currentQuestionIndex].updatedLocale != 'undefined'){
        var updatedLocale = this.allQuestions.questions[this.currentQuestionIndex].updatedLocale;
        if (typeof this.allQuestions.language != 'undefined' && this.allQuestions.language.length > 1 && Object.keys(updatedLocale).length > 1) {
            params.localeInfo = this.quiz.getQuestionLocaleInfo(this.allQuestions.language, this.activeQuizLanguage);
        }
    }
    THIS.revise = false;
    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
        var id = params.quizAttrs.nid;
        params.replace_item = '#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id;
        if ($('#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id + '#footer_wrapper').hasClass("hidden")) {
            $('#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id + '#footer_wrapper').removeClass('hidden');
        }
    }

    if (questiontype == "truefalse") {
        CONTROLLER.loadChildView('Truefalse', function (view) {
            THIS.renderView(type, view, params);
        });
    } else if (params.questiontype == "matching") {
        if (typeof this.allQuestions.questions[this.currentQuestionIndex].match_type != "undefined") {
            params.match_type = this.allQuestions.questions[this.currentQuestionIndex].match_type;
        }
        CONTROLLER.loadChildView('Match', function (view) {
            THIS.renderView(type, view, params);
        });
    }
    else if (params.questiontype == "long_answer") {
        CONTROLLER.loadChildView('LongAnswer', function (view) {
            THIS.renderView(type, view, params);
        });
    }
    else if (params.questiontype == "short_answer") {
        CONTROLLER.loadChildView('ShortAnswer', function (view) {
            THIS.renderView(type, view, params);
        });
    }
    else {
        CONTROLLER.loadChildView('Mcq', function (view) {
            THIS.renderView(type, view, params);
        });
    }

    //clears all the quiz related cached data.
    CONTROLLER.setVariable("inprogress_result_id_"+this.allQuestions.nid, "");
    clearAllQuizRelatedCachedData();

};

CertificatequizView.prototype.removeReviseQuestionStatus = function(e)
{
    this.revise = false;
};

CertificatequizView.prototype.addReviseQuestionStatus = function(e)
{
    this.revise = true;
};


CertificatequizView.prototype.countDown = function () {
    if (this.MAX_TIME != null) {
        var THIS = this;
        var now = getSyncedServerTime();
        var lastTimerRunTime = this.allQuestions['lastTimerRunTime'] || now;
        var diff = now - lastTimerRunTime;
        if (diff < -60000) {
            if (typeof QUIZ_TIMER != 'undefined') {
                window.clearInterval(QUIZ_TIMER);
            }
            QUIZ_TIMER = null;
            this.MAX_TIME = null;
            this.exitFromQuiz(QUIZ_TIMER_MISSMATCH);
            return;
        } else if (diff !== 0 && diff < 900) {
            return;
        } else {
            this.allQuestions['lastTimerRunTime'] = now;
        }
	
        var timeDiff = Math.round((now - lastTimerRunTime) / 1000);
		if (timeDiff > this.MAX_TIME) {
            timeDiff = this.MAX_TIME;
        }
        this.MAX_TIME = this.MAX_TIME - timeDiff;
		
        this.allQuestions['quiz_result']['time_left'] = this.MAX_TIME;
        var quiz_close = this.allQuestions['quiz_close'];
        var quiz_always = this.allQuestions['quiz_always'];
        var totalTime = this.allQuestions['time_limit'];
        var attempt_expired_quiz = this.allQuestions['attempt_expired_quiz'];
        if (CONTROLLER.getVariable("onAppResume") == "true") {
            THIS.setRemainingQuizTime(lastTimerRunTime, now);
            CONTROLLER.setVariable("onAppResume", "false");
        }
        this.saveQuestionIdleTime();
        var timeRemain = this.pad(Math.floor((this.MAX_TIME / 3600))) + " : " + this.pad(Math.floor((this.MAX_TIME % 3600) / 60)) + " : " + this.pad(Math.floor((this.MAX_TIME % 3600) % 60));
        if (REMINDER_TIME != null) {
            var remindertime = this.pad(Math.floor((REMINDER_TIME / 3600))) + " : " + this.pad(Math.floor((REMINDER_TIME % 3600) / 60)) + " : " + this.pad(Math.floor((REMINDER_TIME % 3600) % 60));
        }
        if ($('.report_header').hasClass("hidden")) {
            $('.report_header').removeClass("hidden");
        }
        if (REMINDER_TIME != null && timeRemain == remindertime) {
            var minutes = Math.floor(REMINDER_TIME / 60);
            alert("Alert : " + minutes + TIME_REMAINS_MESSAGE);
        }
        var parentElement = '#countDown';
        if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
            parentElement = '#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id + ' #countDown';
        }
        this.renderTemplate('gametimer.tl', '', parentElement, true);
        this.setProgress(parentElement, this.MAX_TIME, totalTime);
        if (this.MAX_TIME <= 0) {
            this.logUserAction('Quiz Time Up');
            if (!this.allowAttempt(QUIZ_NO_INTERNET_TIME_UP)) {
                return;
            }
            alert('Time Up');
            this.saveQuizResult('Time Up!');
            return;
        }
        if(typeof quiz_close != 'undefined' && attempt_expired_quiz != 1 && quiz_always != 1 && quiz_close > 1
            && quiz_close < Math.round((getSyncedServerTime() / 1000))) {
            this.logUserAction('Quiz Time End Reached');
            if (!this.allowAttempt(QUIZ_NO_INTERNET_TIME_UP)) {
                return;
            }
            alert(RUN_OUT_OF_TIME_MESSAGE);
            this.saveQuizResult(RUN_OUT_OF_TIME_MESSAGE);
            return;
        }
    }
};

CertificatequizView.prototype.setProgress = function (parentElement, timeLeft, totalTime) {
    try{
        if($(parentElement).length > 0) {
            var degrees = timeLeft / totalTime * 360;
            var transform = /MSIE 9/.test(navigator.userAgent) ? 'msTransform' : 'transform';
            var elem = $(parentElement + ' .circlePercent')[0];
            var hr = this.pad(Math.floor((timeLeft / 3600)));
            var hr_min = 0, min_sec = 0;
            if (hr > 0) {
                hr_min = '<div>'+this.pad(Math.floor((timeLeft / 3600)))+'<span>hr</span></div>'
                min_sec = '<div>'+ this.pad(Math.floor((timeLeft % 3600) / 60)) + '<span>min</span></div>';
            } else {
                hr_min = '<div>'+this.pad(Math.floor((timeLeft % 3600) / 60))+'<span>min</span></div>'
                min_sec = '<div>'+ this.pad(Math.floor((timeLeft % 3600) % 60)) + '<span>sec</span></div>';
            }
            var separator = "<div>:</div>"
            var displayTime = hr_min + separator + min_sec;
            $(parentElement + ' .counter_text').html(displayTime);
            elem.querySelector(parentElement + ' .counter').setAttribute('data-time', Math.round(timeLeft));
            elem.querySelector(parentElement + ' .progressEnd').style[transform] = 'rotate(' + degrees + 'deg)';
            elem.querySelector(parentElement + ' .progressEnd').style['webkitTransform'] = 'rotate(' + degrees + 'deg)';
            elem.querySelector(parentElement + ' .progress').style[transform] = 'rotate(' + degrees + 'deg)';
            elem.querySelector(parentElement + ' .progress').style['webkitTransform'] = 'rotate(' + degrees + 'deg)';
            if (degrees >= 180)
                $(parentElement + ' .circlePercent').addClass('fiftyPlus');
            else
                $(parentElement + ' .circlePercent').removeClass('fiftyPlus');
        }
    } catch(e) {
        console.log(e);
    }
};

CertificatequizView.prototype.questionCountDown = function () {
    if (this.remainingQuestionTime <= 1) {
        this.quizNext();
    } else {
        this.remainingQuestionTime--;
        $('#countDown').html('<span class="timer_span">' + this.remainingQuestionTime + '<span>');
    }
}

CertificatequizView.prototype.QuizOnBack = function()
{
    var certificateView = this;
    var attempt_all = this.allQuestions.all_questions_mandatory;
    var searchtext = CONTROLLER.getVariable("activesearchtext");
    var courseid = CONTROLLER.getVariable("activecourseid");
    var preView = CONTROLLER.getVariable("previousView");
    var myactivity = CONTROLLER.getVariable("myactivity");
    var quizoftheday = CONTROLLER.getVariable("quizoftheday");
    var notificationtype = CONTROLLER.getVariable('notificationType');
    var notificationUrlType = CONTROLLER.getVariable('notificationUrlType');
    var listAllQuizzes = CONTROLLER.getVariable("listallquizzes");
    var THIS = this;
    var quiz_obj = THIS.quiz.getQuiz(this.quiz_nid);
    //clears all the quiz related cached data.
    clearAllQuizRelatedCachedData();
    var params = {"report": THIS.allQuestions
        , "replace_item": '#quiz_wrapper'
        , "replace": true
        , "mode": ''
        , "THIS": THIS
        , "attempts": THIS.allQuestions.attempts
    };
    var THIS = this;
    if ($(".solution_embed_wrapper").length == 1) {
        THIS.removeSolutionWrapper();
        return "";
    }

    if (this.gameMode == true) {
        if (COOLDOWN_TIMER == null && QUESTION_TIMER == null) {
            clearInterval(QUIZ_GAME_TIMER);
            clearInterval(QUESTION_TIMER);
            clearInterval(COOLDOWN_TIMER);
            QUIZ_GAME_TIMER = null;
            QUESTION_TIMER = null;
            COOLDOWN_TIMER = null;
            this.callCourseView(searchtext, courseid);
        } else {
            var THIS = this;
            Client.closeProgressBar();
            var check = confirm(QUIT_GAME_MSG, function () {
                if (COOLDOWN_TIMER != null) {
                    var answer_result = THIS.currentQuestionView.checkUserScore('', THIS.revise);
                    THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
                    THIS.questionResultSave(answer_result['user_result_answer']);
                }
                clearInterval(QUIZ_GAME_TIMER);
                clearInterval(QUESTION_TIMER);
                clearInterval(COOLDOWN_TIMER);
                QUIZ_GAME_TIMER = null;
                QUESTION_TIMER = null;
                COOLDOWN_TIMER = null;
                THIS.quizResultSave();
                THIS.QuizCertificateSubmit();
            });
        }
        return;
    }

        if (typeof preView !== 'undefined' && preView === "AllSubjects" && (notificationtype != "" && notificationtype != "action" && notificationUrlType != "course")
            && (typeof listAllQuizzes == "undefined" || listAllQuizzes == "") && (quizoftheday != 'undefined' && quizoftheday == 'true')) {
            window.clearInterval(QUIZ_TIMER);
            QUIZ_TIMER = null;
            THIS.MAX_TIME = null;
            replaceBody();
            CONTROLLER.loadView("AllSubjects");
            CONTROLLER.setVariable("previousView", "");
            CONTROLLER.setVariable("quizoftheday", "");
        }
    else if (typeof preView !== 'undefined' && preView === "TestSeries" && ($('#end_dtate_message').length > 0)) {
            window.clearInterval(QUIZ_TIMER);
            QUIZ_TIMER = null;
            THIS.MAX_TIME = null;
        replaceBody();
        setTimeout(function () {
            CONTROLLER.loadView("TestSeries");
        }, 500);

    } else {
        if (this.currentQuestionView == 'undefined' || this.currentQuestionView == null) {
                window.clearInterval(QUIZ_TIMER);
                QUIZ_TIMER = null;
                THIS.MAX_TIME = null;
            this.callCourseView(searchtext, courseid);
        } else if (this.currentQuestionView.name == 'ReportView' || this.currentQuestionView.name == 'SummaryView') {
                window.clearInterval(QUIZ_TIMER);
                QUIZ_TIMER = null;
                THIS.MAX_TIME = null;
            if (this.currentQuestionView.name == 'SummaryView') {
                if (typeof this.quiz_break != "undefined" && typeof this.quiz_break != "") {
                    this.callCourseView(searchtext, courseid);
                }
                else {
                    this.QuizCertificateSubmit();
                    	var title = this.getTitle(this.allQuestions);
                    $('.title_text').html(title);
                }
            }
            else {
                if (typeof myactivity != "undefined" && myactivity == "true") {
                    replaceBody();
                    setTimeout(function () {
                        CONTROLLER.loadView("MyActivities");
                        CONTROLLER.setVariable("myactivity", "");
                    }, 200);
                }
                else {
                    var courseid = "";
                    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
                        courseid = QUIZ_RESOURCE_PARAMS.courseid;
                    }
                    else {
                        courseid = CONTROLLER.getVariable("activecourseid");
                    }
                    this.callCourseView(searchtext, courseid);
                }
            }
        }
        else if (typeof this.quiz_break != "undefined" && typeof this.quiz_break != "") {
            window.clearInterval(QUIZ_TIMER);
                QUIZ_TIMER = null;
                THIS.MAX_TIME = null;
                this.callCourseView(searchtext, courseid);
        }
        else {
            var THIS = this;
            Client.closeProgressBar();
            var check = confirm("Do you want to exit from the " + OBJECT_QUIZ + "?", function () {
                window.clearInterval(QUIZ_TIMER);
                QUIZ_TIMER = null;
                THIS.MAX_TIME = null;
                THIS.callCourseView(searchtext, courseid);
            });
            //If allow resume is disabled (0), changing the text of confirm dialog "cancel" to "Resume Test"
			//quiz_template == "2" Large Exam, 1 == Small Exam
            if((THIS.allQuestions.type == "certificate" && THIS.allQuestions.quiz_template == "2") && (THIS.allQuestions.allow_resume == "0")){
                if($("#alert_dialog").length > 0){
                    $('.ui-button-text').each(function() {
                        var btn = $(this);
                        if(typeof btn != 'undefined' && typeof btn[0] != 'undefined' && typeof btn[0].innerText != 'undefined' && btn[0].innerText == 'Cancel'){
                            btn[0].innerText = "Resume Test"
                        }
                    });
                }
            }
        }
    }
};



CertificatequizView.prototype.pad = function (n) {
    return n < 10 ? '0' + n : n;
};

CertificatequizView.prototype.quizEnd = function (action) {
	if (!this.allowAttempt()) {
        return false;
    }
    var THIS = this;
    var attempt_all = this.allQuestions.all_questions_mandatory;

    if (typeof action == 'undefined' || action == true) {
        var answer_result = this.currentQuestionView.checkUserScore('', this.revise);
        if (this.showSectionLimitReached(this.allQuestions.questions[this.currentQuestionIndex].nid, true, answer_result['user_result_answer']) == true) {
            this.clearOptionsSelected();
            $('.quiz_next, .quiz_back').removeData('clicked');
            return;
        }
        var seconds = THIS.timeTaken();
        var quiz_result_obj = this.allQuestions['quiz_result'][this.currentQuestionIndex];
        var type = this.allQuestions.questions[this.currentQuestionIndex].questiontype;

        if ((type == "long_answer" || type == "short_answer")) {
            var min_charlen = parseInt(this.allQuestions.questions[this.currentQuestionIndex].min_characters);
            var max_charlen = parseInt(this.allQuestions.questions[this.currentQuestionIndex].max_characters);
            if (this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation != 8 && answer_result['user_result_answer'].user_answer != '' && answer_result['user_result_answer'].answer_charlength < min_charlen) {
                $('.quiz_next, .quiz_back').removeData('clicked');
                alert(MIN_CHARACTERS + min_charlen + ' characters.');
                return "";
            } else if (this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation != 8 && answer_result['user_result_answer'].user_answer != '' && answer_result['user_result_answer'].answer_charlength > max_charlen) {
                $('.quiz_next, .quiz_back').removeData('clicked');
                alert(MAX_CHARACTERS + max_charlen + ' characters.');
                return "";
            } else if (this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation == 6 && this.allQuestions.questions[this.currentQuestionIndex].answer_range_end > 9 && (answer_result['user_result_answer'].user_answer != '' && (answer_result['user_result_answer'].user_answer).length < 2)) {
                $('.quiz_next, .quiz_back').removeData('clicked');
                alert(SHORT_ANSWER_TYPE_RADIO_ERROR_MESSAGE);
                return "";
            }
        }

        if (typeof quiz_result_obj != "undefined") {
            answer_result['user_result_answer']['time_taken'] = (quiz_result_obj['question_result']['time_taken'] + seconds);
        } else {
            answer_result['user_result_answer']['time_taken'] = seconds;
        }
        this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
        this.questionResultSave(answer_result['user_result_answer']);
    }

    if (typeof attempt_all != "undefined" && attempt_all == 1) {
        var count = this.getResultsLength();
        if (this.allQuestions.questions.length != count)
        {
            if (this.allQuestions.questions.length - 1 == count) {
                var answer_result = this.currentQuestionView.checkUserScore('', this.revise);
                if (answer_result['user_result_answer'].is_skipped == IS_SKIPPED['skipped'] || answer_result['user_result_answer'].is_skipped == IS_SKIPPED['review_skipped']) {
                    alert(SKIP_ERROR);
                    return "";
                }
                else if (answer_result['user_result_answer'].is_skipped == IS_SKIPPED['review_answered'] || answer_result['user_result_answer'].is_skipped == IS_SKIPPED['review_skipped']) {
                    alert(QUIZ_SKIP_ERROR);
                    return;
                }
                else {
                    this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
                    this.questionResultSave(answer_result['user_result_answer']);
                    var skip = this.containsSkip();
                    if (skip != undefined && skip == true) {
                        alert(QUIZ_SKIP_ERROR);
                        return;
                    }
                    this.quizReportShow();
                }
            } else {
                alert(QUIZ_SKIP_ERROR);
                return;
            }
        }
        else {
            var skip = this.containsSkip();
            if (skip != undefined && skip == true) {
                alert(QUIZ_SKIP_ERROR);
                return;
            }
            this.quizReportShow();
        }
    }
    else {
        this.quizReportShow();
    }
};


CertificatequizView.prototype.quizReportShow = function() {
    this.logUserAction('Summary View');
    var THIS = this;
    CONTROLLER.loadChildView('Summary', function(view) {
        var PARENTVIEW = view;
        var reports = [];
        var CHILDTHIS = view;
        reports['answered'] = 0;
        reports['unanswer'] = 0;
        reports['allanswer'] = 0;
        reports['revise'] = 0;
        reports['skip'] = 0;
        reports['opt_revise'] = 0;
        reports['opt_skip'] = 0;
        reports['opt_ans'] = 0;
        THIS.getOptionalQuestionsCount(reports);
        reports['total'] = THIS.allQuestions.questions.length;
        for (var i in THIS.allQuestions.quiz_result) {
            if (!isNaN(i)) {
                if (typeof THIS.allQuestions.quiz_result[i].question_result !== 'undefined') {
                    var currentId = THIS.allQuestions.quiz_result[i].question_result.nid;
                    if (THIS.allQuestions.quiz_result[i].question_result.is_skipped == IS_SKIPPED['answered']) {
                        if (reports['optionalQuestions'].indexOf(currentId) > -1) {
                            reports['opt_ans'] = reports['opt_ans'] + 1;
                        } else {
                            reports['answered'] = reports['answered'] + 1;
                        }
                    } else if (THIS.allQuestions.quiz_result[i].question_result.is_skipped == IS_SKIPPED['review_answered'] || THIS.allQuestions.quiz_result[i].question_result.is_skipped == IS_SKIPPED['review_skipped']) {
                        if (reports['optionalQuestions'].indexOf(currentId) > -1) {
                            if (THIS.allQuestions.quiz_result[i].question_result.is_skipped == IS_SKIPPED['review_answered']) {
                                reports['opt_ans'] = reports['opt_ans'] + 1;
                            }
                            reports['opt_revise'] = reports['opt_revise'] + 1;
                        } else {
                            if (THIS.allQuestions.quiz_result[i].question_result.is_skipped == IS_SKIPPED['review_answered']) {
                                reports['answered'] = reports['answered'] + 1;
                            }
                            reports['revise'] = reports['revise'] + 1;
                        }
                    } else if (THIS.allQuestions.quiz_result[i].question_result.is_skipped == IS_SKIPPED['skipped']) {
                        if (reports['optionalQuestions'].indexOf(currentId) > -1) {
                            reports['opt_skip'] = reports['opt_skip'] + 1;
                        } else {
                            reports['skip'] = reports['skip'] + 1;
                        }
                    }
                    reports['allanswer'] = reports['allanswer'] + 1;
                }
            }
        }
        reports['total'] = reports['total'] - reports['eval_skip'];
        reports['answered'] = reports['answered'] + reports['opt_ans'];
        reports['unanswer'] = reports['total'] - reports['answered'];
        reports['revise'] = reports['revise'] + reports['opt_revise'];
        reports['report'] = reports;

        if (typeof THIS.allQuestions.time_limit !== 'undefined' && THIS.allQuestions.time_limit == '0') {
            $("#countDown").remove();
        }

        view.renderSummaryGraphReport(reports);
        $('#footer_wrapper').addClass('hidden');
        $('.quiz_menu_button ').addClass('hidden');
        $('#lang_wrapper').addClass('hidden');
//        $('#report_continue').click(function() {
//            THIS.mode = 'none';
//            THIS.modeCount = 0;
//            THIS.currentQuestionIndex = 0;
//            THIS.certificateRenderReport();
//        });
        $('#report_submit').unbind("click").bind("click",function(e) {
			if (!THIS.allowAttempt()) {
                return false;
            }
            var check = confirm("Do you want to submit the " + OBJECT_QUIZ + "?", function () {
                THIS.saveQuizResult();
            });
            e.stopPropagation();
        });


        $('.report_summary_type').click(function() {
            var id = $(this).attr('action');
            var count = parseInt($(this).attr('count'));
            if (id == 'eval_skip') {
                return;
            }
            if (count > 0) {
                THIS.updateQuestionIdleTime();
                if (id == 'none') {
                    THIS.mode = 'none';
                    THIS.modeCount = 0;
                    THIS.currentQuestionIndex = 0;
                } else if (id == 'answered') {
                    THIS.mode = 'answered';
                } else if (id == 'unanswered') {
                    THIS.mode = 'unanswered';
                } else if (id == 'revise') {
                    THIS.mode = 'revise';
                } else {
                    THIS.mode = 'none';
                }
                THIS.certificateRenderReport();
                $('.quiz_menu_button ').removeClass('hidden');
            }
        });

    });
};

CertificatequizView.prototype.getOptionalQuestionsCount = function (reports) {
    try {
        var sections = this.allQuestions.sections;
        var optionalCount = 0;
        var optionalQuestions = [];
        reports['eval_skip'] = 0;
        reports['sectionLimit'] = 0;
        for (var j in sections) {
            if (typeof sections[j] != "undefined") {
                var restrictAttempt = sections[j]["restrict_attempt"];
                var sectionLimit = sections[j]["section_evaluation_count"];
                if (typeof sectionLimit == "undefined" || !sectionLimit) {
                    continue;
                }
                if (typeof restrictAttempt == "undefined" || restrictAttempt != "1") {
                    continue;
                }
                var questionsLength = sections[j].questions.length;
                sectionLimit = parseInt(sectionLimit);
                if (questionsLength > sectionLimit) {
                    reports['sectionLimit'] = reports['sectionLimit'] + sectionLimit;
                    optionalCount = optionalCount + parseInt(questionsLength - sectionLimit);
                    optionalQuestions = optionalQuestions.concat(sections[j].questions);
                }
            }
        }
        reports['eval_skip'] = optionalCount;
        reports['optionalQuestions'] = optionalQuestions;
    } catch (e) {
        console.log("CertificatequizView - getOptionalQuestionsCount : " + e);
    }
};

CertificatequizView.prototype.certificateRenderReport = function () {
    //save quiz results at end
    var params = {"report": this.allQuestions
        , "mode": this.mode
        , "replace_item": '#quiz_wrapper'
        , "replace": true
    };
    this.modeCount = 0;
    this.getSelectedModeQuestions('next');
    this.flagCount = [];
    this.renderQuestion(true);
    $('#footer_wrapper').removeClass('hidden')
    $('.quiz_header_menu').removeClass('hidden');
};


CertificatequizView.prototype.QuizCertificateSubmit = function () {
    var THIS = this;
    var preView = CONTROLLER.getVariable("previousView");
    var quizPlannerView = CONTROLLER.getVariable("quizPlannerView");
    if (typeof this.quiz_nid != 'undefined') {
        var quiz_obj = THIS.quiz.getQuiz(this.quiz_nid);
    } else {
        var quiz_obj = THIS.allQuestions;
    }
    //  THIS.certificateRenderReport();
    //save quiz results at end  vig

    var params = {"report": THIS.allQuestions
        , "replace_item": '#quiz_wrapper'
        , "replace": true
        , "mode": ''
        , "gameMode": this.gameMode
        , "THIS": THIS
        , "attempts": THIS.allQuestions.attempts
        , "activeQuizLanguage": this.activeQuizLanguage
    };

    var context = CONTROLLER.getVariable("contextView");
    var result_id = '';
    if(typeof context != 'undefined' && context == 'course'){
        var result = this.quiz.getQuizResult(params.report.nid);
        if (result != '' && result != null && result['quiz_result'].length != 0)
            result_id = result['quiz_result'][result['quiz_result'].length-1].result_id;
    }
    var id = CONTROLLER.getVariable('breakquizid');
    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
        params.replace_item = '#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id;

    }
    window.clearInterval(QUIZ_TIMER);
    QUIZ_TIMER = null;
    THIS.MAX_TIME = null;
    THIS.flagCount = [];
    if((Platform.getNetworkState()== true && typeof context != 'undefined'
            && (context == 'course' || context == 'TestSeries') && result_id.indexOf('temp') < 0)
            || (typeof preView !== 'undefined' && preView === "TestSeries") || (typeof quizPlannerView !== 'undefined' && quizPlannerView === "true")){
        $('.quiz_menu_button ').addClass('hidden');
        $('#lang_wrapper').addClass('hidden');
        CONTROLLER.setVariable("quizreport", params);
        if(typeof quiz_obj != "undefined" && typeof quiz_obj.show_results != "undefined" && (parseInt(quiz_obj.show_results)) > Math.round((getSyncedServerTime() / 1000))){
            if(quiz_obj.show_basic_user_stats != "undefined" && quiz_obj.show_basic_user_stats == "1"){
                    Client.showProgressBar("Loading...");
                    setTimeout(function () {
                        params.show_results = quiz_obj.show_results;
                        CONTROLLER.setVariable("quizreport", params);
                        CONTROLLER.loadView("UserStatistics");
                        Client.closeProgressBar();
                        $('#footer_wrapper,#search_wrapper').addClass('hidden');
                    }, 200);
            }else{
                var date = (new Date((parseInt(quiz_obj.show_results)) * 1000));
                var period = date.getHours() >= 12 ? 'PM' : 'AM';
                $('#quiz_wrapper').empty();
                $('#quiz_wrapper').append('<div id="end_dtate_message">Thank you for taking quiz. Results will be announced after' + " " + (date.toString().replace(/GMT.*/g, period)) + '</div>');
                $('#footer_wrapper,#search_wrapper').addClass('hidden');
            }
        } else {
            CONTROLLER.setVariable("quizPlannerView", "false");
            Client.showProgressBar("Loading...");
            setTimeout(function () {
                CONTROLLER.setVariable("quizreport", params);
                CONTROLLER.loadView("TestSummary");
                Client.closeProgressBar();
            }, 200);
            $("#wrapper").css("overflow", "");
            $("#quiz_wrapper").css("overflow", "");
        }
    }
    else{
        CONTROLLER.loadChildView('Report', function (view) {
            $('#wrapper').css({'overflow': 'scroll'});
            THIS.currentQuestionView = view;
            THIS.currentQuestionView.name = 'ReportView';
            view.renderCertificateReport(params);
            $('#footer_wrapper,#search_wrapper,#lang_wrapper').addClass('hidden');
        });
    }
    var activeContent_type = CONTROLLER.getVariable("content_type");
    if(typeof activeContent_type != 'undefined' && typeof OBJECT_TEST_COLLECTION != "undefined" && activeContent_type == OBJECT_TEST_COLLECTION){
   		Platform.startBackGroundJOB(true);
    }
};

CertificatequizView.prototype.timeTaken = function () {
    var THIS = this;
    var end_time_per_question = getSyncedServerTime();
    var answer_result = this.currentQuestionView.checkUserScore('', this.revise);
    var start_time_per_question = answer_result['user_result_answer'].start_time_per_question;
    var seconds = Math.round(((end_time_per_question - start_time_per_question) / 1000));

    return seconds;
};
CertificatequizView.prototype.quizNext = function (button) {
    var answer_result = this.currentQuestionView.checkUserScore('', this.revise);
    if (this.showSectionLimitReached(this.allQuestions.questions[this.currentQuestionIndex].nid, true, answer_result['user_result_answer']) == true) {
        this.clearOptionsSelected();
        $('.quiz_next, .quiz_back').removeData('clicked');
        return;
    }
    var log = 'Clicked next button';
    this.logUserAction(log);
    //load the next question
    if (!this.allowAttempt()) {
		$('.quiz_next, .quiz_back').removeData('clicked');
        return false;
    }
    var THIS = this;
    var flag = true;
    var checkQuestionConf = true;
    var seconds = THIS.timeTaken();
    var quiz_result_obj = this.allQuestions['quiz_result'][this.currentQuestionIndex];
    var attempt_all = this.allQuestions.all_questions_mandatory;
    var type = this.allQuestions.questions[this.currentQuestionIndex].questiontype;

    if (typeof quiz_result_obj != "undefined") {
        answer_result['user_result_answer']['time_taken'] = (quiz_result_obj['question_result']['time_taken'] + seconds)
    } else {
        answer_result['user_result_answer']['time_taken'] = seconds;

    }
    if (this.gameMode == true) {
        if (this.remainingQuestionTime > 1 && answer_result['user_result_answer'].is_skipped == IS_SKIPPED['skipped']) {
            alert(SKIP_ERROR);
			$('.quiz_next, .quiz_back').removeData('clicked');
            return "";
        }
        if (this.remainingQuestionTime <= 0) {
            checkQuestionConf = false;
        }
        clearInterval(QUESTION_TIMER);
        QUESTION_TIMER = null;
    }
    if (typeof attempt_all != "undefined" && attempt_all == 1 && this.gameMode != true) {
        if (answer_result['user_result_answer'].is_skipped == IS_SKIPPED['skipped'] || answer_result['user_result_answer'].is_skipped == IS_SKIPPED['review_skipped']) {
            $('.quiz_next, .quiz_back').removeData('clicked');
            alert(SKIP_ERROR);
            return "";
        }
    }
    else if ((type == "long_answer" || type == "short_answer") && checkQuestionConf) {
        var min_charlen = parseInt(this.allQuestions.questions[this.currentQuestionIndex].min_characters);
        var max_charlen = parseInt(this.allQuestions.questions[this.currentQuestionIndex].max_characters);
        if (this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation != 8 && answer_result['user_result_answer'].answer_charlength < min_charlen) {
            $('.quiz_next, .quiz_back').removeData('clicked');
            alert(MIN_CHARACTERS + min_charlen + ' characters.');
            return "";
        } else if (this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation != 8 && answer_result['user_result_answer'].answer_charlength > max_charlen) {
            $('.quiz_next, .quiz_back').removeData('clicked');
            alert(MAX_CHARACTERS + max_charlen + ' characters.');
            return "";
        } else if (this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation == 6 && this.allQuestions.questions[this.currentQuestionIndex].answer_range_end > 9 && (answer_result['user_result_answer'].user_answer != '' && (answer_result['user_result_answer'].user_answer).length < 2)) {
            $('.quiz_next, .quiz_back').removeData('clicked');
            alert(SHORT_ANSWER_TYPE_RADIO_ERROR_MESSAGE);
            return "";
        }
    }
    if ((this.allQuestions.questions.length > this.currentQuestionIndex + 1)) {
        //get current question result
        this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
        this.questionResultSave(answer_result['user_result_answer']);

        if (this.mode != 'none') {
            this.modeCount = this.currentQuestionIndex + 1;
            flag = this.getSelectedModeQuestions('next');
        } else {
            // if this.mode is answered,revise,unanswered this.currentQuestionIndex was increasing by one and answer was getting replace
            this.currentQuestionIndex = this.currentQuestionIndex + 1;
        }
        if(this.gameMode == true){
            this.quizCoolDown(true);
        } else {
            if (flag == false) {
                 setTimeout(function() {
                    THIS.quizEnd(false);
                }, 300);
            } else {
                this.renderQuestion(true);
            }
        }
    } else {
        if(this.gameMode == true){
            //get current question result
            this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
            this.questionResultSave(answer_result['user_result_answer']);
            this.quizCoolDown(false);
        } else {
            this.quizEnd();
        }
    }
};

CertificatequizView.prototype.quizBack = function (button) {

    var answer_result = this.currentQuestionView.checkUserScore('', this.revise);
    if (this.showSectionLimitReached(this.allQuestions.questions[this.currentQuestionIndex].nid, true, answer_result['user_result_answer']) == true) {
        this.clearOptionsSelected();
        $('.quiz_next, .quiz_back').removeData('clicked');
        return;
    }
    var log = 'Clicked previous button';
    this.logUserAction(log);
	if (!this.allowAttempt()) {
		$('.quiz_next, .quiz_back').removeData('clicked');
        return false;
    }
	var flag = true;
    var THIS = this;
    var seconds = THIS.timeTaken();
    var quiz_result_obj = this.allQuestions['quiz_result'][this.currentQuestionIndex];
    var type = this.allQuestions.questions[this.currentQuestionIndex].questiontype;

    if ((type == "long_answer" || type == "short_answer")) {
        var min_charlen = parseInt(this.allQuestions.questions[this.currentQuestionIndex].min_characters);
        var max_charlen = parseInt(this.allQuestions.questions[this.currentQuestionIndex].max_characters);
            if (this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation != 8 && answer_result['user_result_answer'].user_answer != '' && answer_result['user_result_answer'].answer_charlength < min_charlen) {
            $('.quiz_next, .quiz_back').removeData('clicked');
            alert(MIN_CHARACTERS + min_charlen + ' characters.');
            return "";
            } else if (this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation != 8 && answer_result['user_result_answer'].user_answer != '' && answer_result['user_result_answer'].answer_charlength > max_charlen) {
            $('.quiz_next, .quiz_back').removeData('clicked');
            alert(MAX_CHARACTERS + max_charlen + ' characters.');
            return "";
        } else if (this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation == 6 && this.allQuestions.questions[this.currentQuestionIndex].answer_range_end > 9 && (answer_result['user_result_answer'].user_answer != '' && (answer_result['user_result_answer'].user_answer).length < 2)) {
            $('.quiz_next, .quiz_back').removeData('clicked');
            alert(SHORT_ANSWER_TYPE_RADIO_ERROR_MESSAGE);
            return "";
        }
    }

    if (typeof quiz_result_obj != "undefined") {
        answer_result['user_result_answer']['time_taken'] = (quiz_result_obj['question_result']['time_taken'] + seconds);
    } else {
        answer_result['user_result_answer']['time_taken'] = seconds;
    }
    this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
    this.questionResultSave(answer_result['user_result_answer']);
    if (this.mode != 'none') {
        flag = this.getSelectedModeQuestions('back');
        this.modeCount = this.currentQuestionIndex;
    }
    if (this.currentQuestionIndex >= 1 || flag == true) {
        if (this.mode == 'none') {
            this.currentQuestionIndex = this.currentQuestionIndex - 1;
        }
        this.renderQuestion(true);
    }
};

CertificatequizView.prototype.getSelectedModeQuestions = function (action) {
    try {
        var flag = false, back = 0;
        var allQuestions = this.allQuestions;
        for (var i in allQuestions.questions) {
            if (!isNaN(i)) {
                if (this.mode == 'answered') {
                    if (typeof allQuestions.questions[i].question_result !== 'undefined') {
                    if ((allQuestions.questions[i].question_result.is_correct == 0 || allQuestions.questions[i].question_result.is_correct == 1) && (allQuestions.questions[i].question_result.is_skipped == IS_SKIPPED['answered'] || allQuestions.questions[i].question_result.is_skipped == IS_SKIPPED['review_answered'])) {
                            if (parseInt(i) >= this.modeCount && action == 'next') {
                                this.currentQuestionIndex = parseInt(i);
                                this.modeCount = parseInt(i) + 1;
                                this.flagCount.push(parseInt(i));
                                flag = true;
                                return true;
                            } else if (parseInt(i) == this.modeCount && action == 'back' && back != this.modeCount) {
                                if (this.modeCount >= parseInt(i)) {
                                    if (this.currentQuestionIndex != parseInt(back)) {
                                        this.flagCount.pop(parseInt(back));
                                    }
                                    this.currentQuestionIndex = parseInt(back);
                                    this.modeCount = back;
                                    flag = true;
                                    return true;
                                }
                            }
                            back = parseInt(i);
                        }
                    }
                } else if (this.mode == 'revise') {
                    if (typeof allQuestions.questions[i].question_result !== 'undefined') {
                    if (allQuestions.questions[i].question_result.is_skipped == IS_SKIPPED['review_answered'] || allQuestions.questions[i].question_result.is_skipped == IS_SKIPPED['review_skipped']) {
                            if (parseInt(i) >= this.modeCount && action == 'next') {
                                this.currentQuestionIndex = parseInt(i);
                                this.modeCount = parseInt(i) + 1;
                                this.flagCount.push(parseInt(i));
                                flag = true;
                                return true;
                        } else if (parseInt(i) == this.modeCount && action == 'back' && back != this.modeCount) {
                                if (this.currentQuestionIndex != parseInt(back)) {
                                    this.flagCount.pop(parseInt(back));
                                }
                                this.currentQuestionIndex = parseInt(back);
                                this.modeCount = back;
                                flag = true;
                                return true;
                            }
                            //this.flagCount.push(back);
                            back = parseInt(i);
                        }
                    }
                } else if (this.mode == 'unanswered') {
                    if (typeof allQuestions.questions[i].question_result === 'undefined') {
                        if (parseInt(i) >= this.modeCount && action == 'next') {
                            this.currentQuestionIndex = parseInt(i);
                            this.modeCount = parseInt(i) + 1;
                            this.flagCount.push(parseInt(i));
                            flag = true;
                            return true;
                        } else if (parseInt(i) == this.modeCount && action == 'back' && back != this.modeCount) {

                            if (this.currentQuestionIndex != parseInt(back)) {
                                this.flagCount.pop(parseInt(back));
                            }
                            this.currentQuestionIndex = parseInt(back);
                            this.modeCount = back;
                            flag = true;
                            return true;
                        }
                        //    this.flagCount.push(back);
                        back = parseInt(i);
                    } else {
                        if ((allQuestions.questions[i].question_result.is_correct == 0 && (allQuestions.questions[i].question_result.is_skipped == IS_SKIPPED['skipped']|| allQuestions.questions[i].question_result.is_skipped == IS_SKIPPED['review_skipped']))) {
                            if (parseInt(i) >= this.modeCount && action == 'next') {
                                this.currentQuestionIndex = parseInt(i);
                                this.modeCount = parseInt(i) + 1;
                                this.flagCount.push(parseInt(i));
                                flag = true;
                                return true;
                            } else if (parseInt(i) == this.modeCount && action == 'back' && back != this.modeCount && (allQuestions.questions[parseInt(back)].question_result.is_correct == 0 && allQuestions.questions[parseInt(back)].question_result.is_skipped == IS_SKIPPED['skipped'])) {
                                if (this.currentQuestionIndex != parseInt(back)) {
                                    this.flagCount.pop(parseInt(back));
                                }
                                this.currentQuestionIndex = parseInt(back);
                                this.modeCount = back;
                                flag = true;
                                return true;
                            }
                            //    this.flagCount.push(back);
                            back = parseInt(i);
                        }
                    }
                } else if (this.mode == 'none') {
                    flag = true;
                    return true;
                }
            }
        }
        if (flag == false) {
            return false;
        }
    } catch (e) {
        console.log("CertificatequizView : quizBack - " + e);
    }
};


CertificatequizView.prototype.show_legend = function(button, number) {
    var THIS = this;
    //  var questions=[];
    var answer_result = this.currentQuestionView.checkUserScore('', this.revise);
    if (THIS.showSectionLimitReached(THIS.allQuestions.questions[THIS.currentQuestionIndex].nid, true, answer_result['user_result_answer']) == true) {
        THIS.clearOptionsSelected();
        $('.quiz_menu_button').removeClass('active_legend');
        return false;
    }
    this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
    var quiz = this.allQuestions;

    if (typeof this.allQuestions.questions !== 'undefined') {

        var allQuestions = this.allQuestions;

        quiz['quiztype'] = this.allQuestions.type;
        quiz['questStates'] = IS_SKIPPED;
        this.renderTemplate('nav_list.tl', quiz, '#quiz_wrapper', false);
        //        /*---------------------------------------*/

        for (var i in allQuestions.questions) {
            if (!isNaN(i)) {
                if (this.mode == 'none') {
                    if (typeof allQuestions.questions[i].question_result === 'undefined') {
                        $('#quiz_legend_bar_' + allQuestions.questions[i].nid).show();
                        $('#quiz_legend_circle_' + allQuestions.questions[i].nid).show();
                        $('#quiz_legend_circle_' + allQuestions.questions[i].nid).html('<span class="unanswer circle"></span>');
                    } else if ((allQuestions.questions[i].question_result.is_correct == 0 && allQuestions.questions[i].question_result.is_skipped == IS_SKIPPED['skipped'])) {
                        $('#quiz_legend_bar_' + allQuestions.questions[i].nid).show();
                        $('#quiz_legend_circle_' + allQuestions.questions[i].nid).show();
                    }
                } else {
                    $('#quiz_legend_bar_' + allQuestions.questions[i].nid).hide();
                    $('#quiz_legend_circle_' + allQuestions.questions[i].nid).hide();
                }
                if (this.mode == 'answered') {
                    if (typeof allQuestions.questions[i].question_result !== 'undefined') {
                        if ((allQuestions.questions[i].question_result.is_correct == 0 || allQuestions.questions[i].question_result.is_correct == 1) && (allQuestions.questions[i].question_result.is_skipped == IS_SKIPPED['answered'] || allQuestions.questions[i].question_result.is_skipped == IS_SKIPPED['review_answered'])) {
                            $('#quiz_legend_bar_' + allQuestions.questions[i].nid).show();
                            $('#quiz_legend_circle_' + allQuestions.questions[i].nid).show();
                        }
                    }
                } else if (this.mode == 'revise') {
                    if (typeof allQuestions.questions[i].question_result !== 'undefined') {
                        if (allQuestions.questions[i].question_result.is_skipped == IS_SKIPPED['review_answered'] || allQuestions.questions[i].question_result.is_skipped == IS_SKIPPED['review_skipped']) {
                            $('#quiz_legend_bar_' + allQuestions.questions[i].nid).show();
                            $('#quiz_legend_circle_' + allQuestions.questions[i].nid).show();
                        }
                    }
                } else if (this.mode == 'unanswered') {
                    if (typeof allQuestions.questions[i].question_result === 'undefined') {
                        $('#quiz_legend_bar_' + allQuestions.questions[i].nid).show();
                        $('#quiz_legend_circle_' + allQuestions.questions[i].nid).show();
                        $('#quiz_legend_circle_' + allQuestions.questions[i].nid).html('<span class="unanswer circle"></span>');
                    } else if (allQuestions.questions[i].question_result.is_correct == 0 && (allQuestions.questions[i].question_result.is_skipped == IS_SKIPPED['skipped'] || allQuestions.questions[i].question_result.is_skipped == IS_SKIPPED['review_skipped'])) {
                        $('#quiz_legend_bar_' + allQuestions.questions[i].nid).show();
                        $('#quiz_legend_circle_' + allQuestions.questions[i].nid).show();
                    }
                }
            }
        }
        /*--------------------------------------*/
        var catid = "";
        if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
            catid = QUIZ_RESOURCE_PARAMS.catid;
        }
        else {
            catid = CONTROLLER.getVariable("activecatid");
        }
        $('.question_nav_title_no').unbind("click").bind("click", function (e) {
            var answer_result = THIS.currentQuestionView.checkUserScore("", THIS.revise);
            if (THIS.showSectionLimitReached(THIS.allQuestions.questions[THIS.currentQuestionIndex].nid, true, answer_result['user_result_answer']) == true) {
                THIS.clearOptionsSelected();
                e.stopPropagation();
                return false;
            }
            if (!THIS.allowAttempt()) {
                return false;
            }
            var page_no = $(this).attr('page');
            THIS.modeCount = parseInt(page_no) + 1;
            THIS.logUserAction('Jump to '+ THIS.modeCount);
            THIS.goQuestion(this, page_no);
        });

        $('body').click(function(e) {
            if (!$(e.target).hasClass('question_nav_title_no') && !$(e.target).hasClass('question_nav_show_tilde') && !$(e.target).hasClass('question_nav_title_no') && !$(e.target).hasClass('active_legend') && $(e.target).attr('id') != 'search_icon') {
                $('#question_nav_show').remove();
                $(e.target).removeClass('active_legend');
            }
        });

        $('.quiz_submit').unbind("click").bind("click", function (e) {
			if (!THIS.allowAttempt()) {
                return false;
            }
            THIS.flagCount = [];
            THIS.quizEnd();
        });
    }

};
CertificatequizView.prototype.goQuestion = function (button, number) {
    var seconds = this.timeTaken();
    var quiz_result_obj = this.allQuestions['quiz_result'][this.currentQuestionIndex];
    var answer_result = this.currentQuestionView.checkUserScore(button, this.revise);
    var type = this.allQuestions.questions[this.currentQuestionIndex].questiontype;

    if ((type == "long_answer" || type == "short_answer")) {
        var min_charlen = parseInt(this.allQuestions.questions[this.currentQuestionIndex].min_characters);
        var max_charlen = parseInt(this.allQuestions.questions[this.currentQuestionIndex].max_characters);
            if (this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation != 8 && answer_result['user_result_answer'].user_answer != '' && answer_result['user_result_answer'].answer_charlength < min_charlen) {
            $('.quiz_next, .quiz_back').removeData('clicked');
            alert(MIN_CHARACTERS + min_charlen + ' characters.');
            return "";
            } else if (this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation != 8 && answer_result['user_result_answer'].user_answer != '' && answer_result['user_result_answer'].answer_charlength > max_charlen) {
            $('.quiz_next, .quiz_back').removeData('clicked');
            alert(MAX_CHARACTERS + max_charlen + ' characters.');
            return "";
        } else if (this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation == 6 && this.allQuestions.questions[this.currentQuestionIndex].answer_range_end > 9 && (answer_result['user_result_answer'].user_answer != '' && (answer_result['user_result_answer'].user_answer).length < 2)) {
            $('.quiz_next, .quiz_back').removeData('clicked');
            alert(SHORT_ANSWER_TYPE_RADIO_ERROR_MESSAGE);
            return "";
        }
    }
    if (typeof quiz_result_obj != "undefined") {
        answer_result['user_result_answer']['time_taken'] = (quiz_result_obj['question_result']['time_taken'] + seconds);
    } else {
        answer_result['user_result_answer']['time_taken'] = seconds;
    }
    this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
    this.questionResultSave(answer_result['user_result_answer']);
    this.currentQuestionIndex = parseInt(number);
    this.renderQuestion(true);
};

CertificatequizView.prototype.getResultsLength = function () {
    count = 0;
    var results = this.allQuestions.quiz_result;
    $.each(results, function (index, value) {
        if (value && typeof value['question_result'] != "undefined") {
            count++;
        }
    });
    return count;
};

CertificatequizView.prototype.containsSkip = function () {
    var results = this.allQuestions.quiz_result;
    var skipped = false;
    $.each(results, function (index, value) {
        if (value && typeof value['question_result'] != "undefined") {
            if (value['question_result'].is_skipped > IS_SKIPPED['answered'] && $.isEmptyObject(value['question_result']['user_answer'])) {
                skipped = true;
            }
        }
    });
    return skipped;
};



CertificatequizView.prototype.showQuiz = function(quiz_res_Obj, nid, attempts)
{
    Client.showProgressBar("Loading");
    var quiz_obj;
    var params = {};
    var questions;
    var THIS = this;
    var count = 0;
    quiz_obj = THIS.quiz.getQuiz(nid);
    questions = THIS.quiz.getQuestionsForQuiz(quiz_res_Obj['nid'], quiz_obj['changed']);
    for (var i in questions["question"]) {
        if (!isNaN(i)) {
            for (var j in quiz_res_Obj) {
                if (!isNaN(j)) {
                    if (questions["question"][i].nid == quiz_res_Obj[j]['question_result'].nid) {
                        questions["question"][i]['question_result'] = quiz_res_Obj[j]['question_result'];
                    }
                }
            }
        }
    }
    quiz_obj['attempts'] = attempts;
    THIS.allQuestions = quiz_obj;
    quiz_obj['quiz_result'] = quiz_res_Obj;
    quiz_obj['questions'] = questions["question"];
    params.report = quiz_obj;
    params.mode = "all";
    params.replace_item = "#quiz_wrapper";
    params.replace = true;
    params.THIS = THIS
    params.attempts = attempts;
    var questiontype = "";
    CONTROLLER.setVariable("activeParentView", "Course");
    window.clearInterval(QUIZ_TIMER);
    QUIZ_TIMER = null;
    THIS.MAX_TIME = null;
    THIS.flagCount = [];
    var context = CONTROLLER.getVariable("contextView");
    var preView = CONTROLLER.getVariable("previousView");
    var previousView = CONTROLLER.getVariable("preView");
    if((typeof context != 'undefined' && (context == 'course' || context == 'TestSeries'))
        || (typeof preView !== 'undefined' && preView === "TestSeries")  || (typeof previousView !== 'undefined' && previousView === "Planner")){
        $('.quiz_menu_button ').addClass('hidden');
        $('#lang_wrapper').addClass('hidden');
        CONTROLLER.setVariable("quizreport", params);
        if(typeof quiz_obj != "undefined" && typeof quiz_obj.show_results != "undefined" && (parseInt(quiz_obj.show_results)) > Math.round((getSyncedServerTime() / 1000))){
            if(quiz_obj.show_basic_user_stats != "undefined" && quiz_obj.show_basic_user_stats == "1"){
                Client.showProgressBar("Loading...");
                setTimeout(function () {
                    params.show_results = quiz_obj.show_results;
                    CONTROLLER.setVariable("quizreport", params);
                    CONTROLLER.loadView("UserStatistics");
                    Client.closeProgressBar();
                    $('#footer_wrapper,#search_wrapper').addClass('hidden');
                }, 200);
        }else{
            var date = (new Date((parseInt(quiz_obj.show_results)) * 1000));
            var period = date.getHours() >= 12 ? 'PM' : 'AM';
            $('#quiz_wrapper').empty();
            $('#quiz_wrapper').append('<div id="end_dtate_message">Thank you for taking quiz. Results will be announced after' + " " + (date.toString().replace(/GMT.*/g, period)) + '</div>');
            $('#footer_wrapper,#search_wrapper').addClass('hidden');
        }

        } else {
            Client.showProgressBar("Loading...");
            setTimeout(function () {
                CONTROLLER.setVariable("quizreport", params);
                CONTROLLER.loadView("TestSummary");
                Client.closeProgressBar();
            }, 200);
            $("#wrapper").css("overflow", "");
            $("#quiz_wrapper").css("overflow", "");
        }
    } else {
	    CONTROLLER.loadChildView('Report', function(view) {
	        $('#wrapper').css({'overflow': 'scroll'});
	        THIS.currentQuestionView = view;
	        setTimeout(function(){
	             view.renderCertificateReport(params);
	             $('#footer_wrapper,#search_wrapper').addClass('hidden');
	        },500);
	    });
    }
    Client.closeProgressBar();
}

CertificatequizView.prototype.renderView = function (type, view, params) {
    CONTROLLER.setVariable("onAppResume", "false");
    var THIS = this;
    view.renderContent(params);
    this.currentQuestionView = view;
    if (typeof THIS.allQuestions.time_limit !== 'undefined' && THIS.allQuestions.time_limit == '0') {
        $("#countDown").remove();
    }
    if (this.gameMode == true) {
        var quest_time_limit = this.allQuestions.questions[this.currentQuestionIndex].time_limit;
        if (typeof quest_time_limit == 'undefined' || quest_time_limit == null) {
            if (typeof DEFAULT_QUESTION_TIME == 'undefined') {
                DEFAULT_QUESTION_TIME = 30;
            }
            quest_time_limit = DEFAULT_QUESTION_TIME;
        }
        this.remainingQuestionTime = quest_time_limit;
        if (typeof QUESTION_TIMER != 'undefined' && !QUESTION_TIMER) {
            $('#countDown').html('<span class="timer_span">' + this.remainingQuestionTime + '<span>');
            QUESTION_TIMER = window.setInterval(function () {
                THIS.questionCountDown();
            }, 1000);
        }
        $('.quiz_back').addClass("hidden");
        $('#certificate_placeholder').show();
        $('.revise_class').html('<div class="life_available">Life : ' + this.lifeInGame + '</div>');
    } else {
        if (type == true) {
            this.currentQuestionView.setPreviousData(this.allQuestions.questions[this.currentQuestionIndex]);
        }
        $('#certificate_placeholder').show();
        $('.revise_class').attr('id', 'revise_later');
        if (typeof this.allQuestions.questions[this.currentQuestionIndex]['question_result'] !== 'undefined') {
            if (this.allQuestions.questions[this.currentQuestionIndex]['question_result'].is_skipped == IS_SKIPPED['review_answered'] || this.allQuestions.questions[this.currentQuestionIndex]['question_result'].is_skipped == IS_SKIPPED['review_skipped']) {
                $('.revise_class').addClass('revise_back');
                $('.revise_class').removeClass('revise_later');
                this.revise = true;
            }
        }

        $('#revise_later').unbind("click").bind("click",function(e) {
            if ($(this).hasClass('revise_later')) {
                $(this).addClass('revise_back');
                $(this).removeClass('revise_later');
                THIS.addReviseQuestionStatus(e);
            } else {
                $(this).addClass('revise_later');
                $(this).removeClass('revise_back');
                THIS.removeReviseQuestionStatus(e);
            }

        });
        this.quizStyleBack();
        if (this.mode != 'none') {
            if (this.flagCount.length == 0) {
                $('#flipQuestionback').addClass('flipQuestionback_light');
            }
        }
    }
    //Previous and next call back on swipe
    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
        var id = CONTROLLER.getVariable('breakquizid');
        $('#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id + ' #swipe_wrapper').swipe('enable');
        $('#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id + ' #swipe_wrapper').swipe({
            swipeLeft: function (e) {
                if (!$('.quiz_next').data('clicked')) {
                    $('.quiz_next').data('clicked', true);
                    THIS.quizNext();
                }
            },
            swipeRight: function (e) {
                if (!$('.quiz_back').hasClass('flipQuestionback_light') && !$('.quiz_back').hasClass('hidden')) {
                    if (!$('.quiz_back').data('clicked')) {
                        $('.quiz_back').data('clicked', true);
                        THIS.quizBack();
                    }
                }
            }
        });
    }
        $("#swipe_wrapper").swipe('destroy');
        $("#swipe_wrapper").swipe({
            swipeLeft: function() {
                if (!$('.quiz_next').data('clicked')) {
                    $('.quiz_next').data('clicked', true);
                    THIS.quizNext();
                }
            },
            swipeRight: function() {
                if (!$('.quiz_back').hasClass('flipQuestionback_light') && !$('.quiz_back').hasClass('hidden')) {
                    if (!$('.quiz_back').data('clicked')) {
                        $('.quiz_back').data('clicked', true);
                        THIS.quizBack();
                    }
                }
            }
        });
    $('.quiz_next, .quiz_back').removeData('clicked');
    if($('#header_wrapper').length > 0 && $('#question_button_wrapper').length > 0){
        var quizHeaderHeight = parseInt($('#header_wrapper').height());
        var quizFooterHeight = parseInt($('#question_button_wrapper').height());
        if(typeof quizHeaderHeight != 'undefined' && typeof quizFooterHeight != 'undefined'){
            var windowHeight = window.innerHeight;
            var quizContainerHeight = windowHeight - (quizHeaderHeight + quizFooterHeight + 10);
            $("#quizContainer_wrap").css({
                "height": quizContainerHeight
            });
        }
    }
    this.bindLanguageChange();
    var currQuestion = this.allQuestions.questions[this.currentQuestionIndex];
    var currQuestionResult = currQuestion['question_result'];
    this.showSectionLimitReached(currQuestion.nid, false);
    var log = 'Question ' + (this.currentQuestionIndex + 1) + ' Started';
    var logExtras = this.getLogExtrasForQuestion(currQuestion, currQuestionResult);
    this.logUserAction(log, logExtras);
    if (typeof MathJax != "undefined") {
        MathJax.Hub.Queue(["Typeset",MathJax.Hub,'quiz_wrapper']);
    }
};

CertificatequizView.prototype.bindLanguageChange = function () {
    var THIS = this;
    $('#selectedLang').on('change', function (e) {
        var answer_result = THIS.currentQuestionView.checkUserScore("", THIS.revise);
        if (THIS.showSectionLimitReached(THIS.allQuestions.questions[THIS.currentQuestionIndex].nid, true, answer_result['user_result_answer']) == true) {
            THIS.clearOptionsSelected();
            return;
        }
        $('#selectedLang').off();
        THIS.activeQuizLanguage = this.value;
        CONTROLLER.setVariable('activeQuizLanguage', this.value);
        var log = 'Language Changed to ' + $(this).find("option:selected").text();
        THIS.logUserAction(log);
        THIS.goQuestion('', THIS.currentQuestionIndex);
    });
};

CertificatequizView.prototype.startQuiz = function (resume) {
    var THIS = this;

    if(this.forceRegisterAttempt){
        if (!this.QuizResume()){
            return;
        }
    }

    if (typeof this.allQuestions.time_limit !== 'undefined' && this.allQuestions.time_limit != '0') {
        QUIZ_TIMER = window.setInterval(function() {
            THIS.countDown();
        }, 1000);
    }

    //Showing alert of remaining time to the user if allow resume is disabled.
    if(typeof resume != 'undefined' && resume == true && this.allQuestions.type == "certificate" && this.allQuestions.quiz_template == "2" && 
        this.allQuestions.allow_resume == "0" && typeof this.MAX_TIME != 'undefined' && parseInt(this.MAX_TIME) > 0){
            var quiz_submit_time = parseInt(this.MAX_TIME) + Math.floor(getSyncedServerTime()/1000);
            var quiz_submit_date = new Date(quiz_submit_time*1000);
            alert(ALLOW_RESUME_REMAINING_TIME + getFormattedDate(quiz_submit_time) + ', ' + quiz_submit_date.toLocaleTimeString());
    }

    if (this.currentQuestionIndex >= this.allQuestions.questions.length) {
        this.quizEnd(false);
    } else {
        this.renderQuestion(false);
    }
}

CertificatequizView.prototype.renderQuiz = function (nid, quiz_result_id) {
    try {
	    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
	        $('#quiz_wrapper #break_quiz_wrapper_' + nid).empty();
	    } else {
	        $('#quiz_wrapper').empty();
	    }
	    var questions = '';  var THIS = this;

	    //load all questions
	    questions = this.quiz.getQuestionsForQuiz(nid,this.allQuestions.changed);
        if (!questions || (questions['question'] && questions['question'].length ==  0)) {
            this.exitFromQuiz(ERROR_MESSAGE);
            return;
        }
        this.allQuestions['questions'] = questions['question'];
        if (this.gameMode == true) {
            clearInterval(QUIZ_GAME_TIMER);
            clearInterval(QUESTION_TIMER);
            clearInterval(COOLDOWN_TIMER);
            this.quizGame();
        } else {
        	if (typeof this.allQuestions.time_limit !== 'undefined' && this.allQuestions.time_limit != '0') {
                
                // checking for the remaining time left to take quiz if the quiz is time bound and specific duration is set to complete it 
                // this is for certificate large exam when allow resume is disabled with time limit and duration 
                if((Platform.getNetworkState() == true) && this.allQuestions.type == "certificate" && this.allQuestions.quiz_template == "2" 
                && this.allQuestions.allow_resume == "0"){
                    // check for the quiz attempts so that when we take last quiz attempt 
                    // and use that attempt to get the remaining time from the server
                    // to show that to user when reset app is done
                    var quiz_attempts = GET_QUIZ_ATTEMPTS[nid];
                    quiz_attempts = quiz_attempts.attempts;
                    if(typeof quiz_attempts != 'undefined' && quiz_attempts.total_attempts > 0){   
                        if(typeof quiz_attempts.quiz_attempt_data != 'undefined'){
                            var quiz_attempts_data_json = quiz_attempts.quiz_attempt_data;
                            quiz_attempts_data_json = quiz_attempts_data_json[quiz_attempts_data_json.length - 1];
                            var attempt_result_id = quiz_attempts_data_json.result_id;
                            // remaining time coming from server after reset app is done
                            var remaining_time = this.quiz.getTestRemainingDuration(attempt_result_id);
                            this.MAX_TIME = remaining_time;
                        }else{
                            this.MAX_TIME = this.allQuestions.time_limit;
                        }
                    }else{
                        // show only remaining duration
                        if(this.allQuestions.attempt_expired_quiz != "1" && this.allQuestions.valid_till > 1){
                            // check for the time left to take the quiz
                            // example if quiz is there for 48 hours and user attends the quiz at 47th hour show only 1 hour left for the quiz
                            var time_left_to_take_quiz = this.allQuestions.valid_till - Math.floor(getSyncedServerTime() / 1000);
                            if(typeof time_left_to_take_quiz != 'undefined' && time_left_to_take_quiz != ""
                            && time_left_to_take_quiz != null && time_left_to_take_quiz < this.allQuestions.time_limit){
                                this.MAX_TIME = time_left_to_take_quiz;
                            }else{
                                this.MAX_TIME = this.allQuestions.time_limit;
                            }
                        }else{
                            this.MAX_TIME = this.allQuestions.time_limit;
                        }
                    }
                }else{
                    this.MAX_TIME = this.allQuestions.time_limit;
                }
                
                if (typeof this.allQuestions.time_reminder !== 'undefined' && this.allQuestions.time_reminder != '0') {
                    REMINDER_TIME = this.allQuestions.time_reminder;
                }
            }

            var THIS = this;
            var isEval = THIS.checkForEvalResult();
            if(isEval == false){
                if(this.forceRegisterAttempt){
                    this.startOrResumeQuiz(this.isQuizResumable);
                }else{
                    this.QuizResume(quiz_result_id, function (resume) {
                        THIS.startOrResumeQuiz(resume);
                    });
                }
            }
        }

        if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
            if (typeof OBJECT_TEST_COLLECTION != "undefined" && QUIZ_RESOURCE_PARAMS.type == OBJECT_TEST_COLLECTION) {
                var content_type = OBJECT_TEST_COLLECTION;
            } else {
                var content_type = OBJECT_QUIZ;
            }
            var currentTime = Math.floor(getSyncedServerTime() / 1000);
            var videodetails = '{"totalduration":2,"duration":1,"count":"1","timestamp":"","updatedtime":"","createdtime":"","uid":"' + OBJECT_USER_ID + '","resourceid":"' + CONTROLLER.getVariable("activeresourceid") + '","starttime":"' + currentTime + '","endtime":"0","agent":"mobile"' + ',"courseid":"' + QUIZ_RESOURCE_PARAMS.courseid + '","groupid":"' + QUIZ_RESOURCE_PARAMS.groupid + '","categoryid":"' + QUIZ_RESOURCE_PARAMS.catid + '","chapterid":"' + QUIZ_RESOURCE_PARAMS.chapterid + '","session":"' + OBJECT_USER_ID + '-' + currentTime + '","type":"' + content_type + '"}';
            OBJECT_VIDEO_DETAILS = $.parseJSON(videodetails);
            OBJECT_VIDEO_DETAILS.viewmode = CONTROLLER.getVariable("resourcevvhmode");
            Platform.addOrUpdateVideoViewHistory(videodetails);
        }
    }
    catch (e) {
        console.log(e.message + " function = renderQuiz ");
    }
};

CertificatequizView.prototype.checkForEvalResult = function () {
    try {
        var THIS = this;
        var results = this.quiz.getQuizResult(this.allQuestions.nid);
        if (results != '' && results != null && results['quiz_result'].length != 0) {
            var quiz_result = results['quiz_result'][results['quiz_result'].length - 1];
            if(typeof quiz_result.resultUploadStatus != "undefined"){
                var resultUploadStatus = quiz_result.resultUploadStatus;
            }
            
            if (quiz_result.is_evaluated == '1' && typeof resultUploadStatus != "undefined" && ( resultUploadStatus == "false" ||  resultUploadStatus == false)) {
                this.allQuestions['quiz_result'] = quiz_result;
                for(var i=0; i< this.allQuestions.questions.length-1; i++){
                    if(typeof quiz_result[i].question_result  != "undefined" && typeof quiz_result[i].question_result  != ""
                    && quiz_result[i].question_result  != "null" && quiz_result[i].question_result  != null && 
                    (this.allQuestions.questions[i].nid == quiz_result[i]['question_result'].nid))
                    this.allQuestions.questions[i].question_result = quiz_result[i].question_result 
                }
                var THIS = this;
                $('#footer_wrapper').remove();

                var log = 'Quiz Submit Retry';
                this.logUserAction(log);

                alert(SUBMIT_EVAL_ATTEMPT_MSG, function () {
                    THIS.retryQuizSubmit();
                    return;
                });
                return true;
            }
        }
        return false;
    } catch (e) {
        console.log("function : checkForEvalResult - " + e.message);
    }
};

CertificatequizView.prototype.callCourseView = function (searchtext, courseid) {
    var THIS = this;
    $('#wrapper').remove();
    $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
            <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
    if ($(window).width() < $(window).height() || EXEC_MODE == "online") {
        this.gobackToPreviousView(searchtext, courseid);
    }
    else {
        Platform.setDeviceOrientation("portrait");
        setTimeout(function () {
            THIS.gobackToPreviousView(searchtext, courseid);
            $(window).off("resize");
        }, 300);
    }
    QUIZ_RESOURCE_PARAMS = "";
};

CertificatequizView.prototype.checkOnAttempts = function(quiz_attempts, nid) {
    this.isQuizResumable = false;
    var quiz_res = "", quiz_attempt_data = "", quiz_result_id = "";
    var attempts = 0, quiz_time_end = -1;
    var attemptedOnSameDevice = true;
    var preView = CONTROLLER.getVariable("previousView");
    if (quiz_attempts != undefined) {
        if (Platform.getNetworkState() == false) {
            attempts = quiz_attempts;
        }
        else{
            attempts = quiz_attempts['attempts'].total_attempts;
            quiz_attempt_data = quiz_attempts['attempts'].quiz_attempt_data;
            if(typeof quiz_attempt_data !== 'undefined' && quiz_attempt_data.length > 0) {
                quiz_attempt_data = quiz_attempt_data[quiz_attempt_data.length - 1];
                if (quiz_attempt_data.is_evaluated != 1 && quiz_attempt_data.time_end == 0
                    && typeof quiz_attempt_data.macaddress != 'undefined') {
                    if (this.forceOnline) {
                        quiz_result_id = quiz_attempt_data.result_id;
                        this.isQuizResumable = true;
                        attemptedOnSameDevice = this.isQuizAttemptOnSameDevice(quiz_attempt_data);
                        attempts = attempts - 1; //Since the attempt will be getting resumed.
                    } else {
                        attemptedOnSameDevice = this.isQuizAttemptOnSameDevice(quiz_attempt_data);
                        var results = this.quiz.getQuizResult(nid);
                        if (typeof results != 'undefined' && results != "" && results !== null && results['quiz_result'] && results['quiz_result'].length != 0) {
                            quiz_res = results['quiz_result'][results['quiz_result'].length - 1];
                            if (typeof quiz_res['gen_result_id'] != 'undefined' && quiz_res['gen_result_id'] == quiz_attempt_data.result_id) {
                                attemptedOnSameDevice = true;
                                this.isQuizResumable = true;
                                //Reading the below value in quiz resume to take the remaining time if allow resume is disabled.
                                CONTROLLER.setVariable("inprogress_result_id_"+nid, quiz_attempt_data.result_id);
                                if (quiz_res['is_evaluated'] != 1) {
                                    attempts = attempts - 1; //Since the attempt will be getting resumed.
                                } else if (quiz_res['is_evaluated'] == 1 && quiz_res['result_id'].indexOf('temp_') > -1) {
                                    attempts = attempts - 1; //Since the attempt will be getting resumed.
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    this.allQuestions.attempts = attempts;
    if (attemptedOnSameDevice == false) {
        this.exitFromQuiz(QUIZ_ALREADY_STARTED_MSG);
    } else {
        if (this.allQuestions.takes != 0 && attempts >= this.allQuestions.takes) {
            var results = this.quiz.getQuizResult(nid);
            if (typeof results != 'undefined' && results != "" && results !== null && results['quiz_result'] && results['quiz_result'].length != 0) {
                quiz_res = results['quiz_result'][results['quiz_result'].length - 1];
                if (quiz_res['is_evaluated'] == 1 && quiz_res['result_id'].indexOf('temp_') == -1) {
                    this.showQuiz(quiz_res, nid, attempts);
                    return;
                }
            }
        }
        this.renderQuiz(nid, quiz_result_id);
    }
};

CertificatequizView.prototype.isQuizAttemptOnSameDevice = function (attempt) {
    if (typeof ALLOW_ONLINE_RESUME != 'undefined' && ALLOW_ONLINE_RESUME == 'true') {
        return true;
    } else {
	    var quiz_macaddress = attempt.macaddress;
	    var source = attempt.source;
	    var ua = navigator.userAgent.toLowerCase();
	    var isAndroid = ua.indexOf("android") > -1;
	    var isWindows = ua.indexOf("windows") > -1;
	    var isWeb = EXEC_MODE == "online";

	    var device_mac = Platform.getDeviceMacAddress();
	    if(typeof quiz_macaddress != 'undefined' && quiz_macaddress != ''
	        && typeof device_mac != 'undefined' && device_mac != ''
	        && quiz_macaddress == device_mac){
	        return true;
	    }
	    if (this.forceOnline && source) {
	        if(this.forceOnline && isAndroid && source.toLowerCase() == 'android') {
	            return true;
	        }
	        if(isWindows && source.toLowerCase() == 'windows') {
	            return true;
	        }
	        if(isWeb && source.toLowerCase() == 'web') {
	            return true;
	        }
        }
	    return false;
	}
};

CertificatequizView.prototype.quizGame = function () {
    var starttime = GAME_START_TIME * 1000;
    var now = (new Date()).valueOf();
    var syncedServerTime = (new Date(now + SERVER_CLIENT_OFFSET)).valueOf();
    var remainingTime = starttime - syncedServerTime;
    this.computeQuestionStartTime(syncedServerTime, remainingTime);
    remainingTime = Math.round(remainingTime / 1000);

    $('#footer_wrapper').addClass('hidden');
    if (remainingTime < 0) {
        $('#quiz_wrapper').html('<div class="game_wrap quiz_game_txt">' + GAME_ALREADY_STARTED_MSG + '</div>');
        Client.closeProgressBar();
        return;
    }
    if (typeof QUIZ_GAME_TIMER != 'undefined' && !QUIZ_GAME_TIMER) {
        var THIS = this;
        $('#quiz_wrapper').html('<div class="game_wrap quiz_game_txt"> Game starts in <br /></div>');
        QUIZ_GAME_TIMER = setInterval(function () {
            if (remainingTime <= 0) {
                $('#footer_wrapper').removeClass('hidden');
                clearInterval(QUIZ_GAME_TIMER);
                QUIZ_GAME_TIMER = null;
                $('.instructions_outer_wrapper').dialog("close");
                $('#quiz_inst_overlay, .instructions_outer_wrapper').remove()
                THIS.QuizGameResume(true);
                THIS.renderQuestion(false);
            } else {
                var timeRemain = THIS.pad(Math.floor((remainingTime / 3600))) + " : " + THIS.pad(Math.floor((remainingTime % 3600) / 60)) + " : " + THIS.pad(Math.floor((remainingTime % 3600) % 60));
                $('#quiz_wrapper').html('<div class="game_wrap quiz_game_txt"> Game starts in <br /> <span class="timer_span"><b>' + timeRemain + '</b></span></div>');
                remainingTime--;
            }
        }, 1000);
    }
    Client.closeProgressBar();
};

CertificatequizView.prototype.quizCoolDown = function (nextQuestion) {
    var answer_result = this.currentQuestionView.checkUserScore('', this.revise);
    if (answer_result['user_result_answer'].is_correct == 1 && nextQuestion) {
        this.lastAnswer = 'correct';
        this.renderQuestionAfterCoolDown();
    } else if (answer_result['user_result_answer'].is_correct == 0 && this.lifeInGame > 0 && nextQuestion) {
        this.lifeInGame--;
        this.lastAnswer = 'wrong';
        if (answer_result['user_result_answer'].is_skipped == IS_SKIPPED['skipped']) {
            this.lastAnswer = 'skip';
        }
        this.renderQuestionAfterCoolDown();
        return;
    } else {
        clearInterval(QUIZ_GAME_TIMER);
        clearInterval(QUESTION_TIMER);
        clearInterval(COOLDOWN_TIMER);
        QUIZ_GAME_TIMER = null;
        QUESTION_TIMER = null;
        COOLDOWN_TIMER = null;
        this.quizResultSave();
        this.QuizCertificateSubmit();
    }
};

CertificatequizView.prototype.showCoolDownStats = function () {
    var THIS = this;
    var activeAttempts = {};
    activeAttempts.life_in_game = this.lifeInGame;
    activeAttempts.last_answer = this.lastAnswer;
    this.renderTemplate('cooldown.tl', activeAttempts, '#quiz_wrapper', true);
    $('#footer_wrapper').addClass('hidden');
    $('#coolDown_text').html('Waiting for others to complete the question.');
    this.quiz.getActiveAttemptDetails(this.quiz_nid, function(activeAttempts){
        if(activeAttempts == INTERNET_ERROR || activeAttempts == ERROR_MESSAGE){
            return;
        }
        if($('#cool_down_stats').length > 0 && activeAttempts.total_attempts > 0){
            THIS.renderTemplate('cooldown_stats.tl', activeAttempts, '#cool_down_stats', true);
        }
    });
};

CertificatequizView.prototype.renderQuestionAfterCoolDown = function () {
    this.showCoolDownStats();
    var currentQuestStartTime = this.allQuestions.questions[this.currentQuestionIndex]['question_start_time'];
    var now = (new Date()).valueOf();
    var syncedServerTime = (new Date(now + SERVER_CLIENT_OFFSET)).valueOf();
    var cooldown = Math.round((currentQuestStartTime - syncedServerTime) / 1000);
    if (cooldown <= 0) {
        clearInterval(COOLDOWN_TIMER);
        COOLDOWN_TIMER = null;
        this.renderQuestion(true);
        return;
    }
    if (typeof COOLDOWN_TIMER != 'undefined' && !COOLDOWN_TIMER) {
        var THIS = this;
        COOLDOWN_TIMER = setInterval(function () {
            var timeRemain = THIS.pad(Math.floor((cooldown / 3600))) + " : " + THIS.pad(Math.floor((cooldown % 3600) / 60)) + " : " + THIS.pad(Math.floor((cooldown % 3600) % 60));
            if (cooldown <= 0) {
                clearInterval(COOLDOWN_TIMER);
                COOLDOWN_TIMER = null;
                $('#footer_wrapper').removeClass('hidden');
                $('.instructions_outer_wrapper').dialog("close");
                $('#quiz_inst_overlay, .instructions_outer_wrapper').remove()
                THIS.renderQuestion(true);
                return;
            } else if (cooldown <= 10) {
                $('#coolDown_text').html('Get Ready!<br/>Next question starts in');
            }
            $('#coolDown_timer').html('<span class="timer_span"><b>' + timeRemain + '</b><span>');
            cooldown--;
        }, 1000);
    }
};

CertificatequizView.prototype.computeQuestionStartTime = function (syncedServerTime, remainingTime) {
    if (typeof COOLDOWN_TIME == 'undefined') {
        COOLDOWN_TIME = 10;
    }
    if (typeof DEFAULT_QUESTION_TIME == 'undefined') {
        DEFAULT_QUESTION_TIME = 30;
    }
    for (var i in this.allQuestions.questions) {
        this.allQuestions.questions[i]['question_start_time'] = syncedServerTime + remainingTime;

        var quest_time_limit = this.allQuestions.questions[this.currentQuestionIndex].time_limit;
        if (typeof quest_time_limit == 'undefined' || quest_time_limit == null) {
            quest_time_limit = DEFAULT_QUESTION_TIME;
        }
        quest_time_limit = quest_time_limit * 1000;
        var cooldown_time = COOLDOWN_TIME * 1000;
        remainingTime = (remainingTime + quest_time_limit + cooldown_time);
    }
};

CertificatequizView.prototype.pad = function (n) {
    return n < 10 ? '0' + n : n;
};

CertificatequizView.prototype.saveQuizResult = function (alertSubmit) {
    $("#countDown").remove();
    CONTROLLER.setVariable('alertSubmitMsg', alertSubmit);
    if (typeof QUIZ_TIMER != 'undefined') {
        window.clearInterval(QUIZ_TIMER);
    }
    QUIZ_TIMER = null;
    this.MAX_TIME = null;
    this.updateQuestionIdleTime();
    this.quizResultSave(this.saveQuizResultCallback.bind(this));
};

CertificatequizView.prototype.saveQuizResultCallback = function (resultUploadStatus) {
    try {
        this.MAX_TIME = null;
        if (resultUploadStatus == "false" || resultUploadStatus == false) {
            this.allQuestions.quiz_result.resultUploadStatus = resultUploadStatus;
            this.quiz.quizResultSave(this.allQuestions['quiz_result'].result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']));
            this.exitFromQuiz(CONTROLLER.getVariable('quizSubmitErrorMessage'));
        }else{
            if (typeof QUIZ_MODULE != 'undefined' && QUIZ_MODULE == 'true') {
                this.onSaveQuizResultComplete(resultUploadStatus);
            } else if (resultUploadStatus == false) {
                this.exitFromQuiz(CONTROLLER.getVariable('quizSubmitErrorMessage'));
            } else {
                var accessDenied = CONTROLLER.getVariable("onAccessDenied");
                if (accessDenied != "true") {
                    CONTROLLER.setVariable("onAccessDenied", "false");
                    this.QuizCertificateSubmit();
                }
            }
        }
    } catch (e) {
        console.log('saveQuizResultCallback :' + e.message);
    }
}

CertificatequizView.prototype.onSaveQuizResultComplete = function (resultUploadStatus) {
    try {
        $('#footer_wrapper, #quizinfo_wrapper').addClass('hidden');
        var THIS = this;
        var errormessage = {};
        var quizSubmitErrorMessage = CONTROLLER.getVariable('quizSubmitErrorMessage');
        var alertSubmitMsg = CONTROLLER.getVariable('alertSubmitMsg');
        if (typeof quizSubmitErrorMessage == 'undefined') {
            quizSubmitErrorMessage = '';
        }
        if (typeof alertSubmitMsg == 'undefined' || alertSubmitMsg == '') {
            alertSubmitMsg = false;
        }

        if (this.allQuestions.attempts > this.allQuestions['takes'] && this.allQuestions['takes'] != 0) {
            errormessage.message = QUIZ_ALREADY_ATTEMPTED_MESSAGE;
        } else if ((resultUploadStatus == false || resultUploadStatus == 'false') && quizSubmitErrorMessage != '') {
            if (alertSubmitMsg != false)
                errormessage.message = alertSubmitMsg + '<br/>' + quizSubmitErrorMessage;
            else
                errormessage.message = quizSubmitErrorMessage;
        } else {
            if (alertSubmitMsg != false)
                errormessage.message = alertSubmitMsg + '<br/>' + AUTOMATIC_SUBMIT_MSG;
            else
                errormessage.message = QUIZ_END_MESSAGE;
        }

        if (typeof QUIZ_MODULE != 'undefined' && QUIZ_MODULE == 'true') {
            if ((resultUploadStatus == false || resultUploadStatus == 'false') && quizSubmitErrorMessage == QUIZ_NOT_SUBMITTED) {
                errormessage.end_button = 'Try Again';
            } else if((resultUploadStatus == "false" || resultUploadStatus == false) && (quizSubmitErrorMessage == USER_LOGOUT_ERROR)){
                errormessage.end_button = "Logout";
            } else if (typeof QUIZ_END_BUTTON_TEXT != 'undefined') {
                errormessage.end_button = QUIZ_END_BUTTON_TEXT;
            } else {
                errormessage.end_button = 'Logout'
            }
        }

        this.renderTemplate("quiz_end_message.tl", errormessage, "#quiz_wrapper", true);

        if (typeof QUIZ_MODULE != 'undefined' && QUIZ_MODULE == 'true') {
            if (resultUploadStatus == true) {
                QUIZ_BACK_REDIRECT_PATH = SUCCESS_REDIRECT_PATH;
                window.removeEventListener('beforeunload', onBeforeUnloadEvent);
                $('.end_progress_bar').removeClass('hidden');
                $('.quiz_end_logout_button').addClass('hidden');
                setTimeout(function () {
                    window.location.href = SUCCESS_REDIRECT_PATH;
                }, 3000);
            } else {
                QUIZ_BACK_REDIRECT_PATH = FAILURE_REDIRECT_PATH;
                if (quizSubmitErrorMessage == QUIZ_NOT_SUBMITTED) {
                    this.autoSubmitCounter = 6;
                    AUTO_SUBMIT_TIMER = window.setInterval(function () {
                        if (THIS.autoSubmitCounter <= 0) {
                            $('#quiz_submmit_retry').empty();
                            THIS.retryQuizSubmit();
                        } else {
                            THIS.autoSubmitCounter--;
                            $('#quiz_submmit_retry').html('We will automatically retry in ' + THIS.autoSubmitCounter + ' seconds.');
                        }
                    }, 1000);
                }
            }
        }

        if ((resultUploadStatus == "false" || resultUploadStatus == false) && (quizSubmitErrorMessage == USER_LOGOUT_ERROR)){
            onAccessDenied();
            return;   
        }

        $('.quiz_end_logout_button').unbind("click").bind("click", function (e) {
            if ((resultUploadStatus == false || resultUploadStatus == 'false') && quizSubmitErrorMessage == QUIZ_NOT_SUBMITTED) {
                THIS.retryQuizSubmit();
                return;
            }else if ((resultUploadStatus == "false" || resultUploadStatus == false) && (quizSubmitErrorMessage == USER_LOGOUT_ERROR)){
                onAccessDenied();
                return;   
            }
            window.removeEventListener('beforeunload', onBeforeUnloadEvent);
            if(typeof QUIZ_BACK_REDIRECT_PATH == 'undefined')
                QUIZ_BACK_REDIRECT_PATH = window.location.origin;
            window.location.href = QUIZ_BACK_REDIRECT_PATH;
        });

        CONTROLLER.setVariable('alertSubmitMsg', '');
        Client.closeProgressBar();
        return;
    } catch (e) {
        console.log('onSaveQuizResultComplete :' + e.message);
    }
}

CertificatequizView.prototype.retryQuizSubmit = function () {
    Client.showProgressBar("Submitting quiz results...");
    var THIS = this;
    if (typeof AUTO_SUBMIT_TIMER != 'undefined') {
        clearInterval(AUTO_SUBMIT_TIMER);
        AUTO_SUBMIT_TIMER = null;
    }
    setTimeout(function () {
        THIS.saveResultstoAdmin(THIS.allQuestions['quiz_result'].result_id, "", THIS.saveQuizResultCallback.bind(THIS));
    }, 500);
};

CertificatequizView.prototype.updateQuizParams = function (params) {
    try {
        var quiz_res_obj = this.quiz.getQuizResult(params.report.nid, true);
        if (quiz_res_obj != null && quiz_res_obj != '' && quiz_res_obj['quiz_result'].length != 0) {
            quiz_res_obj = quiz_res_obj['quiz_result']
            quiz_res_obj = quiz_res_obj[quiz_res_obj.length - 1];
        }
        if (quiz_res_obj.is_evaluated < -1) {
            this.exitFromQuiz("Test submitted successfully! <br/> Evaluation in progress, your result will be ready in sometime.");
            return false;
        }
        params.report.quiz_result = quiz_res_obj;

        var questions = this.quiz.getQuestionsForQuiz(params.report.nid, "", true);
        if (questions == "") {
            this.exitFromQuiz(INTERNET_ERROR);
            return false;
        }
        for (var i in questions["question"]) {
            if (!isNaN(i)) {
                for (var j in quiz_res_obj) {
                    if (!isNaN(j)) {
                        if (questions["question"][i].nid == quiz_res_obj[j]['question_result'].nid) {
                            questions["question"][i]['question_result'] = quiz_res_obj[j]['question_result'];
                        }
                    }
                }
            }
        }
        params.report.questions = questions["question"];
        return params;
    } catch (e) {
        console.log("function : updateQuizParams - " + e.message);
    }
};

CertificatequizView.prototype.setRemainingQuizTime = function (lastTimerRunTime, now) {
    try {
        var timeDiff = Math.round((now - lastTimerRunTime) / 1000);
        if (timeDiff > this.MAX_TIME) {
            timeDiff = this.MAX_TIME;
        }
        this.MAX_TIME = this.MAX_TIME - timeDiff;
        this.questionIdleTime['idle_time'] = this.questionIdleTime['idle_time'] + timeDiff;
        this.logUserAction('Paused:' + new Date(lastTimerRunTime).toString().replace(/.GMT.*/g, '') + ' Resumed:' + new Date(now).toString().replace(/.GMT.*/g, ''));
    } catch (e) {
        console.log("function : setRemainingQuizTime - " + e.message);
    }
};

CertificatequizView.prototype.updateQuestionIdleTime = function () {
    try {
        if (typeof this.questionIdleTime != "undefined") {
            var seconds = parseInt(this.questionIdleTime['idle_time']);
            var answer_result = $.extend(true, {}, this.questionIdleTime);
            answer_result['time_taken'] = parseInt(answer_result['time_taken']) + seconds;
            delete answer_result['idle_time'];
            delete answer_result['qIndex'];
            this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result;
            this.questionResultSave(answer_result);
            this.resetQuestionIdleTime();
        }
        return;
    } catch (e) {
        console.log("CertificatequizView : updateQuestionIdleTime - " + e);
    }
};

CertificatequizView.prototype.saveQuestionIdleTime = function () {
    try {
        if (this.currentQuestionIndex >= this.allQuestions.questions.length) {
            this.currentQuestionIndex = this.currentQuestionIndex - 1;
        }
        var currIndex = this.currentQuestionIndex;
        if (typeof this.questionIdleTime == "undefined" || Object.keys(this.questionIdleTime).length === 0) {
            if (typeof this.allQuestions['quiz_result'][currIndex] == "undefined" || typeof this.allQuestions['quiz_result'][currIndex]['question_result'] == "undefined") {
                var currQuest = this.allQuestions.questions[this.currentQuestionIndex];
                this.questionIdleTime = {
                    'nid': currQuest.nid,
                    'is_correct': 0,
                    'is_skipped': IS_SKIPPED['skipped'],
                    'answer': "",
                    'user_answer': "",
                    'points_awarded': 0,
                    'questiontype': currQuest.questiontype == "multichoice" ? "mcq" : currQuest.questiontype,
                    'start_time_per_question': getSyncedServerTime(),
                    'time_taken': 0
                };
            } else {
                var answer_result = JSON.stringify(this.allQuestions['quiz_result'][currIndex]['question_result']);
                this.questionIdleTime = $.parseJSON(answer_result);
                this.questionIdleTime['start_time_per_question'] = getSyncedServerTime();
            }
            this.questionIdleTime['idle_time'] = 0;
            this.questionIdleTime['qIndex'] = currIndex;
        }
        this.questionIdleTime['idle_time'] = this.questionIdleTime['idle_time'] + 1;
        this.questionIdleTime['answer_timestamp'] = getSyncedServerTime();
        Platform.saveObject("questionIdleTime", this.allQuestions['quiz_result'].result_id + '-' + OBJECT_USER_ID + '-time', "content", JSON.stringify(this.questionIdleTime));
    } catch (e) {
        console.log("CertificatequizView : saveQuestionIdleTime - " + e);
    }
};

CertificatequizView.prototype.resetQuestionIdleTime = function () {
    try {
        this.questionIdleTime = {};
        Platform.removeObject("questionIdleTime", this.allQuestions['quiz_result'].result_id + '-' + OBJECT_USER_ID + '-time');
    } catch (e) {
        console.log("CertificatequizView : resetQuestionIdleTime - " + e);
    }
};

CertificatequizView.prototype.showSectionLimitReached = function (currQuestionNid, showAlert, answerResult) {
    try {
        $(".section_limit_wrapper").remove();
        var sections = this.allQuestions.sections;
        if (sections.length == 0) {
            return false;
        }
        for (var i in sections) {
            var restrictAttempt = sections[i]["restrict_attempt"];
            var sectionLimit = sections[i]["section_evaluation_count"];
            if (typeof sectionLimit == "undefined" || !sectionLimit) {
                continue;
            }
            if (sections[i].questions.indexOf(currQuestionNid) > -1) {
                if (typeof restrictAttempt == "undefined" || restrictAttempt != "1") {
                    continue;
                }
                var messageObj = {
                    all: sections[i].questions.length,
                    limit: sectionLimit
                }
                var allQuestionsWithAnswers = [];
                for (var k = 0; k < this.allQuestions.questions.length; k++) {
                    var curr = this.allQuestions.questions[k];
                    if (sections[i].questions.indexOf(curr.nid) > -1) {
                        if (typeof curr['question_result'] != 'undefined'
                            && curr['question_result'].user_answer != null
                            && curr['question_result'].user_answer.length != 0
                            && Object.keys(curr['question_result'].user_answer).length != 0) {
                            allQuestionsWithAnswers.push(curr.nid);
                        }
                    }
                }
                if (allQuestionsWithAnswers.indexOf(currQuestionNid) > -1) {
                    return false;
                }
                if (allQuestionsWithAnswers.length >= sectionLimit) {
                    if (showAlert === true || showAlert === false) {
                        if ($(".section_limit_wrapper").length == 0) {
                            $('#quizContainer_wrap').append("<div class='section_limit_wrapper hidden'><div class='section_limit_close_icon'>x</div><div class='section_limit_text'>" + SECTION_LIMIT_REACHED.call(messageObj) + "</div></div>");
                        }
                        $(".section_limit_close_icon").unbind("click").bind("click", function () {
                            $(".section_limit_wrapper").remove();
                            return false;
                        });
                        if (showAlert === true) {
                            if (typeof answerResult != 'undefined'
                                && answerResult.user_answer != null
                                && answerResult.user_answer.length != 0
                                && Object.keys(answerResult.user_answer).length != 0) {
                                alert(SECTION_LIMIT_REACHED.call(messageObj), function () {
                                    $('.section_limit_wrapper').removeClass('hidden');
                                });
                            } else {
                                return false;
                            }
                        } else {
                            $('.section_limit_wrapper').removeClass('hidden');
                        }
                    }
                    return true;
                }
                break;
            }
        }
        return false;
    } catch (e) {
        console.log("CertificatequizView : showSectionLimitReached - " + e);
    }
};

CertificatequizView.prototype.checkForceRegisterAttempt = function () {
    try{
        var preView = CONTROLLER.getVariable("previousView");
	    var previousView = CONTROLLER.getVariable("preView");
	    if (((typeof preView !== 'undefined' && preView === "TestSeries")
	        || (typeof previousView !== 'undefined' && previousView === "Planner"))
	        && ((typeof this.allQuestions['allow_offline_attempts'] != 'undefined'
	                && this.allQuestions['allow_offline_attempts'] == '1')
	            || (typeof this.allQuestions['quiz_template'] == 'undefined'
	                || this.allQuestions['quiz_template'] == '2'))
        	&& (this.allQuestions['quiz_always'] != '1') || (this.allQuestions['allow_resume'] == '0')) {
                this.forceRegisterAttempt = true;
        }
    }catch(e){
        console.log("function : checkForceRegisterAttempt - " + e.message);
    }
};

CertificatequizView.prototype.startOrResumeQuiz = function (resume) {
    try{
        var THIS = this;
        if (resume) {
            var log = 'Quiz Resumed';
            THIS.logUserAction(log);
            THIS.startQuiz(resume);
        }else{
            var log = 'Quiz Started';
            THIS.logUserAction(log);
            THIS.getQuizInstructions();
        }
    }catch(e){
        console.log("function : startOrResumeQuiz - " + e.message);
    }
};
PracticequizView.prototype = new QuizView();
PracticequizView.prototype.constructor = PracticequizView.constructor;


function PracticequizView() {
    this.quiz = CONTROLLER.getService("Quiz");
    this.currentQuestionIndex = 0;
    this.allQuestions = '';
    this.mode = 'none';
    this.currentQuestionView = null;
    if (typeof TASK_ENABLED != "undefined" && TASK_ENABLED == "true") {
    this.task = CONTROLLER.getService("Task");
}
}
;

PracticequizView.prototype.render = function(params) {
    this.setDefaultStyles(params);
    TASKID = ""; TASK_STATUS = ""; var THIS = this;
    if (typeof TASK_ENABLED != "undefined" && TASK_ENABLED == "true") {
        if (CONTROLLER.activeViewName == "ListTask") {
            TASKID = params.task_id;
            TASK_STATUS = params.status;
        }
    }
    if(typeof params.embed == "undefined" || params.embed != "true"){
        this.renderHeader(params);
    }
    else if(params.embed == "true"){
        QUIZ_EMBED = true;
    }
    if(typeof ZOPIM_CHAT_ENABLE != 'undefined' && ZOPIM_CHAT_ENABLE == 'true'){
        if(typeof hideChat != "undefined"){
            hideChat();
        }
    }
    this.renderContent(params);
    this.renderFooter(params);
    this.setbodystyle(params);
    if (typeof TASK_ENABLED != "undefined" && TASK_ENABLED == "true") {
        if (TASKID != undefined && TASKID != "" && TASK_STATUS != "" && TASK_STATUS == 0)
        {
            if (Platform.getNetworkState() == true) {
                var status = THIS.task.updateTask(TASKID);
                if (status == 403) {
                    onAccessDenied();
                    return;
                }
            }
        }
    }

    $('.flipQuestion_endquiz').click(function(e) {
            THIS.quizEnd(this);
    });
    this.handleDeviceResize(params);
};

PracticequizView.prototype.setbodystyle = function()
{
 var screenwidth = $(window).width() + "px";
    var screenheight = $(window).height() + "px";
    $("body,#wrapper").css({
        width: screenwidth,
        height: screenheight
    });
    $('#quiz_wrapper').css({
        width: screenwidth,
        height: screenheight - 100
    });

    $("#content_wrapper").css({
        width: screenwidth,
        height: (screenheight - 63)
    });

    $("#header_wrapper").css({
        width: screenwidth + "px"
    });

    $("#footer_wrapper").css({
        width: $(window).width() + "px"
    });

    $('#header_wrapper').css('width', $(window).width());
    $("body,#wrapper,#quiz_wrapper").css('background','#FFF');
    $('#countDown').html('');
    $('#certificate_placeholder').hide();
     var catid = "";
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        catid = QUIZ_RESOURCE_PARAMS.catid;
    }
    else {
        catid = CONTROLLER.getVariable("activecatid");
    }

     $(".title_text").css("width", $(window).width() - 170);
    $('#wrapper').css({'overflow': 'hidden'});

    if($('#header_wrapper').css('position') == 'fixed'){
        if(($(window).width()<380)){
             $('#quiz_wrapper').css({'margin-top':'45px'})
        }
        else{
             $('#quiz_wrapper').css({'margin-top':'52px'});
        }
    }
};



PracticequizView.prototype.reportRender = function(action)
{
    if (action == true) {
        var answer_result = this.currentQuestionView.checkUserScore('',false,this.allQuestions);
        this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
        this.questionResultSave(answer_result['user_result_answer']);
    }
    for (var i = 0; i < this.allQuestions.questions.length; i++) {
        if (typeof this.allQuestions.questions[i].match_type != "undefined" && this.allQuestions.questions[i].match_type == 'sequence') {
            for (var j = 0; j < this.allQuestions.questions[i].match.length; j++) {
                if (this.allQuestions.questions[i].match[j]["answer"] != this.allQuestions.questions[i].match[j]["question"]) {
                    this.allQuestions.questions[i].match[j]["answer"] = this.allQuestions.questions[i].match[j]["question"];
                }
            }
        }
    }
    //save quiz results at end
    this.quizResultSave();
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        if (typeof OBJECT_TEST_COLLECTION != "undefined" && QUIZ_RESOURCE_PARAMS.type == OBJECT_TEST_COLLECTION) {
            var content_type = OBJECT_TEST_COLLECTION;
        } else {
            var content_type = OBJECT_QUIZ;
        }
        var currentTime = Math.floor(getSyncedServerTime() / 1000);
        if(OBJECT_VIDEO_DETAILS.endtime == 0){
            var videodetails = '{"totalduration":2,"duration":2,"count":"1","timestamp":"","updatedtime":"","createdtime":"","uid":"' + OBJECT_USER_ID + '","resourceid":"' + CONTROLLER.getVariable("activeresourceid") + '","starttime":"'+OBJECT_VIDEO_DETAILS.starttime+'","endtime":"'+ currentTime +'","agent":"mobile"' + ',"courseid":"' + QUIZ_RESOURCE_PARAMS.courseid + '","groupid":"' + QUIZ_RESOURCE_PARAMS.groupid + '","categoryid":"' + QUIZ_RESOURCE_PARAMS.catid + '","chapterid":"' + QUIZ_RESOURCE_PARAMS.chapterid + '","session":"' + OBJECT_USER_ID + '-' + currentTime + '","type":"'+ content_type +'"}';
            OBJECT_VIDEO_DETAILS = $.parseJSON(videodetails);
            OBJECT_VIDEO_DETAILS.viewmode = CONTROLLER.getVariable("resourcevvhmode");
            Platform.addOrUpdateVideoViewHistory(JSON.stringify(OBJECT_VIDEO_DETAILS));
        }
    }
    var params = {"report": this.allQuestions
        , "replace_item": '#quiz_wrapper'
        , "replace": true
    };
    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
        var id = CONTROLLER.getVariable('breakquizid');
        params.replace_item = '#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id;
    }
    CONTROLLER.loadChildView('Report', function(view) {
        view.render(params);
    });
};



PracticequizView.prototype.quizNext = function (button) {
    var log = 'Clicked next button';
    this.logUserAction(log);
    //load the next question
    var THIS = this;
    var type = this.allQuestions.questions[this.currentQuestionIndex].questiontype;
    var correct_answer_evaluation=this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation;
    //get current question result
    if (this.allQuestions.questions[this.currentQuestionIndex]["type"] != "truefalse") {
        var answer_result = this.currentQuestionView.checkUserScore(button, false, this.allQuestions);
        if (answer_result['user_result_answer'].is_correct == 0 && answer_result['user_result_answer'].is_skipped == IS_SKIPPED['skipped'] && answer_result['user_result_answer'].is_skipped == IS_SKIPPED['review_skipped']) {
            alert('Please fill the option before submitting');
            $('.quiz_next, .quiz_back').removeData('clicked');
            return "";
        }
        else if(this.allQuestions.repeat_until_correct != "undefined" && this.allQuestions.repeat_until_correct == 1){
            if (answer_result['user_result_answer'].is_correct == 0 && answer_result['user_result_answer'].is_skipped != 1 && (type == "multichoice" || type == "truefalse")) {
                alert('Please enter the correct answer');
                $('.quiz_next, .quiz_back').removeData('clicked');
                return "";
            } else if (type == "long_answer" || type == "short_answer") {
                var min_charlen = parseInt(this.allQuestions.questions[this.currentQuestionIndex].min_characters);
                var max_charlen = parseInt(this.allQuestions.questions[this.currentQuestionIndex].max_characters);
                if (correct_answer_evaluation == '8') {
                    if (!(answer_result['user_result_answer'].user_answer >= parseFloat(this.allQuestions.questions[this.currentQuestionIndex].min_characters) && answer_result['user_result_answer'].user_answer <= parseFloat(this.allQuestions.questions[this.currentQuestionIndex].max_characters))) {
                        this.currentQuestionView.repeat_until_correct_error();
                        $('.quiz_next, .quiz_back').removeData('clicked');
                        return "";
                    }
                } else if (answer_result['user_result_answer'].answer_charlength < min_charlen) {
                    alert(MIN_CHARACTERS + min_charlen + ' characters.');
                    $('.quiz_next, .quiz_back').removeData('clicked');
                    return "";
                } else if (answer_result['user_result_answer'].answer_charlength > max_charlen) {
                    alert(MAX_CHARACTERS + max_charlen + ' characters.');
                    $('.quiz_next, .quiz_back').removeData('clicked');
                    return "";
                } else if(this.allQuestions.questions[this.currentQuestionIndex].correct_answer_evaluation == 6 && this.allQuestions.questions[this.currentQuestionIndex].answer_range_end > 9 && (answer_result['user_result_answer'].user_answer != '' && (answer_result['user_result_answer'].user_answer).length < 2)){
                    alert(SHORT_ANSWER_TYPE_RADIO_ERROR_MESSAGE);
                    $('.quiz_next, .quiz_back').removeData('clicked');
                    return "";
                 }
                else if ((answer_result['user_result_answer'].is_correct == 0 && answer_result['user_result_answer'].is_skipped != IS_SKIPPED['skipped'] && answer_result['user_result_answer'].is_skipped != IS_SKIPPED['review_skipped']) && type != "long_answer" && (type == "short_answer" && correct_answer_evaluation != '3')) {
                    this.currentQuestionView.repeat_until_correct_error();
                    $('.quiz_next, .quiz_back').removeData('clicked');
                    return "";
                }
            } else if ((answer_result['user_result_answer'].is_correct == 0 && answer_result['user_result_answer'].is_skipped != IS_SKIPPED['skipped'] && answer_result['user_result_answer'].is_skipped != IS_SKIPPED['review_skipped']) && type != "long_answer" && (type == "short_answer" && correct_answer_evaluation != '3')) {
                this.currentQuestionView.repeat_until_correct_error();
                $('.quiz_next, .quiz_back').removeData('clicked');
                return "";
            }
            else if ((answer_result['user_result_answer'].is_correct == 0 && answer_result['user_result_answer'].is_skipped != IS_SKIPPED['skipped'] && answer_result['user_result_answer'].is_skipped != IS_SKIPPED['review_skipped']) && type == "matching") {
                this.currentQuestionView.repeat_until_correct_error();
                $('.quiz_next, .quiz_back').removeData('clicked');
                return "";
            }
            $('.quiz_next, .quiz_back').removeData('clicked');
        }
        if ((this.allQuestions.questions.length > this.currentQuestionIndex + 1)) {
            if ((answer_result['user_result_answer'].is_correct == 1 || this.allQuestions.repeat_until_correct != "undefined" && this.allQuestions.repeat_until_correct == 0)|| type == "long_answer" || type == "short_answer") {
                this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
                this.questionResultSave(answer_result['user_result_answer']);
                this.currentQuestionIndex = this.currentQuestionIndex + 1;
                this.renderQuestion(true);
            }
        } else {
                //printing report form and updating quiz results
                setTimeout(function(){
                    THIS.reportRender(true);
                },300);
            }
    } else {
        this.currentQuestionIndex = this.currentQuestionIndex + 1;
        this.renderQuestion(true);
    }
};




//PracticequizView.prototype.show_legend = function(button, number) {
//    var THIS = this;
//    var questions = [];
//    if (typeof this.allQuestions.questions !== 'undefined') {
//        questions['questions'] = this.allQuestions.questions;
//        this.renderTemplate('nav_list.tl', questions, '#quiz_wrapper', false);
//        $('.question_nav_title_no').click(function(e) {
//            var page_no = $(this).attr('page');
//            THIS.goQuestion(this, page_no);
//        });
//
//
//        $('.quiz_submit').click(function() {
//            THIS.quizEnd(this);
//        });
//    }
//
//};

//PracticequizView.prototype.goQuestion = function(button, number) {
//    var answer_result = this.currentQuestionView.checkUserScore();
//    this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
//    this.questionResultSave(answer_result['user_result_answer']);
//    this.currentQuestionIndex = parseInt(number);
//    this.renderQuestion(true);
//};




LongAnswerView.prototype = new View();
LongAnswerView.prototype.constructor = LongAnswerView.constructor;
function LongAnswerView() {
    this.question = '';
    this.localeQuestion = '';
}
;


LongAnswerView.prototype.renderContent = function(params) {
    var THIS = this;
    this.question = params.quiz;
    EVALUATION_MODE="manual";
    this.question['questiontype'] = "long_answer";
    this.quizAttrs = params.quizAttrs;
    this.start_time=params.start_question;
    try {
        var questionContent = params.quiz;
		if(typeof params.quiz.updatedLocale != 'undefined') {
			var updatedLocale = params.quiz.updatedLocale;
	        if (typeof updatedLocale != 'undefined' && Object.keys(updatedLocale).length > 1) {
	            if (typeof params.activeQuizLanguage != 'undefined' && params.activeQuizLanguage != '') {
	                questionContent = params.quiz.updatedLocale[params.activeQuizLanguage];
	            }
	            questionContent.localeInfo = params.localeInfo;
	        }
		}
        questionContent['count'] = params.index;
        questionContent['total'] = params.total_questions;
        this.localeQuestion = questionContent;
        if (typeof this.localeQuestion.localeQuestionId == 'undefined') {
            this.localeQuestion.localeQuestionId = this.question.nid;
        }
        if(typeof this.question.question_result != 'undefined')
            this.localeQuestion.question_result = this.question.question_result;
        this.localeQuestion['qustype']=0;
    } catch (e) {
    };
    if(typeof SECTION_TITLE != "undefined"){
        this.localeQuestion.sectiontitle = SECTION_TITLE;
    }
    this.renderTemplate('long_question.tl', this.localeQuestion, params.replace_item, params.replace);
    var context = CONTROLLER.getVariable('contextView');
    if(typeof context !='undefined' && context == 'TestSeries'){
        $('#quiz_wrapper').addClass('testseries_quiz_wrapper');
    }
    else if(typeof context !='undefined' && context == 'course'){
        $('#quiz_wrapper').addClass('course_quiz_wrapper');
        $('#content_wrapper').addClass('content_wrapper_height');
    }
    if (params.mode == "review") {
        $("#edit-tries").attr('contenteditable','false');
        $('.drag_it_down, .choose_buttons').addClass('hidden');
        $('.textarea_resizable').css({'background': 'none', 'border': 'none', 'margin-top': '10px'});
        if (typeof params.quiz['question_result'] != 'undefined' && typeof params.quiz['question_result'].user_answer != 'undefined') {
            var user_answer = params.quiz['question_result'].user_answer;
        } else {
            var user_answer = "";
        }
        $('.textarea_resizable').html(user_answer)
        if($(window).width() < 380){
             $('.textarea_resizable').css({'margin-left': '6px'});
        }
    }
    if (typeof params["quizAttrs"]["sections"] != "undefined" && !$.isEmptyObject(params["quizAttrs"]["sections"])) {
        var sectionFound = false;
        for(var i in params["quizAttrs"]["sections"]){
            for(var j in params["quizAttrs"]["sections"][i]["questions"]){
                if (params["quizAttrs"]["sections"][i]["questions"][j] == this.localeQuestion.localeQuestionId) {
                    sectionFound = true;
                    var body = params["quizAttrs"]["sections"][i]["body"];
                    $("#questionTitle").prepend("<fieldset><legend>"+params["quizAttrs"]["sections"][i]["title"]+"</legend><div id='section_text_wrapper'>"+body+"</div></div></fieldset>");
                    $('#section_text_wrapper').readmore({
                        speed: 100,
                        lessLink: '<a href="#">Read Less</a>'
                    });
                    break;
                }
            }
        }
        if (sectionFound == false && typeof params["quizAttrs"]["updatedLocale"] != 'undefined') {
            updateLocaleSections = params["quizAttrs"]["updatedLocale"]['quiz_section'];
            for (var i in updateLocaleSections) {
                if (params.activeQuizLanguage == i) {
                    for (var j in updateLocaleSections[i]) {
                        for (var k in updateLocaleSections[i][j]["questions"]) {
                            if (updateLocaleSections[i][j]["questions"][k] == this.localeQuestion.localeQuestionId) {
                                var body = updateLocaleSections[i][j]["body"];
                                $("#questionTitle").prepend("<fieldset><legend>" + updateLocaleSections[i][j]["title"] + "</legend><div id='section_text_wrapper'>" + body + "</div></div></fieldset>");
                                $('#section_text_wrapper').readmore({
                                    speed: 100,
                                    lessLink: '<a href="#">Read Less</a>'
                                });
                                break;
                            }
                        }
                    }
                }
            }
        }
    }
    $('#edit-clear-fieldset').click(
    function(){
        $(".textarea_resizable").val('');
    });

    //for selection
    this.setbodystyle(params);

    if(params.mode=='review'){
        $('.solution_content_wrapper').removeClass('hidden');
       if (typeof this.localeQuestion['solution'] != 'undefined') {
            if (this.localeQuestion['solution']['type'] == 'HTML5' && typeof this.localeQuestion['solution']['content'] == 'undefined') {
                $('.solution_content_wrapper').addClass('hidden');
            }
            if(this.localeQuestion['solution']['type'] == 'Video' || this.localeQuestion['solution']['type'] == 'Document'){
                $(".solution_text_wrapper").removeClass("hidden");
            }
        }
        $('.solution_text_wrapper').click(function (e) {
            var type = $(this).attr('type');
            if (typeof QPM != "undefined" && QPM.widget && QPM.widget == "true") {
                var width = $("#wrapper").width();
                if (type == 'Video') {
                    var height = Math.round(width * 9 / 16);
                } else {
                    var height = "";
                }
                var resourcepath = PORTAL_QUESTION_SOLUTION + THIS.localeQuestion.localeQuestionId + "&width=" + width + "&height=" + height;
                $(".portal-iframe-container").remove();
                $("#wrapper").append("<div class='portal-iframe-container'><div class='portal-iframe-close'></div><iframe id='flash_iframe' allowfullscreen frameborder='0' style='display:flex;height:100%;width:100%;' width=" + width + " src=" + resourcepath + "></iframe></div>");
                $(".portal-iframe-close").click(function (e) {
                    $(".portal-iframe-container").remove();
                    return false;
                });
                return false;
            }
            var id = $(this).attr('id');
            var type = $(this).attr('type');
            if(type == 'Document'){
                 type = OBJECT_BOOK;
            }else if(type == 'Video'){
                 type = OBJECT_VIDEO;
            }
             var params = {"resourceid": id , "solution_embed": true, "type":type,
                "activeViewName": CONTROLLER.activeViewName, "activeView": CONTROLLER.activeView,"courseid" :  CONTROLLER.getVariable("activecourseid"),
                "catid" : CONTROLLER.getVariable("activecatid"), "groupid" : CONTROLLER.getVariable("activegroupid"),
                "solutionDuration": THIS.localeQuestion['solution']['duration'],'solution_type':type};
              CONTROLLER.loadView("Resource", params);
        });
    }else{
        $('.question_wrapper').click(function(e) {
            var id = $(this).attr('qid');
            THIS.selectAnswer(this, id,false);
        });
    }
    if (typeof ENABLE_QUIZ_CLEAR_RESPONSE != "undefined" && ENABLE_QUIZ_CLEAR_RESPONSE == "true") {
        $('.choose_buttons').addClass('hidden')
    }
};

LongAnswerView.prototype.setbodystyle = function()
{
     var ParentView = CONTROLLER.getVariable("activeParentView");
       $('#flipQuestion_showanswer').hide();
       $('#show_solution_wrapper').hide();
       var body_height=$('body').height();
       $('#quizContainer_wrap').css('height',(body_height-175));
       $('#question_wrapper img,.solution_content_wrapper img').css({'max-width':'100%', 'max-height':'100%'});
    if($('#header_wrapper').css('position') == 'fixed'){
        if (ParentView != "DashBoardQuiz") {
            if (($(window).width() < 380)) {
                $('#quiz_wrapper').css({'margin-top': '50px'})
            }
            else {
                $('#quiz_wrapper').css({'margin-top': '52px'})
            }
        }
        else {
            if ($(window).width() < 380) {
                $('#quiz_wrapper').css({'margin-top': '10px'});
            }
            else {
                $('#quiz_wrapper').css({'margin-top': '12px'});
            }
        }
    }
};

LongAnswerView.prototype.selectAnswer = function(button, option,show_answer) {	//Invoked when user click on any answer options
    var if_chosen = 0;
    var is_checked;
    is_checked= document.getElementById('edit-tries[answer]-' + option);
    this.resetOptions(is_checked);
    if(is_checked.checked==false || is_checked.type=='radio' || show_answer==true){
        this.correct_ids = new Array();
        this.option = option;
        $('#edit-tries[answer]-' + option).attr("checked", true);
        document.getElementById('edit-tries[answer]-' + option).checked = true;
        this.if_chosen = this.question.choice_multi;
        //-----check the user_answer is correct or wrong and check if alternatives correct answer is more then two----//
        this.question_value = this.localeQuestion.alternatives;
        this.getChosen_value(button);
        //------in case of Practice test and if Repeat Until Correct is enabled----------------//
        if (this.quizAttrs.repeat_until_correct != "undefined" && this.quizAttrs.repeat_until_correct == 1) {
            this.repeat_until_correct_error(button);
        }

        //---------in case of hint----------------//
      //  var checkCorrectAnswer = this.checkUserCorrectAnswer(button,false);
        this.checkUserScore(button, false);
   }
   else{
         document.getElementById('edit-tries[answer]-' + option).checked = false;
   }

};


LongAnswerView.prototype.repeat_until_correct_error = function(button) {
    var question = this.localeQuestion;
    var hint = "";
    var solution = "";
          if(question.type==PRACTICE){
                this.nid_join = this.correct_ids.join(',');
                $('#flipQuestion_showanswer').hide();
                $('#show_solution_wrapper').hide();
                $('#hint_quiz').remove();

                if (this.nid_join == '') {
                    //geting hint
                    if (typeof question['hints'] !== 'undefined' && question['hints'][0]['value']!=null) {
                        var len = question['hints'].length;
                        var random = getRandomInt(0, len);
                        var hint = question['hints'][random]['value'];
                    }
                    $('#flipQuestion_showanswer').show();
                    if(typeof question['solution'] !== 'undefined'){
                        if(question['solution']['show_solution'] == "true"){
                            solution = question['solution'];
                        }
                    }

                    if(solution!= "" || hint != ""){
                        this.showHelpDialog(hint,solution)
                    }
                }
          }
};

LongAnswerView.prototype.showHelpDialog = function(hint,solution) {
    $('#help_dialog').remove();
    $('body').append('<div id="help_dialog" ></div>');
    var  scrollposition = $(window).scrollTop();
    var text = "";

    var width = 0;
    var height = 0;
    var contentUtils = CONTROLLER.getService("ContentUtils");


    if($(window).width() < 420){
        width = 280;
        height = 220;
    }
    else{
        width = 400;
        height = 400;
    }

    $('#help_dialog').dialog({
        resizable: false,
        modal: true,
        height: 'auto',
        width: width,
        create: function(event, ui) {
            $('body').bind('touchmove', function(e){e.preventDefault()});
        },
        beforeClose: function(event, ui) {
            $('body').unbind('touchmove');
        },
        open: function() {
            //window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        close: function() {
                $(this).dialog("close");
                $('#help_dialog').remove();
                $("#transparent_wrapper").css({
                    "display":"none"
                });
                $('.ui-widget-overlay').removeClass('custom-overlay');
                return false;

            },
        dialogClass: 'dlgfixed helpDialog',
        position: "center",
    });


    text = "<div id='help_dialog_close'><div id='help_dialog_close_img'></div></div>";

    if(hint != ""){
        text = text + '<div id="hint_quiz" class="hintstatusMessage"><div id="quiz_hint">\n\
        </div><div class="quiz_hint_text">Hint: ' + hint + '</div></div>';
    }

    if(solution != ""){
        text = text + '<div id="show_solution_wrapper" >\n\
                      <div id="show_solution"></div><div id=show_solution_text>Click here to learn more</div></div>';
    }

    text = text + "<div class='quiz-help-icon'><img src='images/Help-icon.d8152090.png' class='quiz-help-icon_img'></div>";

    $('.helpDialog').css({'max-height': height+'px'});
    $('#help_dialog').css({'max-height': (height-10)+'px', 'overflow-x':'hidden'});

    $(".ui-widget-overlay").bind("click",function(){
        closeDialog();
    });
    $('#help_dialog').html(text);

    if($(window).width() < $(window).height()){
        $('.quiz-help-icon,.ui-dialog').addClass("portrait");
    }
    else{
        $('.quiz-help-icon,.ui-dialog').addClass("landscape");
    }

    if(solution != ""){
        $("#show_solution_wrapper").unbind("click").bind("click",function() {
            $('#help_dialog').dialog('close');
            var courseid = ""; var catid = ""; var chapterid = "";
            if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
                courseid = QUIZ_RESOURCE_PARAMS.courseid;
                catid = QUIZ_RESOURCE_PARAMS.catid;
                chapterid = QUIZ_RESOURCE_PARAMS.chapterid;
            }
            else {
                courseid = CONTROLLER.getVariable("activecourseid");
                catid = CONTROLLER.getVariable("activecatid");
                chapterid = CONTROLLER.getVariable("activechapterid")
            }
            if(solution.type == 'HTML5'){
                showSolutionofTypeHtml(solution['content']);
                return "";
            }
            if (solution['type'] == 'Document') {
                var type = OBJECT_BOOK;
            } else if (type == 'Video') {
                var type = OBJECT_VIDEO;
            }
            var params = {"catid": catid, "groupid": CONTROLLER.getVariable("activegroupid"),
                "chapterid": chapterid, "courseid": courseid,
                "resourceid": solution['clipping_nid'], "solution_embed": true,
                "activeViewName": CONTROLLER.activeViewName, "activeView": CONTROLLER.activeView,
                "solutionDuration":solution['duration'],"type":type};
                CONTROLLER.loadView("Resource", params);
        });
    }
    $(".ui-dialog-titlebar").hide();

    $(".dlgfixed").center(false);

    $('#help_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('.ui-button-text:contains("Re-Download"),.ui-button-text:contains("Delete")')
        .parents('.ui-button').after('<div class="dialog_button_seperator"></div>');
    $('.ui-dialog-buttonset .ui-button').css({
        "width":"32.8%"
    });

    $('html body .ui-dialog').css({'z-index': '99999999',
    background: '#0080C6','color': '#fff','position': 'fixed','outline':'none'});
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
    $('#help_dialog_close').bind('click',function(){
        $('#help_dialog').dialog('close');
    });
        $('#hint_quiz').effect( "shake", "", 300, "");

}

LongAnswerView.prototype.getChosen_value = function(button) {
    var correct_ids = new Array();
    var option = this.option;
    var isCorrect = false;

    $.each(this.question_value, function(key, value) {
        if (value['score_if_chosen'] == 1) {
            if (value['id'] == option) {
                correct_ids.push(value['id']);
                isCorrect = true;
            }
        }
    });
    this.correct_ids = correct_ids;
};

LongAnswerView.prototype.checkUserCorrectAnswer = function(button) {
    var skip = 0, correct = 0, answer = new Array(),wrong_answer=false;
    var option = this.option;
    var userinput=document.getElementById('edit-tries').value;
    EVALUATION_MODE="manual";
     userinput =userinput.trim();
     if(userinput===""){
          skip =1;
     }
     else{
          answer.push(userinput);
     }
 }

LongAnswerView.prototype.checkUserScore = function(button, skip_flag) {
    var answer = new Array();
    var option = this.option;
    var option_json = {};
    var question_result = {};
    var skip = 0, correct = 0, answer = new Array(),param;
    var userinput=document.getElementById('edit-tries').value;
    var user_score = 0;
    var char_length=$.trim($("#edit-tries").val()).length;

    userinput = userinput.trim();
    if (userinput ==="") {
        skip = IS_SKIPPED['skipped'];
    }
    if(skip_flag==true){
        if (userinput === "")
            skip = IS_SKIPPED['review_skipped'];
        else
            skip = IS_SKIPPED['review_answered'];
    }
    param = this.checkUserCorrectAnswer(button);

    option_json['user_result_answer'] = {
        'nid': this.question.nid,
        'is_skipped': skip,
        'is_correct':0,
        'answer_timestamp': getSyncedServerTime(),
        'user_answer':userinput,
        'points_awarded':user_score,
        'questiontype':this.question['questiontype'],
        'start_time_per_question':this.start_time,
        'answer_charlength':char_length,

    };
    return  option_json;

};





LongAnswerView.prototype.showCorrectOptions = function(question_value,mode) {
    var THIS = this;
    var user_checked=new Array();
        try{
            $('tbody tr').each(function() {
            var button_type = $(this).find('input').attr('type');
            if (button_type == 'checkbox') {
                var selected = $(this).find('input.form-checkbox').is(':checked');
                if (selected) {
                   user_checked.push($(this).attr('value'));
                }
            } else {
                var selected = $(this).find('input.form-radio').is(':checked');
                if (selected) {
                    user_checked.push($(this).attr('value'));
                }
            }
        });

        $.each(question_value, function(key, value) {
            if (value['score_if_chosen'] == 1) {
                var t = document.getElementById('option' + value['id']);
                THIS.selectAnswer(t, value['id'],true);
                $('#innerQuizContainer').append('<div id="explaination">');
               // $('#explaination').html($('.explaination_div_hidden').html());
                $('.explaination_div_content').html('');
                $('#flipQuestion_showanswer').hide();
                $('#show_solution_wrapper').hide();
                if(mode=='review'){
                      $('#option' + value['id']).addClass('correctAnswer');
                }

                if(jQuery.inArray(value['id'], user_checked)  != -1) {
                    var index = array.indexOf(value['id']);
                    user_checked.splice(index, 1);
                }
            }else{
                $('#option' + value['id']).removeClass('wrongAnswer');
                document.getElementById('edit-tries[answer]-' + value['id']).checked = false;
            }
        });
        if (mode == 'review' || this.localeQuestion.type == PRACTICE) {
            for (var i in user_checked) {
                $('#option' + user_checked[i]).addClass('wrongAnswer');
            }
        }
    }catch(e){};

};

LongAnswerView.prototype.resetOptions = function(button) {
 //  if(this.question.type==PRACTICE){
        $('#flipQuestion_showanswer').hide();
        $('#show_solution_wrapper').hide();
        $('#hint_quiz').remove();
        if(button.type=='radio'){
        $('.question_wrapper').removeClass('correctAnswer');
        $('.question_wrapper').removeClass('wrongAnswer');
        $('.question_wrapper').addClass('unselected');
        $('#explaination').remove();
        } else{
           if(button.checked==true){
                $('#option' + button.value).removeClass('wrongAnswer');
                $('#option' + button.value).removeClass('correctAnswer');
            }
            $('#explaination').remove();
            $('.question_wrapper').addClass('unselected');
        }
  //  }
};



//got to change
LongAnswerView.prototype.setPreviousData = function(question, passrate) {
    var THIS = this;
    var nids = new Array();
    if(typeof this.question['question_result']!=='undefined'){
         nids = this.question['question_result']['user_answer'];
    }
   //-----------------set previous data--------------------------//
    try {
        for (var i = 0; i < nids.length; i++) {
            $('#edit-tries').val(nids);
            document.getElementById('edit-tries[answer]-' + nids[i]).checked = true;
            if (this.localeQuestion.type == PRACTICE) {
                var question_value = this.localeQuestion.alternatives;
                $.each(question_value, function (key, value) {
                    if (CHECK_ON_PASS_RATE != undefined && CHECK_ON_PASS_RATE == 'true' && passrate != undefined && passrate == 0) {
                        if ((value['id'] == nids[i] && value['score_if_chosen'] == 1) || (value['id'] == nids[i] && value['score_if_chosen'] != 1)) {
                            $('#option' + value['id']).addClass('correctAnswer');
                        }
                    }
                    else {
                        if (value['id'] == nids[i] && value['score_if_chosen'] == 1) {
                            $('#option' + value['id']).addClass('correctAnswer');
                        } else if (value['id'] == nids[i] && value['score_if_chosen'] != 1) {
                            $('#option' + value['id']).addClass('wrongAnswer');
                        }
                    }
                });
            }
        }

    } catch (e) {
    }
    ;

};
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}










MatchView.prototype = new View();
MatchView.prototype.constructor = MatchView.constructor;
function MatchView() {
    this.question = '';
    this.localeQuestion = '';
}



MatchView.prototype.renderContent = function(params) {
    var THIS = this;
    this.question = params.quiz;
    this.start_time=params.start_question;
    this.quizAttrs = params.quizAttrs;
    var matchanswers = [];
    var matchquestions = [];
    var matrixquestions = [];
    var matrixanswers = [];
    var user_sorted = CONTROLLER.getVariable("user_sorted");

    try {
        var questionContent = params.quiz;
        var updatedLocale = params.quiz.updatedLocale;
        if (typeof updatedLocale != 'undefined' && Object.keys(updatedLocale).length > 1) {
            if (typeof params.activeQuizLanguage != 'undefined' && params.activeQuizLanguage != '') {
                questionContent = params.quiz.updatedLocale[params.activeQuizLanguage];
            }
            questionContent.localeInfo = params.localeInfo;
        }
        questionContent['count'] = params.index;
        questionContent['total'] = params.total_questions;
        questionContent['type'] = params.type;
        this.localeQuestion = questionContent;
        if (typeof this.localeQuestion.localeQuestionId == 'undefined') {
            this.localeQuestion.localeQuestionId = this.question.nid;
        }
        if(typeof this.question.question_result != 'undefined')
            this.localeQuestion.question_result = this.question.question_result;
        this.question['questiontype'] = "matching";
    } catch (e) {
    }
    ;

    for(i in this.localeQuestion.match){
        matchanswers[i] = [];
        matrixquestions[i] = [];
        matrixanswers[i] = [];
        if(typeof params.quiz.match_type!="undefined" && params.quiz.match_type=='sequence'){
            matchanswers[i]["body"] = this.localeQuestion.match[i]["question"];
            this.localeQuestion.match[i]["answer"] = this.localeQuestion.match[i]["question"]
        }else if(typeof params.quiz.match_type!="undefined" && params.quiz.match_type=='matrix'){
            if (this.localeQuestion.match[i]["question"]) {
                matrixquestions[i]["body"] = this.localeQuestion.match[i]["question"];
                matrixquestions[i]["id"] = this.localeQuestion.match[i]["match_id"];
            }else{
                 matrixquestions.splice(i, 1);
            }
            if (this.localeQuestion.match[i]["answer"]) {
                matrixanswers[i]["body"] = this.localeQuestion.match[i]["answer"];
                matrixanswers[i]["id"] = this.localeQuestion.match[i]["match_id"];
            }else{
                 matrixanswers.splice(i, 1);
            }
        }
        else{
            matchanswers[i]["body"] = this.localeQuestion.match[i]["answer"];
        }

        matchanswers[i]["match_id"] = this.localeQuestion.match[i]["match_id"];
        matchquestions.push(this.localeQuestion.match[i]["question"]);
        }

    this.localeQuestion.matchanswers = shuffleArray(matchanswers);
    this.localeQuestion.matchquestions = matchquestions;
    if( params.mode=='review'){
        if(params.quiz.match_type == 'matrix'){
            this.localeQuestion["matrixanswers"] = matrixanswers;
            this.localeQuestion["matrixquestions"] = matrixquestions;
            this.localeQuestion["matrixquestions"] = this.getMatrixOptions();
            this.renderTemplate('matrix_summary.tl', this.localeQuestion, params.replace_item, params.replace);
              $('.solution_content_wrapper').removeClass('hidden');
              if (typeof this.localeQuestion['solution'] != 'undefined') {
                if (this.localeQuestion['solution']['type'] == 'HTML5' && (this.localeQuestion['solution']['content'] == 'undefined')) {
                    $('.solution_content_wrapper').addClass('hidden');
                }
                if(this.localeQuestion['solution']['type'] == 'Video' || this.localeQuestion['solution']['type'] == 'Document'){
                    $(".solution_text_wrapper").removeClass("hidden");
                }
            }
              $('.solution_text_wrapper').click(function(e) {
                var id = $(this).attr('id');
                var type = $(this).attr('type');
                THIS.displaySolutionContent(id, type);
            });
        } else {
            this.checkUserScore('', false);
            this.renderTemplate('match_summary.tl', this.localeQuestion, params.replace_item, params.replace);
            $('.solution_content_wrapper').removeClass('hidden');
            if (typeof this.localeQuestion['solution'] != 'undefined') {
                if (this.localeQuestion['solution']['type'] == 'HTML5' && (this.localeQuestion['solution']['content'] == 'undefined')) {
                    $('.solution_content_wrapper').addClass('hidden');
                }
                if(this.localeQuestion['solution']['type'] == 'Video' || this.localeQuestion['solution']['type'] == 'Document'){
                    $(".solution_text_wrapper").removeClass("hidden");
                }
            }
            $('.solution_text_wrapper').click(function(e) {
                var id = $(this).attr('id');
                var type = $(this).attr('type');
                THIS.displaySolutionContent(id, type);
            });
        }
    }else if(typeof params.quiz.match_type!="undefined" && params.quiz.match_type=='sequence'){
        this.matchtype="sequence";
        this.renderTemplate("put_in_sequence.tl", this.localeQuestion, params.replace_item, params.replace);
        if (this.quizAttrs.repeat_until_correct != "undefined" && this.quizAttrs.repeat_until_correct != 1 && typeof THIS.question.question_result != "undefined") {
            if (typeof THIS.question.question_result['user_attemted'] == "undefined" && THIS.question.question_result['user_attemted'] != "true") {
                CONTROLLER.setVariable("user_sorted", "undefined");
                user_sorted = "undefined";
            }
            if ((typeof user_sorted == "undefined" || user_sorted != true)) {
                this.initDragDrop();
            }
        }else{
             this.initDragDrop();
        }
    }else if(typeof params.quiz.match_type!="undefined" && params.quiz.match_type=='matrix'){
        this.localeQuestion["matrixanswers"] = matrixanswers;
        this.localeQuestion["matrixquestions"] = matrixquestions;
        this.localeQuestion["matrixquestions"] = this.getMatrixOptions();
        this.matchtype="matrix";
       this.renderTemplate("question_table_matrix.tl",this.localeQuestion, params.replace_item, params.replace);
    }
    else{
        this.matchtype="matching";
        $('.question_wrapper').click(function(e) {
            var id = $(this).attr('qid');
            THIS.selectAnswer(this, id,false);
        });
        this.renderTemplate('question_table_match.tl', this.localeQuestion, params.replace_item, params.replace);
    }
    var context = CONTROLLER.getVariable('contextView');
    if(typeof context !='undefined' && context == 'TestSeries'){
        $('#quiz_wrapper').addClass('testseries_quiz_wrapper');
    }
    else if(typeof context !='undefined' && context == 'course'){
        $('#quiz_wrapper').addClass('course_quiz_wrapper');
        $('#content_wrapper').addClass('content_wrapper_height');
    }
    if (typeof params["quizAttrs"]["sections"] != "undefined" && !$.isEmptyObject(params["quizAttrs"]["sections"])) {
        var sectionFound = false;
        for(var i in params["quizAttrs"]["sections"]){
            for(var j in params["quizAttrs"]["sections"][i]["questions"]){
                if (params["quizAttrs"]["sections"][i]["questions"][j] == this.localeQuestion.localeQuestionId) {
                    sectionFound = true;
                    var body = params["quizAttrs"]["sections"][i]["body"];
                    $("#questionTitle").prepend("<fieldset><legend>"+params["quizAttrs"]["sections"][i]["title"]+"</legend><div id='section_text_wrapper'>"+body+"</div></div></fieldset>");
                    $('#section_text_wrapper').readmore({
                        speed: 100,
                        lessLink: '<a href="#">Read Less</a>'
                    });
                    break;
                }
            }
        }
        if (sectionFound == false && typeof params["quizAttrs"]["updatedLocale"] != 'undefined') {
            updateLocaleSections = params["quizAttrs"]["updatedLocale"]['quiz_section'];
            for (var i in updateLocaleSections) {
                if (params.activeQuizLanguage == i) {
                    for (var j in updateLocaleSections[i]) {
                        for (var k in updateLocaleSections[i][j]["questions"]) {
                            if (updateLocaleSections[i][j]["questions"][k] == this.localeQuestion.localeQuestionId) {
                                var body = updateLocaleSections[i][j]["body"];
                                $("#questionTitle").prepend("<fieldset><legend>" + updateLocaleSections[i][j]["title"] + "</legend><div id='section_text_wrapper'>" + body + "</div></div></fieldset>");
                                $('#section_text_wrapper').readmore({
                                    speed: 100,
                                    lessLink: '<a href="#">Read Less</a>'
                                });
                                break;
                            }
                        }
                    }
                }
            }
        }
    }
    $(".match_option_choose img.flag").addClass("flagvisibility");
    $(".match_option_choose dt a").click(function() {
        var id = $(this).attr('nid');
        THIS.id = id;
        if($("#match_options_" + id + " ul").hasClass('hidden')){
            $('.match_options ul').addClass('hidden');
            $("#match_options_" + id + " ul").removeClass('hidden');
        }else{
            $("#match_options_" + id + " ul").addClass('hidden');
        }
    });

    $(".match_option_choose dd ul li a").click(function() {
        var text = $(this).html();
        var id = $(this).attr('id');
        $("#match_options_select_"+THIS.id +" dt a span").html(text);
        $("#match_options_select_"+THIS.id +" dt a .user_option").attr('id', id);
        $("#match_options_" + THIS.id  + " ul").addClass('hidden');
        $('.match_option_choose dt a span p').css({'-webkit-margin-after': '0em','-webkit-margin-before': '0em'})
    });
 //for selection
    this.setbodystyle(params);
    
};

MatchView.prototype.initDragDrop = (function () {
    var THIS=this;
    THIS.user_sorted = false;
    var el = document.getElementById('sortable');
	Sortable.create(el,{
            scroll: true,
        onSort: function(evt) {
            THIS.user_sorted = true;
            CONTROLLER.setVariable("user_sorted",  THIS.user_sorted);
        }
    });
});


MatchView.prototype.setbodystyle = function()
{
     var ParentView = CONTROLLER.getVariable("activeParentView");
       $('#flipQuestion_showanswer').hide();
       $('#show_solution_wrapper').hide();
       var body_height=$('body').height();
       var container_height = (body_height-125);
       $('#quizContainer_wrap').css('height',(body_height-175));
       $('#question_wrapper img, .solution_content_wrapper img').css({'max-width':'100%', 'max-height':'100%'});
       if($(window).width() > 380 ){
           $('#sortable').css('height',(container_height*(70/100)));
       }
       else{
           $('#sortable').css('height',(container_height*(60/100)));
       }
    if($('#header_wrapper').css('position') == 'fixed'){
        if (ParentView != "DashBoardQuiz") {
            if (($(window).width() < 380)) {
                $('#quiz_wrapper').css({'margin-top': '50px'})
            }
            else {
                $('#quiz_wrapper').css({'margin-top': '52px'})
            }
        }
        else {
            if ($(window).width() < 380) {
                $('#quiz_wrapper').css({'margin-top': '10px'});
            }
            else {
                $('#quiz_wrapper').css({'margin-top': '12px'});
            }
        }
    }

    if ($(window).width() > $(window).height()) {
        if((typeof BREAK_MODE != 'undefined'  && BREAK_MODE == 'true')) {
            $('#sortable').css('height', $(window).width() - 140 + 'px');
        }
    }

}
;

MatchView.prototype.selectAnswer = function(button, option,show_answer) {	//Invoked when user click on any answer options
    var if_chosen = 0;
    var is_checked;
    is_checked= document.getElementById('edit-tries[answer]-' + option);
    this.resetOptions(is_checked);
    if(is_checked.checked==false || is_checked.type=='radio' || show_answer==true){
        this.correct_ids = new Array();
        this.option = option;
        $('#edit-tries[answer]-' + option).attr("checked", true);
        document.getElementById('edit-tries[answer]-' + option).checked = true;
        this.if_chosen = this.question.choice_multi;
        //-----check the user_answer is correct or wrong and check if alternatives correct answer is more then two----//
        this.question_value = this.localeQuestion.alternatives;
        this.getChosen_value(button);
        //------in case of Practice test and if Repeat Until Correct is enabled----------------//
        if (this.quizAttrs.repeat_until_correct != "undefined" && this.quizAttrs.repeat_until_correct == 1) {
            this.repeat_until_correct_error(button);
        }
        //---------in case of hint----------------//
      //  var checkCorrectAnswer = this.checkUserCorrectAnswer(button,false);
        this.checkUserScore(button, false);
   }
   else{
         document.getElementById('edit-tries[answer]-' + option).checked = false;
   }

};


MatchView.prototype.repeat_until_correct_error = function(button) {
    var question = this.localeQuestion;
    var hint = "";
    var solution = "";
          if(question.type==PRACTICE){
                $('#flipQuestion_showanswer').hide();
                $('#show_solution_wrapper').hide();
                $('#hint_quiz').remove();

                    //geting hint
                    if (typeof question['hints'] !== 'undefined' && question['hints'][0]['value']!=null) {
                        var len = question['hints'].length;
                        var random = getRandomInt(0, len);
                        var hint = question['hints'][random]['value'];
                    }
                    $('#flipQuestion_showanswer').show();
                    if(typeof question['solution'] !== 'undefined'){
                        if(question['solution']['show_solution'] == "true"){
                            solution = question['solution'];
                        }
                    }

                    if(solution!= "" || hint != ""){
                        this.showHelpDialog(hint,solution)
                    }else{
                        alert('Please enter correct answer');
                    }
          }
};

MatchView.prototype.showHelpDialog = function(hint,solution) {
    $('#help_dialog').remove(); var THIS = this;
    $('body').append('<div id="help_dialog" ></div>');
    var  scrollposition = $(window).scrollTop();
    var text = "";

    var width = 0;
    var height = 0;
    var contentUtils = CONTROLLER.getService("ContentUtils");


    if($(window).width() < 420){
        width = 280;
        height = 220;
    }
    else{
        width = 400;
        height = 400;
    }

    $('#help_dialog').dialog({
        resizable: false,
        modal: true,
        height: 'auto',
        width: width,
        create: function(event, ui) {
            $('body').bind('touchmove', function(e){e.preventDefault()});
        },
        beforeClose: function(event, ui) {
            $('body').unbind('touchmove');
        },
        open: function() {
            //window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        close: function() {
                $(this).dialog("close");
                $('#help_dialog').remove();
                $("#transparent_wrapper").css({
                    "display":"none"
                });
                $('.ui-widget-overlay').removeClass('custom-overlay');
                return false;

            },
        dialogClass: 'dlgfixed helpDialog',
        position: "center",
    });


    text = "<div id='help_dialog_close'><div id='help_dialog_close_img'></div></div>";

    if(hint != ""){
        text = text + '<div id="hint_quiz" class="hintstatusMessage"><div id="quiz_hint">\n\
        </div><div class="quiz_hint_text">Hint: ' + hint + '</div></div>';
    }

    if(solution != ""){
        text = text + '<div id="show_solution_wrapper" >\n\
                      <div id="show_solution"></div><div id=show_solution_text>Click here to learn more</div></div>';
    }

    text = text + "<div class='quiz-help-icon'><img src='images/Help-icon.d8152090.png' class='quiz-help-icon_img'></div>";

    $('.helpDialog').css({'max-height': height+'px'});
    $('#help_dialog').css({'max-height': (height-10)+'px', 'overflow-x':'hidden'});

    $(".ui-widget-overlay").bind("click",function(){
        closeDialog();
    });
    $('#help_dialog').html(text);

    if($(window).width() < $(window).height()){
        $('.quiz-help-icon,.ui-dialog').addClass("portrait");
    }
    else{
        $('.quiz-help-icon,.ui-dialog').addClass("landscape");
    }

    if(solution != ""){
        $("#show_solution_wrapper").unbind("click").bind("click",function() {
            $('#help_dialog').dialog('close');
            closeDialog();
            if (solution['type'] == 'HTML5') {
                showSolutionofTypeHtml(solution['content']);
                return "";
            }
            var id = solution['clipping_nid'];
            var qView = CONTROLLER.getView("Quiz");
            qView.showSolutionForQuestion(id, solution['type'], solution['duration'], THIS.localeQuestion.localeQuestionId);
            e.stopPropagation();
            return false;
        });
    }
    $(".ui-dialog-titlebar").hide();

    $(".dlgfixed").center(false);

    $('#help_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('.ui-button-text:contains("Re-Download"),.ui-button-text:contains("Delete")')
        .parents('.ui-button').after('<div class="dialog_button_seperator"></div>');
    $('.ui-dialog-buttonset .ui-button').css({
        "width":"32.8%"
    });

    $('html body .ui-dialog').css({'z-index': '99999999',
    background: '#0080C6','color': '#fff','position': 'fixed','outline':'none'});
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
    $('#help_dialog_close').bind('click',function(){
        $('#help_dialog').dialog('close');
    });
        $('#hint_quiz').effect( "shake", "", 300, "");

}

MatchView.prototype.getChosen_value = function(button) {
    var correct_ids = new Array();
    var option = this.option;
    var isCorrect = false;

    $.each(this.question_value, function(key, value) {
        if (value['score_if_chosen'] == 1) {
            if (value['id'] == option) {
                correct_ids.push(value['id']);
                isCorrect = true;
            }
        }
    });
    this.correct_ids = correct_ids;
};

MatchView.prototype.checkUserCorrectAnswer = function (button, user_answer) {
    var skip = 0, correct = 0, answer = new Array(), wrong_answer = false;
    var option = this.option, selected = 0, THIS = this;
    var simple_score = this.question.choice_boolean;
    var user_score = 0;  var user_wrong_score = 0;
    var max_score = this.question.max_score;
    if (simple_score != 1 || simple_score == "null") {
        if (typeof this.question.partial_positive_score != 'undefined' && this.question.partial_positive_score) {
            var partial_pos_score = parseInt(this.question.partial_positive_score);
        }
        if (typeof this.question.partial_negative_score != 'undefined' && this.question.partial_negative_score) {
            var partial_neg_score = parseInt(this.question.partial_negative_score);
        }
    }

    if(this.question.match_type != 'matrix'){ //If simple score is not enabled (for type matching and sequence)
    if(typeof THIS.question.question_result != "undefined" && (typeof this.matchtype != "undefined" && this.matchtype=="sequence")){
        THIS.question.question_result['user_attemted'] = "true";
    }
    $.each(this.localeQuestion.match, function (key, value) {
        answer.push(value["match_id"]);
        if (simple_score != 1 || simple_score == "null") {
            if (user_answer[key] == value["match_id"]) {
                correct = 1;
                user_score++;
                THIS.localeQuestion.match[key]["status"] = "correct";
            }
            else if(user_answer[key] != '0'){
                wrong_answer = true;
                user_wrong_score++;
                THIS.localeQuestion.match[key]["status"] = "wrong";
            }
        }

        selected = selected++;

    });
        if (typeof partial_pos_score != 'undefined') {
            user_score = (user_score * partial_pos_score);
        }
        if (user_score < 0) {
            if (typeof this.question.negative_score != 'undefined' && this.question.negative_score != 0 && (this._skipped != 1 || this.user_sorted == true)) {
                user_score = -(parseInt(this.question.negative_score));
            }
            correct = 0;
        } else if (user_score > this.question.max_score) {
            user_score = this.question.max_score;
        }
        $.each(this.localeQuestion.match, function(key, value) {
            if(user_answer[key] == 0 && value["match_id"] != 0){
                correct = 0;
            }
        });
    }else{
        var matrixAnswers = this.getMatrixOptions(user_answer);
        for (var i = 0; i < matrixAnswers.length; i++) {
            var flag = false;
            if (matrixAnswers[i].status != 'skipped') {
                if (matrixAnswers[i].status == 'correct') {
                    correct = 1;
                    flag = true;
                } else if (matrixAnswers[i].status == 'wrong' && simple_score == 1) {
                    wrong_answer = true;
                    user_score = 0;
                    correct = 0;
                } else if (matrixAnswers[i].status == 'wrong') {
                     wrong_answer = true;
                     correct = 0;
                }
                if (simple_score != 1 || simple_score == "null") { // simple score is not enabled
                    if (flag) {
                        user_score++;
                    } else {
                        user_wrong_score++;
                        if (correct != 1) {
                            wrong_answer = true;
                        }
                    }
                }else{   // simple score is enabled
                    if(wrong_answer != true){
                        if (flag) {
                            user_score++;
                        }
                    }
                }
            }
        }
        if (simple_score != 1 || simple_score == "null") { // simple score is not enabled for matrix
            if(typeof partial_neg_score == 'undefined'){
                partial_neg_score = 0;
            }
            if(typeof partial_pos_score != 'undefined'){
                  user_score = (user_score * partial_pos_score) - (user_wrong_score * partial_neg_score);
            }
            if (user_score < 0 && user_score < -(parseInt(partial_neg_score)) && typeof partial_neg_score != 'undefined') {
                if (partial_neg_score != 0) {
                    user_score = -(parseInt(this.question.negative_score));
                } else {
                    user_score = 0;
                }
            } else if (typeof this.question.negative_score != 'undefined' && user_score < -(parseInt(this.question.negative_score))) {
                if (this.question.negative_score != 0) {
                    user_score = -(parseInt(this.question.negative_score));
                } else {
                    user_score = 0;
                }
            } else if (user_score > this.question.max_score) {
                user_score = this.question.max_score;
            } else if (user_answer.length != this.localeQuestion.matrix_answers.length) {
                 correct = 0;
            }
        } else if (simple_score == 1) { // simple score is enabled
            if (wrong_answer == true || (user_answer.length > 0 && user_score == 0) || ((user_answer.length > 0) && (user_answer.length != this.localeQuestion.matrix_answers.length))) {
                if (parseInt(this.question.negative_score) != 0 && typeof this.question.negative_score != 'undefined') {
                    user_score = -(parseInt(this.question.negative_score));
                } else {
                    user_score = 0;
                }
                correct = 0;
            } else if (user_score > this.question.max_score) {
                user_score = this.question.max_score
            }else if(correct == 1){
                user_score = max_score;
            }
        }
    }
    if (simple_score == 1 && this.question.match_type != 'matrix') { // If simple score is enabled (for type matching and sequence)
        if (answer.length == user_answer.length) {
            correct = 1;
            user_score = max_score;
            for (i = 0; i < answer.length; i++) {
                if (answer[i] != user_answer[i]) {
                    correct = 0;
                    user_score = 0;
                    THIS.localeQuestion.match[i]["status"] = "wrong";
                    wrong_answer = true;
                } else {
                    THIS.localeQuestion.match[i]["status"] = "correct";
                }
            }
        }
        if (wrong_answer == true) {
            if (this.question.negative_score != 0 && this._skipped != 1 && typeof this.question.negative_score != 'undefined') {
                user_score = -(parseInt(this.question.negative_score));
                correct = 0;
            } else {
                correct = 0;
                user_score = 0;
            }
        }
    }

    if (this.option == '' || this.option === 'undefined' || typeof this.option == "undefined") {
        skip = 1;
    }

    if (wrong_answer == true) {
        correct = 0;
    }
    return  {'correct': correct, 'skip': skip, 'answer': answer, 'user_score': user_score};
};

MatchView.prototype.checkUserScore = function(button, skip_flag) {
    var tempArray = new Array();
    var option = this.option;
    var option_json = {};
    var question_result = {};
    this._skipped = 0;
    var user_answer = [];
    useranswerjson = {};

    var skip = 0, correct = 0, answer = new Array(),param,THIS = this;
    var THIS = this;
    $('.match_option_choose dt a .user_option,.match_options_select,.slide,.li p,tbody tr td .form-checkbox:checked').each(function() {
        if(THIS.matchtype=="sequence")
        {
            id=$(this).attr("title");
        }else if(THIS.matchtype=="matrix"){
                tempArray.push($(this).attr('value'));
                user_answer.push($(this).attr('value'));
                this.option = 1;
        }
        else{
            var id = $(this).attr('id');
        }
        if (THIS.matchtype != "matrix") {
            if ($(this).val() || THIS.matchtype == "sequence" || (typeof id != 'undefined' && id != "")) {
                tempArray.push(id);
                user_answer.push(id);
            }
            else {
                user_answer.push("0");
            }
        }

    });

    if(THIS.matchtype != "matrix"){
        for (k in user_answer) {
            useranswerjson[this.localeQuestion.match[k]["match_id"]] = user_answer[k];
            if (user_answer[k] != 0) {
                $.each(this.localeQuestion.match, function(key, value) {
                    if (user_answer[k] == value["match_id"]) {
                        if (THIS.matchtype == "sequence") {
                            THIS.localeQuestion.match[k].user_answer = value["question"];
                        }
                        else {
                            THIS.localeQuestion.match[k].user_answer = value["answer"];
                            THIS.localeQuestion.match[k].user_answer_id = user_answer[k];
                        }
                    }
                });
            }
            else {
                THIS.localeQuestion.match[k].user_answer = "";
                THIS.localeQuestion.match[k].user_answer_id = "";
            }
        }
    }else{
        useranswerjson = user_answer;
    }

    if (tempArray.length === 0) {
        skip = IS_SKIPPED['skipped'];
        this._skipped = 1;
    }
    if (skip_flag == true) {
        if (tempArray.length === 0)
            skip = IS_SKIPPED['review_skipped'];
        else
            skip = IS_SKIPPED['review_answered'];
    }
    param = this.checkUserCorrectAnswer(button, user_answer);

    if (skip == IS_SKIPPED['skipped'] || skip == IS_SKIPPED['review_skipped']) {
        param.user_score = 0;
    }

    option_json['user_result_answer'] = {
        'nid': this.question.nid,
        'is_correct': param.correct,
        'is_skipped': skip,
        'answer_timestamp': getSyncedServerTime(),
        'answer': param.answer,
        'user_answer':useranswerjson,
        'points_awarded':param.user_score,
        'questiontype':this.question['questiontype'],
        'start_time_per_question':this.start_time

    };
    return  option_json;

};





MatchView.prototype.showCorrectOptions = function(question_value,mode) {
    var THIS = this;
    var user_checked=new Array();
     $('#flipQuestion_showanswer').hide();
     $(".form-checkbox").prop("checked", false);
        try{
        if (question_value.match_type == 'matrix') {
            $.each(question_value.matrix_answers, function (key, value) {
                var select_id = value;
                var answers = select_id.split("|");
                $("#matrix_" + answers[0] + "\\|" + answers[1]).prop("checked", true);
            });
            $.each(question_value.matrixquestions, function(key, value) {
                question_value.matrixquestions[key]['user_answer'] = question_value.matrixquestions[key]['correct_answers'];
            });

        } else if (question_value.questiontype == 'matching') {
            $.each(question_value.match, function(key, value) {
                if (typeof question_value.match_type != 'undefined' && question_value.match_type == 'sequence') {
                    var temp = $('#' + value["match_id"]);
                    $("#sortable").append(temp);
                }
                else {
                    $('#match_options_select_' + value["match_id"] + ' ' + '.user_option').html(value["answer"]);
                    $('#match_options_select_' + value["match_id"] + ' ' + '.user_option').removeAttr('id');
                    $('#match_options_select_' + value["match_id"] + ' ' +  '.user_option').attr('id',  value["match_id"]);
                }
            });
        }

    }catch(e){};

};

MatchView.prototype.resetOptions = function(button) {
 //  if(this.question.type==PRACTICE){
        $('#flipQuestion_showanswer').hide();
        $('#show_solution_wrapper').hide();
        $('#hint_quiz').remove();
        if(button.type=='radio'){
        $('.question_wrapper').removeClass('correctAnswer');
        $('.question_wrapper').removeClass('wrongAnswer');
        $('.question_wrapper').addClass('unselected');
        $('#explaination').remove();
        } else{
           if(button.checked==true){
                $('#option' + button.value).removeClass('wrongAnswer');
                $('#option' + button.value).removeClass('correctAnswer');
            }
            $('#explaination').remove();
            $('.question_wrapper').addClass('unselected');
        }
  //  }
};

MatchView.prototype.getMatrixOptions = function (user_answer) {
    if (typeof user_answer != 'undefined') {
        var question_result = {};
        question_result['user_answer'] = user_answer;
    } else if(typeof this.question.question_result != 'undefined') {
        var question_result = this.question.question_result;
    }
    var matrix_qsns = this.localeQuestion.matrixquestions;
    var matrix_ansrs = this.localeQuestion.matrixanswers;
    for(var i=0; i < matrix_qsns.length; i++ ){
        var matrixoptions = []; var matrixoption_answers = [];
        var user_selected_answers = [];
        for(var j=0; j < matrix_ansrs.length; j++){
            var qsn_matchid = parseInt(matrix_qsns[i]["id"]);
            var ansr_matchid = parseInt(matrix_ansrs[j]["id"]);
            matrixoptions.push((qsn_matchid + "|" + ansr_matchid));
            for (var k = 0; k < this.localeQuestion.matrix_answers.length; k++) { //get all the correct answers for each question
                var ansr = this.localeQuestion.matrix_answers[k].split("|");
                if (matrix_qsns[i].id == ansr[0]) {
                    if (ansr[1] == this.localeQuestion.match[j]['match_id']) {
                        matrixoption_answers.push(this.localeQuestion.match[j]['answer'])
                    }
                }
            }
        }
        if (typeof question_result != 'undefined') { //get the user answeres for each question
            for (var m = 0; m < question_result['user_answer'].length; m++) {
                    for (var n = 0; n < matrix_ansrs.length; n++) {
                    var user_answers = question_result['user_answer'];
                    var user_answered = user_answers[m].split("|");
                    if (matrix_qsns[i].id == user_answered[0]) {
                        if (user_answered[1] == matrix_ansrs[n]['id']) {
                                user_selected_answers.push(matrix_ansrs[n]['body'])
                        }
                    }
                }
                }
            }
         this.localeQuestion.matrixquestions[i]['options'] = matrixoptions;
         this.localeQuestion.matrixquestions[i]['correct_answers'] = matrixoption_answers;

         // check user selted answer is correct or wrong
        if (typeof question_result != 'undefined') {
            this.localeQuestion.matrixquestions[i]['user_answers'] = user_selected_answers;
            if ((this.localeQuestion.matrixquestions[i]['correct_answers'].length == this.localeQuestion.matrixquestions[i]['user_answers'].length) && this.localeQuestion.matrixquestions[i]['user_answers'].length != 0) {
                for (var t = 0; t < this.localeQuestion.matrixquestions[i]['user_answers'].length; t++) {
                    if ($.inArray(this.localeQuestion.matrixquestions[i]['user_answers'][t], this.localeQuestion.matrixquestions[i]['correct_answers']) < 0) {
                        // the element is not in the array
                        this.localeQuestion.matrixquestions[i].status = 'wrong';
                        break;
                    } else {
                        this.localeQuestion.matrixquestions[i].status = 'correct';
                    }
                }
            } else if (this.localeQuestion.matrixquestions[i]['user_answers'].length != 0) {
                this.localeQuestion.matrixquestions[i].status = 'wrong';
            }else{
                 this.localeQuestion.matrixquestions[i].status = 'skipped';
            }
        }
    }
    return this.localeQuestion.matrixquestions;
};


//got to change
MatchView.prototype.setPreviousData = function (question, passrate) {
    var THIS = this;
    var type = question.match_type
    var nids = new Array();
    if (typeof this.question['question_result'] !== 'undefined') {
        if (this.question.match_type != 'matrix') {
            nids = this.question['question_result']['user_answer'];
            try {
                //-----------------set previous data--------------------------//
                $.each(this.localeQuestion.match, function (key, value) {
                    var id = nids[Object.keys(nids)[key]];
                    if (typeof type != 'undefined' && type == 'sequence') {
                        var temp = $('#' + id);
                        $("#sortable").append(temp);
                    }
                    else if (question.question_result.is_skipped != IS_SKIPPED['skipped'] && question.question_result.is_skipped != IS_SKIPPED['review_skipped'] && id != 0) {
                        var user_answer_pos = findIndexByKeyValue(THIS.localeQuestion.matchanswers, 'match_id', id);
                        var user_answer = THIS.localeQuestion.matchanswers[user_answer_pos]['body'];
                        $('#match_options_select_' + value["match_id"] + ' ' + '.user_option').html(user_answer);
                        $('#match_options_select_' + value["match_id"] + ' ' + '.user_option').removeAttr('id');
                        $('#match_options_select_' + value["match_id"] + ' ' + '.user_option').attr('id', id);
                    }
                    if (THIS.localeQuestion.type == PRACTICE) {
                        if (CHECK_ON_PASS_RATE != undefined && CHECK_ON_PASS_RATE == 'true' && passrate != undefined && passrate == 0) {
                            $('.correct_answer_title, .correct_answer').addClass('hidden');
                            $('.user_answer_title span').addClass('correct');
                        } else {
                            if (question.type == PRACTICE) {
                                if (id == value["match_id"]) {
                                    $('.match_options_select[id=' + value["match_id"] + ']').addClass('correctAnswer');
                                }
                                else {
                                    $('.match_options_select[id=' + value["match_id"] + ']').addClass('wrongAnswer');
                                }
                            }

                        }
                    }
                });
                $("#sortable").sortable('refresh');
                //        for (var i = 0; i < nids.length; i++) {
                //            document.getElementById('edit-tries[answer]-' + nids[i]).checked = true;
                //            var question_value = THIS.question.alternatives;
                //            $.each(question_value, function(key, value) {
                //                if (value['id'] == nids[i] && value['score_if_chosen'] == 1) {
                //                    $('#option' + value['id']).addClass('correctAnswer');
                //                } else if (value['id'] == nids[i] && value['score_if_chosen'] != 1) {
                //                    $('#option' + value['id']).addClass('wrongAnswer');
                //                }
                //            });
                //        }
                $('.match_option_choose dt a span p').css({ '-webkit-margin-after': '0em', '-webkit-margin-before': '0em' })

            } catch (e) {
            }
        } else {
            $.each(this.question.question_result['user_answer'], function (key, value) {
                var select_id = value;
                var numbers = select_id.split("|");
                $("#matrix_" + numbers[0] + "\\|" + numbers[1]).prop("checked", true);
            });
        }
    }
};
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

MatchView.prototype.displaySolutionContent = function(id, type) {
    var id = $(this).attr('id');
    var type = $(this).attr('type');
    var qView = CONTROLLER.getView("Quiz");
    qView.showSolutionForQuestion(id, type, this.localeQuestion['solution']['duration'], this.localeQuestion.localeQuestionId);
    return false;
};









McqView.prototype = new View();
McqView.prototype.constructor = McqView.constructor;
function McqView() {
    this.question = '';
    this.localeQuestion = '';
}
;



McqView.prototype.renderContent = function(params) {
    var THIS = this;
    this.start_time=params.start_question;
    this.question = params.quiz;
    this.quizAttrs = params.quizAttrs;
    try {
        var questionContent = params.quiz;
        var updatedLocale = params.quiz.updatedLocale;
        if (typeof updatedLocale != 'undefined' && Object.keys(updatedLocale).length > 1) {
            if (typeof params.activeQuizLanguage != 'undefined' && params.activeQuizLanguage != '') {
                questionContent = params.quiz.updatedLocale[params.activeQuizLanguage];
            }
            questionContent.localeInfo = params.localeInfo;
        }
        questionContent['count'] = params.index;
        questionContent['total'] = params.total_questions;
        questionContent['type'] = params.type;
        this.localeQuestion = questionContent;
        if (typeof this.localeQuestion.localeQuestionId == 'undefined') {
            this.localeQuestion.localeQuestionId = this.question.nid;
        }
        if(typeof this.question.question_result != 'undefined')
            this.localeQuestion.question_result = this.question.question_result;
    } catch (e) {
    }
    ;
    var qsn_body = this.localeQuestion['body'];
    if(qsn_body.indexOf('multichoiceStack(') >= 0){
        qsn_body = qsn_body.split("multichoiceStack");
        var funparams = qsn_body[1].slice(qsn_body[1].indexOf('(') +1,qsn_body[1].indexOf(')'));
        funparams = funparams.replace(/["']+/gm, '');
        funparams = funparams.split(",");
        params = this.multichoiceBlankTypeQuestions(funparams[0],funparams[1],params);
    }
    this.renderTemplate('question_table.tl', this.localeQuestion, params.replace_item, params.replace);
    if (typeof MathJax != "undefined") {
        MathJax.Hub.Queue(["Typeset",MathJax.Hub,'quiz_wrapper']);
    }
    var context = CONTROLLER.getVariable('contextView');
    if(typeof context !='undefined' && context == 'TestSeries'){
        $('#quiz_wrapper').addClass('testseries_quiz_wrapper');
    }
    else if(typeof context !='undefined' && context == 'course'){
        $('#quiz_wrapper').addClass('course_quiz_wrapper');
        $('#content_wrapper').addClass('content_wrapper_height');
    }
    if (typeof params["quizAttrs"]["sections"] != "undefined" && !$.isEmptyObject(params["quizAttrs"]["sections"])) {
        var sectionFound = false;
        for(var i in params["quizAttrs"]["sections"]){
            for(var j in params["quizAttrs"]["sections"][i]["questions"]){
                if (params["quizAttrs"]["sections"][i]["questions"][j] == this.localeQuestion.localeQuestionId) {
                    sectionFound = true;
                    var body = params["quizAttrs"]["sections"][i]["body"];
                    $("#questionTitle").prepend("<fieldset><legend>"+params["quizAttrs"]["sections"][i]["title"]+"</legend><div id='section_text_wrapper'>"+body+"</div></div></fieldset>");
                    $('#section_text_wrapper').readmore({
                        speed: 100,
                        lessLink: '<a href="#">Read Less</a>'
                    });
                    break;
                }
            }
        }
        if (sectionFound == false && typeof params["quizAttrs"]["updatedLocale"] != 'undefined') {
            updateLocaleSections = params["quizAttrs"]["updatedLocale"]['quiz_section'];
            for (var i in updateLocaleSections) {
                if (params.activeQuizLanguage == i) {
                    for (var j in updateLocaleSections[i]) {
                        for (var k in updateLocaleSections[i][j]["questions"]) {
                            if (updateLocaleSections[i][j]["questions"][k] == this.localeQuestion.localeQuestionId) {
                                var body = updateLocaleSections[i][j]["body"];
                                $("#questionTitle").prepend("<fieldset><legend>" + updateLocaleSections[i][j]["title"] + "</legend><div id='section_text_wrapper'>" + body + "</div></div></fieldset>");
                                $('#section_text_wrapper').readmore({
                                    speed: 100,
                                    lessLink: '<a href="#">Read Less</a>'
                                });
                                break;
    }
                        }
                    }
                }
            }
        }
    }
    //for selection
    this.setbodystyle(params);

    if( params.mode=='review'){
        $('input[name="tries[answer]"]').attr('disabled', 'disabled');
        $('.form-checkbox').attr('disabled', 'disabled');
        $('.solution_content_wrapper').removeClass('hidden');
        if (typeof this.localeQuestion['solution'] != 'undefined') {
            if (this.localeQuestion['solution']['type'] == 'HTML5' && typeof this.localeQuestion['solution']['content'] == 'undefined') {
                $('.solution_content_wrapper').addClass('hidden');
            }
            if(this.localeQuestion['solution']['type'] == 'Video' || this.localeQuestion['solution']['type'] == 'Document'){
                $(".solution_text_wrapper").removeClass("hidden");
            }
        }
        $('.solution_text_wrapper').click(function (e) {
            var id = $(this).attr('id');
            var type = $(this).attr('type');
            var qView = CONTROLLER.getView("Quiz");
            qView.showSolutionForQuestion(id, type, THIS.localeQuestion['solution']['duration'], THIS.localeQuestion.localeQuestionId);
            return false;
        });
    }else{
        $('.question_wrapper').click(function(e) {
            var id = $(this).attr('qid');
            THIS.selectAnswer(this, id,false);
            e.stopImmediatePropagation();
        });
    }


};


McqView.prototype.setbodystyle = function()
{
     var ParentView = CONTROLLER.getVariable("activeParentView");
       $('#flipQuestion_showanswer').hide();
       $('#show_solution_wrapper').hide();
       var body_height=$('body').height();
       if(BREAK_MODE == 'true'){
            $('#quizContainer_wrap').css('height',(body_height-70));
       }else{
            $('#quizContainer_wrap').css('height',(body_height-125));
       }
       $('#question_wrapper img, .solution_content_wrapper img').css({'max-width':'100%', 'max-height':'100%'});
    if($('#header_wrapper').css('position') == 'fixed'){
        if (ParentView != "DashBoardQuiz") {
            if (($(window).width() < 380)) {
                $('#quiz_wrapper').css({'margin-top': '50px'})
            }
            else {
                $('#quiz_wrapper').css({'margin-top': '52px'})
            }
        }
        else {
            if ($(window).width() < 380) {
                $('#quiz_wrapper').css({'margin-top': '10px'});
            }
            else {
                $('#quiz_wrapper').css({'margin-top': '12px'});
            }
        }
    }
    if(BREAK_MODE == "true"){
        $('#quiz_wrapper').css({'overflow-y': '','overflow-x': ''});
        Platform.setStatusAndNavigationBar(false);
    }
}
;



McqView.prototype.selectAnswer = function(button, option,show_answer) {	//Invoked when user click on any answer options
    var if_chosen = 0;
    var is_checked;
    is_checked = document.getElementById('edit-tries[answer]-' + option);
    if (this.localeQuestion.type == PRACTICE) {
        if ($('#question_wrapper #option' + option).hasClass('correctAnswer') || $('#question_wrapper #option' + option).hasClass('wrongAnswer')) {
            is_checked.checked = false;
        } else {
            is_checked.checked = true;
        }
    } else {
        if ($('#question_wrapper #option' + option).hasClass('selectedOption')) {
            is_checked.checked = false;
        } else {
            is_checked.checked = true;
        }
    }
    this.resetOptions(is_checked);
    if(is_checked.checked==true || is_checked.type=='radio' || show_answer==true){
        this.correct_ids = new Array();
        this.option = option;
        $('#edit-tries[answer]-' + option).attr("checked", true);
        document.getElementById('edit-tries[answer]-' + option).checked = true;
        this.if_chosen = this.question.choice_multi;
        //-----check the user_answer is correct or wrong and check if alternatives correct answer is more then two----//
        this.question_value = this.localeQuestion.alternatives;
        this.getChosen_value(button);
        //------in case of Practice test and if Repeat Until Correct is enabled----------------//
        if (this.quizAttrs.repeat_until_correct != "undefined" && this.quizAttrs.repeat_until_correct == 1) {
            this.repeat_until_correct_error(button);
        }

        //---------in case of hint----------------//
      //  var checkCorrectAnswer = this.checkUserCorrectAnswer(button,false);
        this.checkUserScore(button, false, this.quizAttrs);
   }
   else{
         document.getElementById('edit-tries[answer]-' + option).checked = false;
   }

};


McqView.prototype.repeat_until_correct_error = function(button) {
    var question = this.localeQuestion;
    var hint = "";
    var solution = "";
          if(question.type==PRACTICE){
                this.nid_join = this.correct_ids.join(',');
                $('#flipQuestion_showanswer').hide();
                $('#show_solution_wrapper').hide();
                $('#hint_quiz').remove();

                if (this.nid_join == '') {
                    //geting hint
                    if (typeof question['hints'] !== 'undefined' && question['hints'][0]['value']!=null) {
                        var len = question['hints'].length;
                        var random = getRandomInt(0, len);
                        var hint = question['hints'][random]['value'];
                    }
                    $('#flipQuestion_showanswer').show();
                    if(typeof question['solution'] !== 'undefined'){
                        if(question['solution']['show_solution'] == "true"){
                            solution = question['solution'];
                        }
                    }

                    if(solution!= "" || hint != ""){
                        this.showHelpDialog(hint,solution)
                    }
                }
          }
};

McqView.prototype.showHelpDialog = function(hint,solution) {
    $('#help_dialog').remove();
    $('body').append('<div id="help_dialog" ></div>');
    var  scrollposition = $(window).scrollTop();
    var text = "";

    var width = 0;
    var height = 0; var THIS = this;
    var contentUtils = CONTROLLER.getService("ContentUtils");


    if($(window).width() < 420){
        width = 280;
        height = 220;
    }
    else{
        width = 400;
        height = 400;
    }

    $('#help_dialog').dialog({
        resizable: false,
        modal: true,
        height: 'auto',
        width: width,
        create: function(event, ui) {
            $('body').bind('touchmove', function(e){e.preventDefault()});
        },
        beforeClose: function(event, ui) {
            $('body').unbind('touchmove');
        },
        open: function() {
            //window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        close: function() {
                $(this).dialog("close");
                $('#help_dialog').remove();
                $("#transparent_wrapper").css({
                    "display":"none"
                });
                $('.ui-widget-overlay').removeClass('custom-overlay');
                return false;

            },
        dialogClass: 'dlgfixed helpDialog',
        position: "center",
    });


    text = "<div id='help_dialog_close'><div id='help_dialog_close_img'></div></div>";

    if(hint != ""){
        text = text + '<div id="hint_quiz" class="hintstatusMessage"><div id="quiz_hint">\n\
        </div><div class="quiz_hint_text">Hint: ' + hint + '</div></div>';
    }

    if(solution != ""){
        text = text + '<div id="show_solution_wrapper" >\n\
                      <div id="show_solution"></div><div id=show_solution_text>Click here to learn more</div></div>';
    }

    text = text + "<div class='quiz-help-icon'><img src='images/Help-icon.d8152090.png' class='quiz-help-icon_img'></div>";

    $('.helpDialog').css({'max-height': height+'px'});
    $('#help_dialog').css({'max-height': (height-10)+'px', 'overflow-x':'hidden'});

    $(".ui-widget-overlay").bind("click",function(){
        closeDialog();
    });
    $('#help_dialog').html(text);

    if($(window).width() < $(window).height()){
        $('.quiz-help-icon,.ui-dialog').addClass("portrait");
    }
    else{
        $('.quiz-help-icon,.ui-dialog').addClass("landscape");
    }

    if(solution != ""){
        $("#show_solution_wrapper").unbind("click").bind("click",function(e) {
            $('#help_dialog').dialog('close');
            if(BREAK_MODE !="true"){
                closeDialog();
            }
            if (solution['type'] == 'HTML5') {
                showSolutionofTypeHtml(solution['content']);
                return "";
            }
            var id = solution['clipping_nid'];
            var qView = CONTROLLER.getView("Quiz");
            qView.showSolutionForQuestion(id, solution['type'], solution['duration'], THIS.localeQuestion.localeQuestionId);
            e.stopPropagation();
            return false;
        });
    }
    $(".ui-dialog-titlebar").hide();

    $(".dlgfixed").center(false);

    $('#help_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('.ui-button-text:contains("Re-Download"),.ui-button-text:contains("Delete")')
        .parents('.ui-button').after('<div class="dialog_button_seperator"></div>');
    $('.ui-dialog-buttonset .ui-button').css({
        "width":"32.8%"
    });

    $('html body .ui-dialog').css({'z-index': '99999999',
    background: '#0080C6','color': '#fff','position': 'fixed','outline':'none'});
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
    $('#help_dialog_close').bind('click',function(){
        $('#help_dialog').dialog('close');
        $(".ui-dialog").css("background","transparent");
    });
    $('#hint_quiz').effect( "shake", "", 300, "");

}

McqView.prototype.getChosen_value = function(button) {
    var correct_ids = new Array();
    var option = this.option;
    var isCorrect = false;

    $.each(this.question_value, function(key, value) {
        if (value['score_if_chosen'] == 1) {
            if (value['id'] == option) {
                correct_ids.push(value['id']);
                isCorrect = true;
            }
        }
    });
    this.correct_ids = correct_ids;
};

McqView.prototype.checkUserCorrectAnswer = function (button, tempArray, quizAttrs) {
    var THIS = this;
    var skip = 0, correct = 0, answer = new Array(), wrong_answer = false;
    var option = this.option;
    var user_score = 0; var wrong_answer = 0;
    var simple_score = this.question.choice_boolean;
    var max_score = this.question.max_score;
    var multi_answer = new Array();
    if ((simple_score != 1 || simple_score == "null") && (typeof this.question.partial_positive_score != 'undefined' && this.question.partial_positive_score)) {
        var partial_pos_score = parseInt(this.question.partial_positive_score);
    }

    $.each(this.localeQuestion.alternatives, function (key, value) {
        if (value['score_if_chosen'] == 1) {
            multi_answer.push(value['id']);
        }
        $('tbody tr').each(function () {
            var button_type = $(this).find('input').attr('type');
            var id = $(this).attr('value');
            if (button_type == 'checkbox') {
                var selected = $(this).find('input.form-checkbox').is(':checked');
                if (selected) {
                    if (value['id'] == id && simple_score == 1) {
                        answer.push(value['id']);
                        user_score++;
                        correct = 1;
                    }
                    else if (value['id'] == id && value['score_if_chosen'] == 1) {
                        answer.push(value['id']);
                        correct = 1;
                        user_score++;
                    }
                    if (THIS.localeQuestion.type == PRACTICE) {
                        if (value['id'] == id && value['score_if_chosen'] == 1) {
                            $('#option' + value['id']).addClass('correctAnswer');
                        } else if (value['id'] == id && value['score_if_chosen'] != 1) {
                            $('#option' + value['id']).addClass('wrongAnswer');
                            wrong_answer = true;
                        }
                    } else {
                        if (value['id'] == id && value['score_if_chosen'] == 1) {
                            $('#option' + value['id']).addClass('selectedOption');
                        } else if (value['id'] == id && value['score_if_chosen'] != 1) {
                            $('#option' + value['id']).addClass('selectedOption');
                            wrong_answer = true;
                        }
                    }
                }
            } else {
                var selected = $(this).find('input.form-radio').is(':checked');
                if (selected) {
                    if (value['id'] == id && value['score_if_chosen'] == 1) {
                        correct = 1;
                        answer.push(value['id']);
                        user_score++;
                    }
                    if (THIS.localeQuestion.type == PRACTICE) {
                        if (value['id'] == id && value['score_if_chosen'] == 1) {
                            $('#option' + value['id']).addClass('correctAnswer');
                        } else if (value['id'] == id && value['score_if_chosen'] != 1) {
                            $('#option' + value['id']).addClass('wrongAnswer');
                        }
                    } else {
                        if (value['id'] == id && value['score_if_chosen'] == 1) {
                            $('#option' + value['id']).addClass('selectedOption');
                        } else if (value['id'] == id && value['score_if_chosen'] != 1) {
                            $('#option' + value['id']).addClass('selectedOption');
                        }
                    }
                }
            }
            if (typeof quizAttrs != "undefined") {
                if ((quizAttrs.repeat_until_correct != "undefined" && quizAttrs.repeat_until_correct != 1) && (typeof quizAttrs.mode == "undefined" || quizAttrs.mode != "review")) {
                    $('.quiz_practice .correctAnswer .quiz_tick, .quiz_practice .wrongAnswer .quiz_tick').css('background', 'none');
                }
            }
        });
    });

    if(typeof this.question.choice_multi != 'undefined' && this.question.choice_multi == 1){
         for (i = 0; i < tempArray.length; i++) {
            if (jQuery.inArray(tempArray[i], multi_answer) == -1) {
                wrong_answer = true;
            }
        }
        if(wrong_answer == true || (answer.length != multi_answer.length && tempArray.length != 0 && simple_score == 1)){
            if (tempArray.length != 0 && parseInt(this.question.negative_score) != 0 && (typeof this.question.negative_score != 'undefined' && this.question.negative_score) ) {
                user_score = -(parseInt(this.question.negative_score));
                correct = 0;
            } else {
                user_score = 0;
                correct = 0;
            }
        }
        else if(typeof partial_pos_score != 'undefined' && partial_pos_score > 0 && user_score != 0){
             user_score = (user_score * partial_pos_score);
        }else if(user_score == 0 || tempArray.length == 0){
             user_score = 0;
             correct = 0;
        }
        if(answer.length != multi_answer.length){
              correct = 0;
        }
        if (correct == 1) {
            user_score = max_score;
        }
    }else if(answer.length != multi_answer.length){
        if (tempArray.length != 0 && correct == 0) {
            if (parseInt(this.question.negative_score) != 0 && (typeof this.question.negative_score != 'undefined' && this.question.negative_score)) {
                user_score = -(parseInt(this.question.negative_score));
            }
        }else{
            user_score = 0;
            correct = 0;
        }
    }else if(correct == 1){
        user_score = max_score;
    }
    if (user_score > max_score) {
        user_score = max_score;
    }
    if (this.option == '' || this.option == 'undefined') {
        skip = 1;
    }

    return  {'correct': correct, 'skip': skip, 'answer': answer, 'user_score': user_score};
};

McqView.prototype.checkUserScore = function(button, skip_flag, quizAttrs) {
    var tempArray = new Array();
    var option = this.option;
    var option_json = {};
    var question_result = {};
    var skip = 0, correct = 0, answer = new Array(),param;

    $('tbody tr').each(function() {
        var button_type = $(this).find('input').attr('type');
        if (button_type == 'checkbox') {
            var selected = $(this).find('input.form-checkbox').is(':checked');
            if (selected) {
                tempArray.push($(this).attr('value'));
            }
        } else {
            var selected = $(this).find('input.form-radio').is(':checked');
            if (selected) {
                tempArray.push($(this).attr('value'));
            }
        }
    });

    if (tempArray.length === 0) {
        skip = IS_SKIPPED['skipped'];
    }

    if(skip_flag==true){
        if (tempArray.length === 0)
            skip = IS_SKIPPED['review_skipped'];
        else
            skip = IS_SKIPPED['review_answered'];
    }
    param = this.checkUserCorrectAnswer(button,tempArray,quizAttrs);

    option_json['user_result_answer'] = {
        'nid': this.question.nid,
        'is_correct': param.correct,
        'is_skipped': skip,
        'answer_timestamp': getSyncedServerTime(),
        'answer': param.answer,
        'user_answer': tempArray,
        'points_awarded':param.user_score,
        'questiontype':'mcq',
        'start_time_per_question':this.start_time

    };
    return  option_json;

};





McqView.prototype.showCorrectOptions = function(question_value,mode) {
    var THIS = this;
    var user_checked=new Array();
        try{
            $('tbody tr').each(function() {
            var button_type = $(this).find('input').attr('type');
            if (button_type == 'checkbox') {
                var selected = $(this).find('input.form-checkbox').is(':checked');
                if (selected) {
                   user_checked.push($(this).attr('value'));
                }
            } else {
                var selected = $(this).find('input.form-radio').is(':checked');
                if (selected) {
                    user_checked.push($(this).attr('value'));
                }
            }
        });

        $.each(question_value, function (key, value) {
            if (mode == 'review' && !($('#question_wrapper #option' + value['id']).hasClass('correctAnswer') || $('#question_wrapper #option' + value['id']).hasClass('wrongAnswer'))) {
                $('#option' + value['id']).addClass('missedAnswer');
            }
        });
        $.each(question_value, function (key, value) {
            if (value['score_if_chosen'] == 1) {
                var t = document.getElementById('option' + value['id']);
                THIS.selectAnswer(t, value['id'],true);
                $('#innerQuizContainer').append('<div id="explaination">');
               // $('#explaination').html($('.explaination_div_hidden').html());
                $('.explaination_div_content').html('');
                $('#flipQuestion_showanswer').hide();
                $('#show_solution_wrapper').hide();
                if(mode=='review'){
                      $('#option' + value['id']).addClass('correctAnswer');
                }

                if($.inArray(value['id'], user_checked)  != -1) {
                    var index = user_checked.indexOf(value['id']);
                    user_checked.splice(index, 1);
                }
            }else{
                $('#option' + value['id']).removeClass('wrongAnswer');
                document.getElementById('edit-tries[answer]-' + value['id']).checked = false;
            }
        });

        if (mode == 'review' || this.localeQuestion.type == PRACTICE) {
            for (var i in user_checked) {
                $('#option' + user_checked[i]).addClass('wrongAnswer');
            }
        }

    }catch(e){};

};

McqView.prototype.resetOptions = function(button) {
 //  if(this.question.type==PRACTICE){
        $('#flipQuestion_showanswer').hide();
        $('#show_solution_wrapper').hide();
        $('#hint_quiz').remove();
        if (button.type == 'radio') {
            $('.question_wrapper').removeClass('wrongAnswer');
            $('.question_wrapper').removeClass('correctAnswer');
            $('.question_wrapper').removeClass('selectedOption');
            $('.question_wrapper').addClass('unselected');
            $('#explaination').remove();
        } else{
           if(button.checked==false){
                $('#option' + button.value).removeClass('wrongAnswer');
                $('#option' + button.value).removeClass('correctAnswer');
                $('#option' + button.value).removeClass('selectedOption');
            }
            $('#explaination').remove();
            $('.question_wrapper').addClass('unselected');
        }
  //  }
};



//got to change
McqView.prototype.setPreviousData = function (question, passrate, quizAttrs) {
    var THIS = this;
    var nids = new Array();
    if (typeof this.question['question_result'] !== 'undefined') {
        nids = this.question['question_result']['user_answer'];
    }
    //-----------------set previous data--------------------------//
    try {
        for (var i = 0; i < nids.length; i++) {
            document.getElementById('edit-tries[answer]-' + nids[i]).checked = true;
            $('#option' + nids[i]).addClass('selectedOption');
            if (this.localeQuestion.type == PRACTICE) {
                var question_value = this.localeQuestion.alternatives;
                $.each(question_value, function (key, value) {
                    if (CHECK_ON_PASS_RATE != undefined && CHECK_ON_PASS_RATE == 'true' && passrate != undefined && passrate == 0) {
                        if ((value['id'] == nids[i] && value['score_if_chosen'] == 1) || (value['id'] == nids[i] && value['score_if_chosen'] != 1)) {
                            $('#option' + value['id']).addClass('correctAnswer');
                        }
                    }
                    else {
                        if (value['id'] == nids[i] && value['score_if_chosen'] == 1) {
                            $('#option' + value['id']).addClass('correctAnswer');
                        } else if (value['id'] == nids[i] && value['score_if_chosen'] != 1) {
                            $('#option' + value['id']).addClass('wrongAnswer');
                            if ((typeof quizAttrs != 'undefined' && quizAttrs.repeat_until_correct != "undefined" && quizAttrs.repeat_until_correct == 1)){
                                $('#flipQuestion_showanswer').show();
                            }
                        }
                    }
                    if (typeof quizAttrs != "undefined") {
                        if ((quizAttrs.repeat_until_correct != "undefined" && quizAttrs.repeat_until_correct != 1) && (typeof quizAttrs.mode == "undefined" || quizAttrs.mode != "review")) {
                            $('.quiz_practice .correctAnswer .quiz_tick, .quiz_practice .wrongAnswer .quiz_tick').css('background', 'none');
                        }
                    }

                });
            }
        }
    } catch (e) {
    }
};

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

McqView.prototype.multichoiceBlankTypeQuestions = function(title, columns, questions) {
    var THIS = this;
    var question_options = {};
    var k = 0;
    question_options['option_json'] = [];
     if(!title){
        title = "Blank";
    }
    if(columns && questions){
    var options_length =  questions['quiz'].alternatives.length;
    var options = questions['quiz'].alternatives;
    var show_data = options_length/columns;
    var show_col_data = show_data;
    var count = 0;
    for (i in options) {
        for (var j = 0; j < columns; j++) {
            count++;
            question_options['option_json'][j] = [];
            question_options['option_json'][j]['option_data'] = [];
            question_options['option_json'][j].option_header = title + count;
            for (k; k < show_data; k++) {
                 question_options['option_json'][j]['option_data'].push(options[k]);
            }
            if (k == show_data && count != columns) {
                k = show_data;
                show_data = show_data + show_col_data;
            }
        }
        break;
    }
    questions['quiz'].option_json = question_options['option_json'];
    questions['quiz'].blanck_type_qsn = "true";
    }
    return questions;
}
function multichoiceStack(name,col){

}

ShortAnswerView.prototype = new View();
ShortAnswerView.prototype.constructor = ShortAnswerView.constructor;
function ShortAnswerView() {
    this.question = '';
    this.localeQuestion = '';
}
;



ShortAnswerView.prototype.renderContent = function(params) {
    var THIS = this;
    this.question = params.quiz;
    this.start_time=params.start_question;
    this.question['questiontype'] = "short_answer";
    this.quizAttrs = params.quizAttrs;
    this.activeQuizLanguage = params.activeQuizLanguage;

    var correct_answer=params.quiz.correct_answer;
    var correct_answer_evaluation=params.quiz.correct_answer_evaluation;
    try {
        var questionContent = params.quiz;
        var updatedLocale = params.quiz.updatedLocale;
        if (typeof updatedLocale != 'undefined' && Object.keys(updatedLocale).length > 1) {
            if (typeof params.activeQuizLanguage != 'undefined' && params.activeQuizLanguage != '') {
                questionContent = params.quiz.updatedLocale[params.activeQuizLanguage];
            }
            questionContent.localeInfo = params.localeInfo;
        }
        questionContent['count'] = params.index;
        questionContent['total'] = params.total_questions;
        questionContent['type'] = params.type;
        this.localeQuestion = questionContent;

        if (typeof this.localeQuestion.localeQuestionId == 'undefined') {
            this.localeQuestion.localeQuestionId = this.question.nid;
        }
        if(typeof this.question.question_result != 'undefined')
            this.localeQuestion.question_result = this.question.question_result;
    } catch (e) {
         console.log("function :- renderContent " + e.message);
    }
    ;
    if(this.question.correct_answer_evaluation == 3) {
        //manual evaluation
        EVALUATION_MODE = "manual";
    }
    if( params.mode=='review'){
        if (this.question.correct_answer_evaluation == 5 && typeof this.localeQuestion.question_result != 'undefined') {
            this.localeQuestion.user_answer_pos = this.question.answer_list.indexOf(this.localeQuestion.question_result['user_answer']);
        }
        this.renderTemplate('short_answer_summary.tl', this.localeQuestion, params.replace_item, params.replace);
          $('.solution_content_wrapper').removeClass('hidden');
          if (typeof this.localeQuestion['solution'] != 'undefined') {
            if (this.localeQuestion['solution']['type'] == 'HTML5' && typeof this.localeQuestion['solution']['content'] == 'undefined') {
                $('.solution_content_wrapper').addClass('hidden');
            }
            if(this.localeQuestion['solution']['type'] == 'Video' || this.localeQuestion['solution']['type'] == 'Document'){
                $(".solution_text_wrapper").removeClass("hidden");
            }
        }
        $('.solution_text_wrapper').click(function (e) {
            var id = $(this).attr('id');
            var type = $(this).attr('type');
            var qView = CONTROLLER.getView("Quiz");
            qView.showSolutionForQuestion(id, type, THIS.localeQuestion['solution']['duration'], THIS.localeQuestion.localeQuestionId);
            return false;
        });
    }else{
        this.renderTemplate('short_question.tl',this.localeQuestion, params.replace_item, params.replace);
    }
    var context = CONTROLLER.getVariable('contextView');
    if(typeof context !='undefined' && context == 'TestSeries'){
        $('#quiz_wrapper').addClass('testseries_quiz_wrapper');
    }
    else if(typeof context !='undefined' && context == 'course'){
        $('#quiz_wrapper').addClass('course_quiz_wrapper');
        $('#content_wrapper').addClass('content_wrapper_height');
    }
    if (this.question.correct_answer_evaluation == 4) {
    // type range
    for(var i=parseInt(params.quiz.answer_range_start);i<=parseInt(params.quiz.answer_range_end);i++){
        if( parseInt(params.quiz.answer_range_end) < 10 ){
             $("#edit-tries").append($("<option></option>").val(i).html(i));

        }else{
            if(i >= 10){
                $("#edit-tries").append($("<option></option>").val(i).html(i));
            }else{
                $("#edit-tries").append($("<option></option>").val("0"+i).html("0"+i));
            }
        }
    }
    }

    if(this.question.correct_answer_evaluation == 6){
//       Automatic and range as radio
            if (parseInt(params.quiz.answer_range_end) < 10) {
                for (var i =0; i <= 9 ; i++) {
                $("#edit-tries").append('<div class="short-radio" ><input class="short-radio-input" type="radio" name="radioanswer" value=' + i + ' >'+'<label class="short-radio-label" for=edit-tries-'+ i + '>'+i+'</label></div>');
            }
            } else {
                for (var i = 0; i <= 9; i++) {
                      $("#edit-tries").append('<div class="short-radio" ><input class="short-radio-input" name="radioanswer" type="radio" value=' + i + ' >' + '<label class="short-radio-label" for=edit-tries-' + i + '>' + i + '</label></div>');
                }

                for (var i = 0; i <=9; i++) {
                    $("#edit-tries1").append('<div class="row_sd"></div>');
                    $("#edit-tries1").append('<div class="short-radio" ><input  class="short-radio-input" name="radioanswer1" type="radio" value=' + i + ' >' + '<label class="short-radio-label" for=edit-tries-' + i + '>' + i + '</label></div>');
                }
            }

    }
    if((correct_answer_evaluation == 7 || correct_answer_evaluation == 8) && params.mode != "review"){
        $("#edit-tries").attr("onfocus","blur()");
        $("#edit-tries").initKeypad();
    }
    if(params.mode == "review"){
        $('.answer_here, #edit-clear-fieldset').addClass('hidden');
        $('#edit-tries').css({'background': 'none', 'border': 'none'});
        if($(window).width() > 360){
             $('#edit-tries').css({'padding-left': '0'});
        }
    }
    if(typeof params["quizAttrs"]["sections"] != "undefined" && !$.isEmptyObject(params["quizAttrs"]["sections"])){
        var sectionFound = false;
        for(var i in params["quizAttrs"]["sections"]){
            for(var j in params["quizAttrs"]["sections"][i]["questions"]){
                if (params["quizAttrs"]["sections"][i]["questions"][j] == this.localeQuestion.localeQuestionId) {
                    sectionFound = true;
                    var body = params["quizAttrs"]["sections"][i]["body"];
                    $("#questionTitle").prepend("<fieldset><legend>"+params["quizAttrs"]["sections"][i]["title"]+"</legend><div id='section_text_wrapper'>"+body+"</div></div></fieldset>");
                    $('#section_text_wrapper').readmore({
                        speed: 100,
                        lessLink: '<a href="#">Read Less</a>'
                    });
                    break;
                }
            }
        }
        if (sectionFound == false && typeof params["quizAttrs"]["updatedLocale"] != 'undefined') {
            updateLocaleSections = params["quizAttrs"]["updatedLocale"]['quiz_section'];
            for (var i in updateLocaleSections) {
                if (params.activeQuizLanguage == i) {
                    for (var j in updateLocaleSections[i]) {
                        for (var k in updateLocaleSections[i][j]["questions"]) {
                            if (updateLocaleSections[i][j]["questions"][k] == this.localeQuestion.localeQuestionId) {
                                var body = updateLocaleSections[i][j]["body"];
                                $("#questionTitle").prepend("<fieldset><legend>" + updateLocaleSections[i][j]["title"] + "</legend><div id='section_text_wrapper'>" + body + "</div></div></fieldset>");
                                $('#section_text_wrapper').readmore({
                                    speed: 100,
                                    lessLink: '<a href="#">Read Less</a>'
                                });
                                break;
    }
                        }
                    }
                }
            }
        }
    }
    //for selection
    this.setbodystyle(params);

    $('#edit-clear-fieldset').click(
    function(){
        $("#edit-tries").val('');
    });
     if (typeof ENABLE_QUIZ_CLEAR_RESPONSE != "undefined" && ENABLE_QUIZ_CLEAR_RESPONSE == "true") {
        $('#edit-clear-fieldset').addClass('hidden');
    }


};

ShortAnswerView.prototype.setbodystyle = function()
{
     var ParentView = CONTROLLER.getVariable("activeParentView");
       $('#flipQuestion_showanswer').hide();
       $('#show_solution_wrapper').hide();
       var body_height=$('body').height();
       $('#quizContainer_wrap').css('height',(body_height-175));
       $('#question_wrapper img, .solution_content_wrapper img').css({'max-width':'100%', 'max-height':'100%'});
    if($('#header_wrapper').css('position') == 'fixed'){
        if (ParentView != "DashBoardQuiz") {
            if (($(window).width() < 380)) {
                $('#quiz_wrapper').css({'margin-top': '50px'})
            }
            else {
                $('#quiz_wrapper').css({'margin-top': '52px'})
            }
        }
        else {
            if ($(window).width() < 380) {
                $('#quiz_wrapper').css({'margin-top': '10px'});
            }
            else {
                $('#quiz_wrapper').css({'margin-top': '12px'});
            }
        }
    }

}
;

ShortAnswerView.prototype.repeat_until_correct_error = function(button) {
    var question = this.localeQuestion;
    var hint = "";
    var solution = "";
    var answer=this.localeQuestion.correct_answer;
    var userinput=document.getElementById('edit-tries').value;
          if(question.type==PRACTICE){
                $('#flipQuestion_showanswer').hide();
                $('#show_solution_wrapper').hide();
                alert('Please enter correct answer');
                $('#hint_quiz').remove();

                    //geting hint
                    if (typeof question['hints'] !== 'undefined' && question['hints'][0]['value']!=null) {
                        var len = question['hints'].length;
                        var random = getRandomInt(0, len);
                        var hint = question['hints'][random]['value'];
                    }
                    if(typeof question['solution'] !== 'undefined'){
                        if(question['solution']['show_solution'] == "true"){
                            solution = question['solution'];
                        }
                    }

                    if(solution!= "" || hint != ""){
                        this.showHelpDialog(hint,solution)
                    }
                    $('#flipQuestion_showanswer').show();
          }
};

ShortAnswerView.prototype.showHelpDialog = function(hint,solution) {
    $('#help_dialog').remove();
    $('body').append('<div id="help_dialog" ></div>');
    var  scrollposition = $(window).scrollTop();
    var text = "";

    var width = 0;
    var height = 0;
    var contentUtils = CONTROLLER.getService("ContentUtils");


    if($(window).width() < 420){
        width = 280;
        height = 220;
    }
    else{
        width = 400;
        height = 400;
    }

    $('#help_dialog').dialog({
        resizable: false,
        modal: true,
        height: 'auto',
        width: width,
        create: function(event, ui) {
            $('body').bind('touchmove', function(e){e.preventDefault()});
        },
        beforeClose: function(event, ui) {
            $('body').unbind('touchmove');
        },
        open: function() {
            //window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        close: function() {
                $(this).dialog("close");
                $('#help_dialog').remove();
                $("#transparent_wrapper").css({
                    "display":"none"
                });
                $('.ui-widget-overlay').removeClass('custom-overlay');
                return false;

            },
        dialogClass: 'dlgfixed helpDialog',
        position: "center",
    });


    text = "<div id='help_dialog_close'><div id='help_dialog_close_img'></div></div>";

    if(hint != ""){
        text = text + '<div id="hint_quiz" class="hintstatusMessage"><div id="quiz_hint">\n\
        </div><div class="quiz_hint_text">Hint: ' + hint + '</div></div>';
    }

    if(solution != ""){
        text = text + '<div id="show_solution_wrapper" >\n\
                      <div id="show_solution"></div><div id=show_solution_text>Click here to learn more</div></div>';
    }

    text = text + "<div class='quiz-help-icon'><img src='images/Help-icon.d8152090.png' class='quiz-help-icon_img'></div>";

    $('.helpDialog').css({'max-height': height+'px'});
    $('#help_dialog').css({'max-height': (height-10)+'px', 'overflow-x':'hidden'});

    $(".ui-widget-overlay").bind("click",function(){
        closeDialog();
    });
    $('#help_dialog').html(text);

    if($(window).width() < $(window).height()){
        $('.quiz-help-icon,.ui-dialog').addClass("portrait");
    }
    else{
        $('.quiz-help-icon,.ui-dialog').addClass("landscape");
    }

    if(solution != ""){
        $("#show_solution_wrapper").unbind("click").bind("click",function() {
            $('#help_dialog').dialog('close');
            closeDialog();
            if (solution['type'] == 'HTML5') {
                showSolutionofTypeHtml(solution['content']);
                return "";
            }
            var id = solution['clipping_nid'];
            var qView = CONTROLLER.getView("Quiz");
            qView.showSolutionForQuestion(id, solution['type'], solution['duration'], THIS.localeQuestion.localeQuestionId);
            e.stopPropagation();
            return false;
        });
    }
    $(".ui-dialog-titlebar").hide();

    $(".dlgfixed").center(false);

    $('#help_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('.ui-button-text:contains("Re-Download"),.ui-button-text:contains("Delete")')
        .parents('.ui-button').after('<div class="dialog_button_seperator"></div>');
    $('.ui-dialog-buttonset .ui-button').css({
        "width":"32.8%"
    });

    $('html body .ui-dialog').css({'z-index': '99999999',
    background: '#0080C6','color': '#fff','position': 'fixed','outline':'none'});
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
    $('#help_dialog_close').bind('click',function(){
        $('#help_dialog').dialog('close');
    });
        $('#hint_quiz').effect( "shake", "", 300, "");

}

ShortAnswerView.prototype.checkUserCorrectAnswer = function(button) {
    var skip = 0, correct = 0, answer = new Array(), wrong_answer = false;
    var option = this.option;
    var question = this.localeQuestion;
    answer = this.question.correct_answer;
    var userinput = document.getElementById('edit-tries').value;
    var user_score = 0;
    var max_score = this.question.max_score;
    if (this.question.correct_answer_evaluation == 3) {
        //           manual
        EVALUATION_MODE = "manual";
        if (userinput == " ") {
            skip = 1;
        }
    }
    else if (this.question.correct_answer_evaluation == 4 || this.question.correct_answer_evaluation == 5) {
        if (this.question.correct_answer_evaluation == 5) {
            var user_answer_id = $('#edit-tries').find('option:selected').attr('id');
            userinput = this.question.answer_list[user_answer_id];
        }
        if (answer == userinput) {
            correct = 1;
            user_score = max_score;
        }
        else {
            wrong_answer = true;
        }
    }
    else if (this.question.correct_answer_evaluation == 6) {
        if (parseInt(this.question.answer_range_end) < 10) {
            userinput = $('input[name=radioanswer]:checked').val();
            if (answer == userinput) {
                correct = 1;
                user_score = max_score;
            }
            else if (typeof userinput != 'undefined') {
                wrong_answer = true;
            }
        } else {
            var userinput1 = $('input[name=radioanswer]:checked').val();
            var userinput2 = $('input[name=radioanswer1]:checked').val();
            if (typeof userinput1 == 'undefined' && typeof userinput2 == 'undefined') {
                userinput = '';
            } else if (typeof userinput1 != 'undefined' && typeof userinput2 == 'undefined') {
                userinput = userinput1;
            } else if (typeof userinput1 == 'undefined' && typeof userinput2 != 'undefined') {
                userinput = userinput2;
            }
            else if (typeof userinput1 != 'undefined' && typeof userinput2 != 'undefined') {
                var userinput = userinput1 + userinput2;
            }
            if (answer == userinput) {
                correct = 1;
                user_score = max_score;
            }
            else if (typeof userinput != 'undefined') {
                wrong_answer = true;
            }
        }
    }
    else if (this.question.correct_answer_evaluation == 0) {
        //      automatic   check for case sensitive

        var n = userinput.localeCompare(this.localeQuestion.correct_answer);
        if (n == 0) {
            correct = 1;
            user_score = max_score;
        }
        else {
            wrong_answer = true;
        }
    }
    else if (this.question.correct_answer_evaluation == 7) {
        if (typeof userinput != 'undefined' && userinput != '' && (Math.round(userinput * 100) / 100) == this.localeQuestion.correct_answer) {
            correct = 1;
            user_score = max_score;
        }
        else {
            wrong_answer = true;
        }
    } else if (this.question.correct_answer_evaluation == 8) {
        if (userinput >= parseFloat(this.localeQuestion.min_characters) && userinput <= parseFloat(this.localeQuestion.max_characters)) {
            correct = 1;
            user_score = max_score;
        } else {
            wrong_answer = true;
        }
    }
    else {
        //      automatic  non case sensitive
        if (typeof userinput != 'undefined' && userinput.toLowerCase() == this.localeQuestion.correct_answer.toLowerCase()) {
            correct = 1;
            user_score = max_score;
        }
        else {
            wrong_answer = true;
        }
    }

    if (userinput == '') {
        skip = 1;
    }

    if (wrong_answer == true) {
        correct = 0;
        if (parseInt(this.question.negative_score) != 0 && skip != 1 && (typeof this.question.negative_score != 'undefined' && this.question.negative_score)) {
            user_score = -(parseInt(this.question.negative_score));
        }
    }
    return { 'correct': correct, 'skip': skip, 'answer': answer, 'user_score': user_score };
};

ShortAnswerView.prototype.checkUserScore = function(button, skip_flag) {
    var tempArray = new Array();
    var option = this.option;
    var option_json = {};
    var question = this.localeQuestion;
    var question_result = {};
    var user_answer_id = '';
    var skip = 0, correct = 0, answer = new Array(),param;
    var userinput=document.getElementById('edit-tries').value;
    if (userinput != '' && this.question.correct_answer_evaluation == 5) {
        user_answer_id = $('#edit-tries').find('option:selected').attr('id');
        userinput = this.question.answer_list[user_answer_id];
    }
    if (this.question.correct_answer_evaluation == 6) {
        if (parseInt(this.question.answer_range_end) < 10) {
            userinput = $('input[name=radioanswer]:checked').val();
            if (typeof userinput == 'undefined') {
                userinput = '';
            }
        } else {
            var userinput1 = $('input[name=radioanswer]:checked').val();
            var userinput2 = $('input[name=radioanswer1]:checked').val();
            if (typeof userinput1 == 'undefined' && typeof userinput2 == 'undefined') {
                userinput = '';
            } else if (typeof userinput1 != 'undefined' && typeof userinput2 == 'undefined') {
                userinput = userinput1;
            } else if (typeof userinput1 == 'undefined' && typeof userinput2 != 'undefined') {
                userinput = userinput2;
            }
            else if (typeof userinput1 != 'undefined' && typeof userinput2 != 'undefined') {
                var userinput = userinput1 + userinput2;
            }
        }
    } else if (this.question.correct_answer_evaluation == 7) {
        if (userinput != '') {
            userinput = (Math.round(userinput * 100) / 100).toString();
        }
    }
    var char_length=$.trim($("#edit-tries").val()).length;
    if (userinput === '') {
        skip = IS_SKIPPED['skipped'];
    }
    if(skip_flag==true){
        if (userinput === '')
            skip = IS_SKIPPED['review_skipped'];
        else
            skip = IS_SKIPPED['review_answered'];
    }
    param = this.checkUserCorrectAnswer(button);

    option_json['user_result_answer'] = {
        'nid': this.question.nid,
        'is_correct': param.correct,
        'is_skipped': skip,
        'answer_timestamp': getSyncedServerTime(),
        'answer': param.answer,
        'user_answer':userinput,
        'points_awarded':param.user_score,
        'questiontype':this.question['questiontype'],
        'start_time_per_question':this.start_time,
        'answer_charlength':char_length,
        'user_answer_id': user_answer_id
    };
    return  option_json;

};

ShortAnswerView.prototype.showCorrectOptions = function (question_value, mode) {
    var THIS = this;
    var answer = this.localeQuestion.correct_answer;
    try {
        if (this.question.correct_answer_evaluation == 6) {
//             Automatic and range as radio
            if (answer.length < 2 && parseInt(answer) < 10) {
                for (var i = 0; i <= 9; i++) {
                    $('input[name="' + 'radioanswer' + '"][value="' + answer + '"]').prop('checked', true);
                }
            } else {
                var val1 = Math.floor(answer / 10);
                var val2 = answer % 10;
                    $('input[name="' + 'radioanswer' + '"][value="' + val1 + '"]').prop('checked', true);
                    $('input[name="' + 'radioanswer1' + '"][value="' + val2 + '"]').prop('checked', true);
            }

        } else if (this.question.correct_answer_evaluation == 8) {
            $('#edit-tries').val(this.localeQuestion.min_characters);
        } else {
            $('#edit-tries').val(answer);

        }
        $('#flipQuestion_showanswer').hide();
        $('#show_solution_wrapper').hide();
        $('#hint_quiz').remove();

    } catch (e) {
         console.log("function :- showCorrectOptions " + e.message);
    }
    ;

};

//got to change
ShortAnswerView.prototype.setPreviousData = function(question, passrate) {
    var THIS = this;
    var nids = new Array();
    if(typeof this.question['question_result']!=='undefined'){
         nids = this.question['question_result']['user_answer'];
    }
   //-----------------set previous data--------------------------//
    try {
        if (typeof nids == 'undefined') {
            return;
        }
        for (var i = 0; i < nids.length; i++) {
            if (this.question.correct_answer_evaluation == 6) {
//             Automatic and range as radio
                if (nids.length < 2 && parseInt(nids) < 10) {
                    for (var i = 0; i <= 9; i++) {
                        $('input[name="' + 'radioanswer' + '"][value="' + nids + '"]').prop('checked', true);
                    }
                } else {
                    var val1 = Math.floor(nids / 10);
                    var val2 = nids % 10;
                        $('input[name="' + 'radioanswer' + '"][value="' + val1 + '"]').prop('checked', true);
                        $('input[name="' + 'radioanswer1' + '"][value="' + val2 + '"]').prop('checked', true);
                }

            } else if (this.question.correct_answer_evaluation == 5) {
                if (typeof this.question.updatedLocale != 'undefiend') {
                    for (var j in this.question.updatedLocale) {
                        var locale = this.question.updatedLocale[j];
                        var index = locale.answer_list.indexOf(nids);
                        if (index > -1) {
                            var answer = this.localeQuestion.answer_list[index];
                            $('#edit-tries option[value=' + answer + ']').prop('selected', 'true');
                            break;
                        }
                    }
            } else {
                $('#edit-tries').val(nids);
            }
            } else {
                $('#edit-tries').val(nids);
            }
            if (this.localeQuestion.type == PRACTICE) {
                var question_value = this.localeQuestion.alternatives;
                if (typeof question_value != 'undefined') {
                    $.each(question_value, function (key, value) {
                        if (CHECK_ON_PASS_RATE != undefined && CHECK_ON_PASS_RATE == 'true' && passrate != undefined && passrate == 0) {
                            if ((value['id'] == nids[i] && value['score_if_chosen'] == 1) || (value['id'] == nids[i] && value['score_if_chosen'] != 1)) {
                                $('#option' + value['id']).addClass('correctAnswer');
                            }
                        }
                        else {
                            if (value['id'] == nids[i] && value['score_if_chosen'] == 1) {
                                $('#option' + value['id']).addClass('correctAnswer');
                            } else if (value['id'] == nids[i] && value['score_if_chosen'] != 1) {
                                $('#option' + value['id']).addClass('wrongAnswer');
                            }
                        }
                    });
                }
            }
        }
    } catch (e) {
        console.log("function :- setPreviousData " + e.message);
    }
    ;

};
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}










TruefalseView.prototype = new View();
TruefalseView.prototype.constructor = TruefalseView.constructor;
function TruefalseView() {
    this.question = '';
    this.localeQuestion = '';
}
;
TruefalseView.prototype.renderContent = function (params) {
    var THIS = this;
    this.question = params.quiz;
    this.start_time = params.start_question;
    this.quizAttrs = params.quizAttrs;
    try {
        var questionContent = params.quiz;
        var updatedLocale = params.quiz.updatedLocale;
        if (typeof updatedLocale != 'undefined' && Object.keys(updatedLocale).length > 1) {
            if (typeof params.activeQuizLanguage != 'undefined' && params.activeQuizLanguage != '') {
                questionContent = params.quiz.updatedLocale[params.activeQuizLanguage];
            }
            questionContent.localeInfo = params.localeInfo;
        }
        questionContent['count'] = params.index;
        questionContent['total'] = params.total_questions;
        questionContent['type'] = params.type;
        this.localeQuestion = questionContent;
        if (typeof this.localeQuestion.localeQuestionId == 'undefined') {
            this.localeQuestion.localeQuestionId = this.question.nid;
        }
        if(typeof this.question.question_result != 'undefined')
            this.localeQuestion.question_result = this.question.question_result;
    } catch (e) {
    }
    ;
    this.renderTemplate('truefalse_question.tl', this.localeQuestion, params.replace_item, params.replace);
    var context = CONTROLLER.getVariable('contextView');
    if(typeof context !='undefined' && context == 'TestSeries'){
        $('#quiz_wrapper').addClass('testseries_quiz_wrapper');
    }
    else if(typeof context !='undefined' && context == 'course'){
        $('#quiz_wrapper').addClass('course_quiz_wrapper');
        $('#content_wrapper').addClass('content_wrapper_height');
    }
    if (typeof params["quizAttrs"]["sections"] != "undefined" && !$.isEmptyObject(params["quizAttrs"]["sections"])) {
        var sectionFound = false;
        for(var i in params["quizAttrs"]["sections"]){
            for(var j in params["quizAttrs"]["sections"][i]["questions"]){
                if (params["quizAttrs"]["sections"][i]["questions"][j] == this.localeQuestion.localeQuestionId) {
                    sectionFound = true;
                    var body = params["quizAttrs"]["sections"][i]["body"];
                    $("#questionTitle").prepend("<fieldset><legend>"+params["quizAttrs"]["sections"][i]["title"]+"</legend><div id='section_text_wrapper'>"+body+"</div></div></fieldset>");
                    $('#section_text_wrapper').readmore({
                        speed: 100,
                        lessLink: '<a href="#">Read Less</a>'
                    });
                    break;
                }
            }
        }
        if (sectionFound == false && typeof params["quizAttrs"]["updatedLocale"] != 'undefined') {
            updateLocaleSections = params["quizAttrs"]["updatedLocale"]['quiz_section'];
            for (var i in updateLocaleSections) {
                if (params.activeQuizLanguage == i) {
                    for (var j in updateLocaleSections[i]) {
                        for (var k in updateLocaleSections[i][j]["questions"]) {
                            if (updateLocaleSections[i][j]["questions"][k] == this.localeQuestion.localeQuestionId) {
                                var body = updateLocaleSections[i][j]["body"];
                                $("#questionTitle").prepend("<fieldset><legend>" + updateLocaleSections[i][j]["title"] + "</legend><div id='section_text_wrapper'>" + body + "</div></div></fieldset>");
                                $('#section_text_wrapper').readmore({
                                    speed: 100,
                                    lessLink: '<a href="#">Read Less</a>'
                                });
                                break;
                            }
                        }
                    }
                }
            }
        }
    }
    //for selection
    this.setbodystyle(params);
    if (params.mode == 'review') {
         $('input[name="tries[answer]"]').attr('disabled', 'disabled');
         $('.solution_content_wrapper').removeClass('hidden');
       if (typeof this.localeQuestion['solution'] != 'undefined') {
            if (this.localeQuestion['solution']['type'] == 'HTML5' && typeof this.localeQuestion['solution']['content'] == 'undefined') {
                $('.solution_content_wrapper').addClass('hidden');
            }
            if(this.localeQuestion['solution']['type'] == 'Video' || this.localeQuestion['solution']['type'] == 'Document'){
                $(".solution_text_wrapper").removeClass("hidden");
            }
        }
        $('.solution_text_wrapper').click(function (e) {
            var id = $(this).attr('id');
            var type = $(this).attr('type');
            var qView = CONTROLLER.getView("Quiz");
            qView.showSolutionForQuestion(id, type, THIS.localeQuestion['solution']['duration'], THIS.localeQuestion.localeQuestionId);
            return false;
        });
    } else {
        $('.question_wrapper').click(function (e) {
            var id = $(this).attr('qid');
            THIS.selectAnswer(this, id, false);
           e.stopImmediatePropagation();
        });
    }


};

TruefalseView.prototype.setbodystyle = function ()
{
    var ParentView = CONTROLLER.getVariable("activeParentView");
    $('#flipQuestion_showanswer').hide();
    $('#show_solution_wrapper').hide();
    var body_height = $('body').height();
    $('#quizContainer_wrap').css('height', (body_height - 175));
    $('#question_wrapper img, .solution_content_wrapper img').css({'max-width': '100%', 'max-height': '100%'});
    if ($('#header_wrapper').css('position') == 'fixed') {
        if (ParentView != "DashBoardQuiz") {
            if (($(window).width() < 380)) {
                $('#quiz_wrapper').css({'margin-top': '50px'})
            }
            else {
                $('#quiz_wrapper').css({'margin-top': '52px'})
            }
        }
        else {
            if ($(window).width() < 380) {
                $('#quiz_wrapper').css({'margin-top': '10px'});
            }
            else {
                $('#quiz_wrapper').css({'margin-top': '12px'});
            }
        }
    }

}
;
TruefalseView.prototype.selectAnswer = function(button, option, show_answer) {	//Invoked when user click on any answer options
    var if_chosen = 0;
    var is_checked;
    is_checked = document.getElementById('edit-tries[answer]-' + option);
    this.resetOptions(is_checked);
    if (is_checked.checked == false || is_checked.type == 'radio' || show_answer == true) {
        this.correct_ids = new Array();
        this.option = option;
        $('#edit-tries[answer]-' + option).attr("checked", true);
        document.getElementById('edit-tries[answer]-' + option).checked = true;
        this.if_chosen = this.question.choice_multi;
        //-----check the user_answer is correct or wrong and check if alternatives correct answer is more then two----//
        this.question_value = this.question;
        this.getChosen_value(button);
        //------in case of Practice test and if Repeat Until Correct is enabled----------------//
        if (this.quizAttrs.repeat_until_correct != "undefined" && this.quizAttrs.repeat_until_correct == 1) {
        this.repeat_until_correct_error(button);
        }
        //---------in case of hint----------------//
        //  var checkCorrectAnswer = this.checkUserCorrectAnswer(button,false);
        this.checkUserScore(button, false, this.quizAttrs);
    }
    else {
        document.getElementById('edit-tries[answer]-' + option).checked = false;
    }

};
TruefalseView.prototype.repeat_until_correct_error = function (button) {
    var question = this.localeQuestion;
    var hint = "";
    var solution = "";
    if (question.type == PRACTICE) {
        this.nid_join = this.correct_ids.join(',');
        $('#flipQuestion_showanswer').hide();
        $('#show_solution_wrapper').hide();
        $('#hint_quiz').remove();
        if (this.nid_join == '') {
            //geting hint
            if (typeof question['hints'] != 'undefined' && question['hints'] != null && question['hints'][0]['value'] != null) {
                var len = question['hints'].length;
                var random = getRandomInt(0, len);
                var hint = question['hints'][random]['value'];
            }
            $('#flipQuestion_showanswer').show();
            if (typeof question['solution'] !== 'undefined') {
                if (question['solution']['show_solution'] == "true") {
                    solution = question['solution'];
                }
            }

            if (solution != "" || hint != "") {
                this.showHelpDialog(hint, solution)
            }
        }
    }
};
TruefalseView.prototype.showHelpDialog = function (hint, solution) {
    $('#help_dialog').remove();
    $('body').append('<div id="help_dialog" ></div>');
    var scrollposition = $(window).scrollTop();
    var text = "";
    var width = 0;
    var height = 0;
    var contentUtils = CONTROLLER.getService("ContentUtils");

    if($(window).width() < 420){
        width = 280;
        height = 220;
    }
    else{
        width = 400;
        height = 400;
    }

    $('#help_dialog').dialog({
        resizable: false,
        modal: true,
        height: 'auto',
        width: width,
        create: function (event, ui) {
            $('body').bind('touchmove', function (e) {
                e.preventDefault()
            });
        },
        beforeClose: function (event, ui) {
            $('body').unbind('touchmove');
        },
        open: function () {
            //window.scrollTo(0, scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        close: function () {
            $(this).dialog("close");
            $('#help_dialog').remove();
            $("#transparent_wrapper").css({
                "display": "none"
            });
            $('.ui-widget-overlay').removeClass('custom-overlay');
            return false;
        },
        dialogClass: 'dlgfixed helpDialog',
        position: "center",
    });

    text = "<div id='help_dialog_close'><div id='help_dialog_close_img'></div></div>";

    if(hint != ""){
        text = text + '<div id="hint_quiz" class="hintstatusMessage"><div id="quiz_hint">\n\
        </div><div class="quiz_hint_text">Hint: ' + hint + '</div></div>';
    }

    if(solution != ""){
        text = text + '<div id="show_solution_wrapper" >\n\
                      <div id="show_solution"></div><div id=show_solution_text>Click here to learn more</div></div>';
    }

    text = text + "<div class='quiz-help-icon'><img src='images/Help-icon.d8152090.png' class='quiz-help-icon_img'></div>";

    $('.helpDialog').css({'max-height': height+'px'});
    $('#help_dialog').css({'max-height': (height-10)+'px', 'overflow-x':'hidden'});

    $(".ui-widget-overlay").bind("click", function () {
        closeDialog();
    });
    $('#help_dialog').html(text);
    if ($(window).width() < $(window).height()) {
        $('.quiz-help-icon,.ui-dialog').addClass("portrait");
    }
    else {
        $('.quiz-help-icon,.ui-dialog').addClass("landscape");
    }

    if (solution != "") {
        $("#show_solution_wrapper").unbind("click").bind("click", function () {
            $('#help_dialog').dialog('close');
            closeDialog();
            if (solution['type'] == 'HTML5') {
                showSolutionofTypeHtml(solution['content']);
                return "";
            }
            var id = solution['clipping_nid'];
            var qView = CONTROLLER.getView("Quiz");
            qView.showSolutionForQuestion(id, solution['type'], solution['duration'], THIS.localeQuestion.localeQuestionId);
            e.stopPropagation();
            return false;
        });
    }
    $(".ui-dialog-titlebar").hide();
    $(".dlgfixed").center(false);
    $('#help_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('.ui-button-text:contains("Re-Download"),.ui-button-text:contains("Delete")')
            .parents('.ui-button').after('<div class="dialog_button_seperator"></div>');
    $('.ui-dialog-buttonset .ui-button').css({
        "width": "32.8%"
    });
    $('html body .ui-dialog').css({'z-index': '99999999',
        background: '#0080C6', 'color': '#fff', 'position': 'fixed', 'outline': 'none'});
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
    $('#help_dialog_close').bind('click', function () {
        $('#help_dialog').dialog('close');
    });
    $('#hint_quiz').effect("shake", "", 300, "");
}

TruefalseView.prototype.getChosen_value = function (button) {
    var correct_ids = new Array();
    var option = this.option;
    var isCorrect = false;
    var selected = document.getElementById('edit-tries[answer]-' + option);
    if (this.question['correct_answer'] == selected.value) {
        correct_ids.push(this.question['correct_answer']);
        isCorrect = true;
    }
    this.correct_ids = correct_ids;
};
TruefalseView.prototype.checkUserCorrectAnswer = function (button, quizAttrs) {
    var THIS = this;
    var skip = 0, correct = 0, answer = new Array(), wrong_answer = false;
    var option = this.option;
    var correct_answer = this.question['correct_answer'];
    var user_score = 0;
    var tempArray = new Array();
    var max_score=this.question.max_score;
//     var userval=document.getElementById('edit-tries[answer]-' +option);
//    $.each(this.question.alternatives, function (key, value) {
    $('tbody tr').each(function () {
        var button_type = $(this).find('input').attr('type');
        var id = $(this).attr('value');
        var userval = document.getElementById('edit-tries[answer]-' + id);
        if (button_type == 'checkbox') {
            var selected = $(this).find('input.form-checkbox').is(':checked');
            if (selected) {
                if (value['id'] == id && value['score_if_chosen'] == 1) {
                    correct = 1;
                    answer.push(value['id']);
                }
                if (THIS.localeQuestion.type == PRACTICE) {
                    if (correct_answer == id) {
                        $('#option' + id).addClass('correctAnswer');
                    } else {
                        $('#option' + id).addClass('wrongAnswer');
                        wrong_answer = true;
                    }
                } else {
                    if (correct_answer == id) {
                        $('#option' + id).addClass('selectedOption');
                    } else {
                        $('#option' + id).addClass('selectedOption');
                        wrong_answer = true;
                    }
                }
            }
        } else {
            var selected = $(this).find('input.form-radio').is(':checked');
            if (selected) {
                tempArray.push(userval.value);
                if (correct_answer == userval.value) {
                    correct = 1;
                    user_score=max_score;
                    answer.push(correct_answer);
                }
                if (THIS.localeQuestion.type == PRACTICE) {
                    if (correct_answer == userval.value) {
                        $('#option' + id).addClass('correctAnswer');
                    } else {
                        $('#option' + id).addClass('wrongAnswer');
                        wrong_answer = true;
                    }
                } else {
                    if (correct_answer == userval.value) {
                        $('#option' + id).addClass('selectedOption');
                    } else {
                        $('#option' + id).addClass('selectedOption');
                        wrong_answer = true;
                    }
                }
            }
        }
        if (typeof quizAttrs != "undefined") {
            if ((quizAttrs.repeat_until_correct != "undefined" && quizAttrs.repeat_until_correct != 1) && (typeof quizAttrs.mode == "undefined" || quizAttrs.mode != "review")) {
                $('.quiz_practice .correctAnswer .quiz_tick, .quiz_practice .wrongAnswer .quiz_tick').css('background', 'none');
            }
        }
    });
//    });

    if (tempArray.length == 0) {
        skip = 1;
    }

    if (wrong_answer == true) {
        correct = 0;
        if (parseInt(this.question.negative_score) != 0 && skip != 1 && (typeof this.question.negative_score != 'undefined' && this.question.negative_score)) {
            user_score = -(parseInt(this.question.negative_score));
        }
    }
    return  {'correct': correct, 'skip': skip, 'answer': answer,'user_score':user_score};
};
TruefalseView.prototype.checkUserScore = function(button, skip_flag, quizAttrs) {
    var tempArray = new Array();
    var option = this.option;
    var option_json = {};
    var question_result = {};
    var skip = 0, correct = 0, answer = new Array(), param;
    $('tbody tr').each(function () {
        var button_type = $(this).find('input').attr('type');
        if (button_type == 'checkbox') {
            var selected = $(this).find('input.form-checkbox').is(':checked');
            if (selected) {
                tempArray.push($(this).attr('value'));
            }
        } else {
            var selected = $(this).find('input.form-radio').is(':checked');
            if (selected) {
                tempArray.push($(this).attr('value'));
            }
        }
    });
    if (tempArray.length === 0) {
        skip = IS_SKIPPED['skipped'];
    }
    if (skip_flag == true) {
        if (tempArray.length === 0)
            skip = IS_SKIPPED['review_skipped'];
        else
            skip = IS_SKIPPED['review_answered'];
    }
    param = this.checkUserCorrectAnswer(button,quizAttrs);
    option_json['user_result_answer'] = {
        'nid': this.question.nid,
        'is_correct': param.correct,
        'is_skipped': skip,
        'answer_timestamp': getSyncedServerTime(),
        'answer': param.answer,
        'user_answer': tempArray,
        'points_awarded':param.user_score,
        'questiontype': 'truefalse',
        'start_time_per_question':this.start_time

    };
    return  option_json;
};
TruefalseView.prototype.showCorrectOptions = function (question_value, mode) {
    var THIS = this;
    var correct_answer = question_value['correct_answer'];
    var user_checked = new Array();
    try {
         $('tbody tr').each(function () {
             var selected = $(this).find('input.form-radio').is(':checked');
                if (selected) {
                     user_checked.push($(this).attr('value'));
                 }
         });
        $('tbody tr').each(function () {
            var button_type = $(this).find('input').attr('type');
            if (correct_answer == $(this).attr('value')) {
            var t = document.getElementById('option' + $(this).attr('value'));
            THIS.selectAnswer(t, $(this).attr('value'), true);
            $('#innerQuizContainer').append('<div id="explaination">');
            // $('#explaination').html($('.explaination_div_hidden').html());
            $('.explaination_div_content').html('');
            $('#flipQuestion_showanswer').hide();
            $('#show_solution_wrapper').hide();
            if (mode == 'review') {
                $('#option' + $(this).attr('value')).addClass('correctAnswer');
                }
            } else {
                $('#option' + $(this).attr('value')).removeClass('wrongAnswer');
                document.getElementById('edit-tries[answer]-' + $(this).attr('value')).checked = false;
            }
//        });


        });
        if (mode == 'review' || this.localeQuestion.type == PRACTICE) {
            for (var i in user_checked) {
                $('#option' + user_checked[i]).addClass('wrongAnswer');
            }
        }
//        $.each(question_value, function (key, value) {
//        if (value['score_if_chosen'] == 1) {


    } catch (e) {
        console.log(e.message);
    }
    ;
};
TruefalseView.prototype.resetOptions = function (button) {
    //  if(this.question.type==PRACTICE){
    $('#flipQuestion_showanswer').hide();
    $('#show_solution_wrapper').hide();
    $('#hint_quiz').remove();
    if (button.type == 'radio') {
        $('.question_wrapper').removeClass('correctAnswer');
        $('.question_wrapper').removeClass('wrongAnswer');
        $('.question_wrapper').addClass('unselected');
        $('.question_wrapper').removeClass('selectedOption');
        $('#explaination').remove();
    } else {
        if (button.checked == true) {
            $('#option' + button.value).removeClass('wrongAnswer');
            $('#option' + button.value).removeClass('correctAnswer');
            $('#option' + button.value).removeClass('selectedOption');
        }
        $('#explaination').remove();
        $('.question_wrapper').addClass('unselected');
    }
    //  }
};
//got to change
TruefalseView.prototype.setPreviousData = function(question, passrate, quizAttrs) {
    var THIS = this;
    var nids = new Array();
    var correct_answer = this.localeQuestion['correct_answer'];
    if (typeof this.question['question_result'] !== 'undefined') {
        nids = this.question['question_result']['user_answer'];
    }
    //-----------------set previous data--------------------------//
    try {
        for (var i = 0; i < nids.length; i++) {
            document.getElementById('edit-tries[answer]-' + nids[i]).checked = true;
            $('#option' + nids[i]).addClass('selectedOption');
            if (this.localeQuestion.type == PRACTICE) {
                var question_value = this.localeQuestion.alternatives;
                $('tbody tr').each(function () {
                    if (CHECK_ON_PASS_RATE != undefined && CHECK_ON_PASS_RATE == 'true' && passrate != undefined && passrate == 0) {
                        if ((correct_answer == nids[i])) {
                            $('#option' + correct_answer).addClass('correctAnswer');
                        }
                    }
                    else {
                        if (correct_answer == nids[i]) {
                            $('#option' + nids[i]).addClass('correctAnswer');
                        } else {
                            $('#option' + nids[i]).addClass('wrongAnswer');
                        }
                    }
                    if (typeof quizAttrs != "undefined") {
                        if (quizAttrs.repeat_until_correct != "undefined" && quizAttrs.repeat_until_correct != 1) {
                            $('.quiz_practice .correctAnswer .quiz_tick, .quiz_practice .wrongAnswer .quiz_tick').css('background', 'none');
                        }
                    }
                });
            }
        }

    } catch (e) {
    }
    ;
};
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}










SummaryView.prototype = new View();
SummaryView.prototype.constructor = SummaryView.constructor;
function SummaryView() {
    this.quiz = CONTROLLER.getService("Quiz");
    this.mode = 'all';
    this.modeCount = [];
    this.allQuestions = null;
    this.currentQuestionView = null;
    this.currentQuestionIndex = 0;
    this.currentQuestionMode = [];
    this.activeQuizLanguage = '';
}
;

SummaryView.prototype.render = function (params) {
    this.setDefaultStyles(params);
    this.renderHeader(params);
    this.renderContent(params);
    this.renderFooter(params);
    this.setbodystyle(params);
    this.handleDeviceResize(params);
};

SummaryView.prototype.setDefaultStyles = function () {
    $(window).off("resize");
    $('body, #wrapper, #header_wrapper, #header, #content_wrapper, #quiz_wrapper, #footer_wrapper, #footer_wrapper .footer').css({
        "width": "100vw"
    });
    $('body, #wrapper').css({
        "height": "100vh"
    });
    $('#content_wrapper, #quiz_wrapper').css({
        "max-height": "100vh",
        "overflow-y": "auto"
    });
};

SummaryView.prototype.handleDeviceResize = function (params) {
    this.setDefaultStyles();
    var THIS = this;
    $(window).off("resize");
    $(window).on("resize", function () {
        $(".title_text").css("width", $(window).width() - 170);
        THIS.setbodystyle(params);
    });
};

SummaryView.prototype.renderHeader = function(params) {
    var title = '';
    this.mode = params.mode;
    if (this.mode == 'correct') {
        title = 'Correct Questions';
    } else if (this.mode == 'unanswered') {
        title = 'Unattempted Questions';
    } else if (this.mode == 'eval_skip_correct') {
        title = 'Correct - Evaluation Skipped';
    } else if (this.mode == 'eval_skip_wrong') {
        title = 'Incorrect - Evaluation Skipped';
    } else if (this.mode == 'wrong') {
        title = 'Incorrect Questions';
    } else if (this.mode == 'all') {
        title = 'All Questions';
    }else if (this.mode == 'attempted') {
        title = 'Attempted Questions';
    }
    if (typeof params.parentView != 'undefined' && params.parentView == 'QuestionList') {
        $('.title_text').html('Test Summary');
        $('#sub_title').html('Solutions');
    } else {
        this.renderTemplate("quiz_header.tl", {'title': title}, "#header_wrapper", true);
    }
};

SummaryView.prototype.renderFooter = function(params) {
    var THIS = this;
    if(typeof params.parentView == 'undefined' && params.parentView != 'QuestionList'){
         this.renderTemplate("quiz_footer.tl", "", "#footer_wrapper", true);
    }
    //Prebview and next call back
    $('.quiz_back').click(function(e) {
        THIS.quizBack();
    });

    $('.quiz_next').click(function(e) {
        THIS.quizNext();
    });
};

SummaryView.prototype.quizNext = function() {
    var ParentView = CONTROLLER.getVariable("activeParentView");
    var flag = this.getQuiznumberContext('next', false);
    if (flag == true) {
        this.renderQuestion(true);
    }
    else {
        if(ParentView == "TestSeries") {
            CONTROLLER.onBack();
            return;
        }
        var THIS = this;
        CONTROLLER.loadChildView('Certificatequiz', function(view) {
            view.allQuestions = THIS.allQuestions;
            THIS.currentQuestionView.name = 'ReportView';
            view.QuizCertificateSubmit();
            if (ParentView == 'DashBoardQuiz')
            {
                $('.quiz_back_button').addClass('hidden');
            }
        });
    }
};
SummaryView.prototype.quizBack = function() {
    var ParentView = CONTROLLER.getVariable("activeParentView");
    var flag = this.getQuiznumberContext('back', false);
    if (flag == true) {
        this.renderQuestion(true);
    }
    else {
        if(ParentView == "TestSeries") {
            CONTROLLER.onBack();
            return;
        }
        var THIS = this;
        CONTROLLER.loadChildView('Certificatequiz', function(view) {
            view.allQuestions = THIS.allQuestions;
            THIS.currentQuestionView.name = 'ReportView';
            if (ParentView == 'DashBoardQuiz')
            {
                $('.quiz_back_button').addClass('hidden');
            }
            view.QuizCertificateSubmit();
        });
    }
};

SummaryView.prototype.getQuiznumberContext = function(action, type) {
    var index = 0;
    if (this.modeCount) {
        if (action == 'next' && (this.currentQuestionMode.length) > 0) {
            if (this.currentQuestionMode.length == this.modeCount.length && type == false) {
                index = parseInt(this.modeCount.shift());
                this.modeCount.push(index);
                this.currentQuestionMode.pop();
            }
            index = parseInt(this.modeCount.shift());
            this.currentQuestionIndex = index;
            this.modeCount.push(index);
            this.currentQuestionMode.pop();
            return true;
        } else if (action == 'back' && this.currentQuestionMode.length < (this.modeCount.length)) {
            if (this.currentQuestionMode.length == 0) {
                index = parseInt(this.modeCount.pop());
                this.modeCount.unshift(index);
                this.currentQuestionMode.push(index);
            }
            index = parseInt(this.modeCount.pop());
            this.currentQuestionIndex = index;
            this.modeCount.unshift(index);
            this.currentQuestionMode.push(index);
            return true;

        } else {
            return false;
        }
    }
};

SummaryView.prototype.renderQuestion = function(type)
{
    var THIS = this;
    if(this.allQuestions.replace_item != 'undefined' && this.allQuestions.replace_item == '#content_wrapper'){
        var replace_item = '#content_wrapper';
    }else{
        var replace_item = '#quiz_wrapper';
    }
    this.activeQuizLanguage = CONTROLLER.getVariable('activeQuizLanguage');
    var params = {"quiz": this.allQuestions.questions[this.currentQuestionIndex]
        , "index": this.currentQuestionIndex
        , "quizAttrs": this.allQuestions
        , "total_questions": this.allQuestions.questions.length
        , "type": 'practice'
        , "replace_item": replace_item
        , "replace": true
        , "mode": 'review'
        , "activeQuizLanguage": this.activeQuizLanguage
    };

    if(typeof this.allQuestions.questions[this.currentQuestionIndex].updatedLocale != 'undefined'){
	    var updatedLocale = this.allQuestions.questions[this.currentQuestionIndex].updatedLocale;
	    if (typeof this.allQuestions.language != 'undefined' && this.allQuestions.language.length > 1 && typeof updatedLocale != 'undefined' && Object.keys(updatedLocale).length > 1) {
	        params.localeInfo = this.quiz.getQuestionLocaleInfo(this.allQuestions.language, this.activeQuizLanguage);
	    }
	}
    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
        var id = CONTROLLER.getVariable('breakquizid');
        params.replace_item = '#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id;
    }
    if(this.allQuestions.questions[this.currentQuestionIndex].questiontype == undefined){
        this.allQuestions.questions[this.currentQuestionIndex].questiontype = this.allQuestions.questions[this.currentQuestionIndex].type;
    }
    if(this.allQuestions.questions[this.currentQuestionIndex].questiontype == "matching") {
        CONTROLLER.loadChildView('Match', function (view) {
           THIS.renderView(type, view, params);
        });
    }
    else if(this.allQuestions.questions[this.currentQuestionIndex].questiontype == "short_answer") {
        CONTROLLER.loadChildView('ShortAnswer', function (view) {
            THIS.renderView(type, view, params);
        });
    }
    else if (this.allQuestions.questions[this.currentQuestionIndex].questiontype == "truefalse") {
        CONTROLLER.loadChildView('Truefalse', function (view) {
            THIS.renderView(type, view, params);
        });
    }
    else if (this.allQuestions.questions[this.currentQuestionIndex].questiontype == "long_answer") {
        CONTROLLER.loadChildView('LongAnswer', function (view) {
            THIS.renderView(type, view, params);
        });
    }
    else {
        CONTROLLER.loadChildView('Mcq', function(view) {
           THIS.renderView(type, view, params);
        });
    }
};


SummaryView.prototype.renderContent = function(params) {
    this.modeCount = [];
    this.mode = params.mode;
    if(typeof params.parentView != 'undefined'){
        this.parentView = params.parentView;
    }
    this.allQuestions = params.report;
    this.activeQuizLanguage = params.activeQuizLanguage;
    this.getQuizQuestions(params);
    this.getQuiznumberContext('next', true);
    this.renderQuestion(true);
    //this.renderTemplate('certificate_graph_report.tl',params['report'],'#quiz_wrapper', true);

};


SummaryView.prototype.getQuizQuestions = function(params) {
    this.getSelectedModeQuestions(this.mode, params.report.questions, params)
};

SummaryView.prototype.renderSummaryGraphReport = function(params) {
    var id=CONTROLLER.getVariable('breakquizid');
    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
        var replace ='#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id;
        this.renderTemplate('certificate_graph_report.tl', params['report'], replace, true);
        $('#footer_wrapper').remove();
    }else{
        this.renderTemplate('certificate_graph_report.tl', params['report'], '#quiz_wrapper', true);
    }
    $('#wrapper').css('overflow','auto');
};

SummaryView.prototype.setbodystyle = function(params)
{
    var ParentView = CONTROLLER.getVariable("activeParentView");
    var screenwidth = $(window).width() + "px";
    var screenheight = $(window).height() + "px";
    $("body,#wrapper").css({
        width: screenwidth,
        height: screenheight
    });
    $('#quiz_wrapper').css({
        width: screenwidth,
        height: 'auto'
    });
    $("#wrapper").css("overflow","auto");
    $("#content_wrapper").css({
        width: screenwidth,
        height: (screenheight - 63)
    });

    $("#header_wrapper").css({
        width: screenwidth + "px"
    });

    $('#header_wrapper').css('width', $(window).width());
    $('html body').css('overflow', 'auto');
    //  $('#footer_wrapper').addClass('hidden');
    $('#question_nav_show').remove();
    $('.quiz_header_menu').addClass('hidden');
    var catid = CONTROLLER.getVariable("activecatid");

    if($('#header_wrapper').css('position') == 'fixed'){
        if (ParentView != "DashBoardQuiz") {
            if ($(window).width() < 380) {
                $('#quiz_wrapper').css({'margin-top': '40px'});
                $('#wrapper').css({'overflow-x': 'auto'});
            }
            else {
                $('#quiz_wrapper').css({'margin-top': '50px'});
            }
        }
        else{
            if ($(window).width() < 380) {
                $('#quiz_wrapper').css({'margin-top': '12px'});
            }
            else {
                $('#quiz_wrapper').css({'margin-top': '12px'});
            }
        }
    }
        if($(window).width()<380){
            $(".title_text").css("width", $(window).width() - 163);
        }
        else{
            $(".title_text").css("width", $(window).width() - 170);
        }
    if (typeof this.parentView != 'undefined' && this.parentView == 'QuestionList') {
        QuestionListView.prototype.setbodystyle();
        $(".title_text").css({"width": $(window).width() - 100, 'line-height': '29px', "margin-top": '12px'});
        if ($(window).width() > 380) {
            $(".title_text").css("width", $(window).width() - 150);
        }
    }
};

SummaryView.prototype.onBack = function()
{
    var ParentView = CONTROLLER.getVariable("activeParentView");
    if(ParentView=="DashBoardQuiz"){
       CONTROLLER.loadView('DashBoardQuiz');
    }
     var courseid = "";
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        courseid = QUIZ_RESOURCE_PARAMS.courseid;
    }
    else {
        courseid = CONTROLLER.getVariable("activecourseid");
    }
    CONTROLLER.loadView("Course", courseid);
};

SummaryView.prototype.renderSummaryQuestion = function(params) {
    var reports = [];
    var id = params.mode;
    reports['report'] = params.report;
    if (params.mode == 'all') {
        reports['report'] = params.report;
    } else if (id == 'answered') {
        reports['report']['questions'] = this.getSelectedModeQuestions(id, reports['report']['questions']);
    } else if (id == 'unanswered') {
        reports['report']['questions'] = this.getSelectedModeQuestions(id, reports['report']['questions']);
    } else if (id == 'revise') {
        reports['report']['questions'] = this.getSelectedModeQuestions(id, reports['report']['questions']);
    }
    this.renderTemplate('certificate_summary_report.tl', reports, '#quiz_wrapper', true);
    this.setSummaryTitle();

};

SummaryView.prototype.setSummaryTitle = function()
{
    var courseid = ""; var catid = "";
    var title = "";
    var preView = CONTROLLER.getVariable("previousView");
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        courseid = QUIZ_RESOURCE_PARAMS.courseid;
        catid = QUIZ_RESOURCE_PARAMS.catid;
    }
    else {
        courseid = CONTROLLER.getVariable("activecourseid");
        catid = CONTROLLER.getVariable("activecatid");
    }
    if(typeof preView !== undefined && preView === "AllSubjects"){
        title = "Quiz";
    }
    else if (typeof preView !== undefined && preView === "TestSeries") {
      return TESTSERIES_TITLE;
    }
    else{
        var courseService = CONTROLLER.getService("Course");
        title = courseService.getTitle(catid, courseid, CONTROLLER.getVariable("activegroupid"));
    }
    $('.title_text').html(title);
};

SummaryView.prototype.renderView = function(type, view, params)
{
    var THIS = this;
    view.renderContent(params);
    THIS.currentQuestionView = view;
   if(typeof this.parentView == 'undefined' &&  this.parentView != 'QuestionList'){
        $('#footer_wrapper').removeClass('hidden');
   }
   params.quizAttrs['mode'] = params.mode;
    if (type == true) {
        THIS.currentQuestionView.setPreviousData(THIS.allQuestions.questions[THIS.currentQuestionIndex], params.quizAttrs['pass_rate']);
    }
    if (CHECK_ON_PASS_RATE != undefined && CHECK_ON_PASS_RATE == 'true') {
        if (params.quizAttrs['pass_rate'] > 0) {
            if (this.allQuestions.questions[this.currentQuestionIndex].questiontype == "truefalse") {
                view.showCorrectOptions(THIS.allQuestions.questions[THIS.currentQuestionIndex], 'review');
            }else{
            view.showCorrectOptions(THIS.allQuestions.questions[THIS.currentQuestionIndex].alternatives, 'review');
        }
        }
    }
    else {
        if (this.allQuestions.questions[this.currentQuestionIndex].questiontype == "truefalse") {
            view.showCorrectOptions(THIS.allQuestions.questions[THIS.currentQuestionIndex], 'review');
        } else {
            view.showCorrectOptions(THIS.allQuestions.questions[THIS.currentQuestionIndex].alternatives, 'review');
        }
    }
    //Previous and next call back on swipe
    $("#swipe_wrapper").swipe('destroy');
    $("#swipe_wrapper").swipe({
        swipeLeft: function() {
           if(!$('#content_wrapper').length > 0){
               THIS.quizNext();
           } else if(CONTROLLER.activeViewName == 'TestSummary'){
               THIS.quizNext();
           }
        },
        swipeRight: function() {
            if (!$('.quiz_back').hasClass('flipQuestionback_light')) {
                if (!$('#content_wrapper').length > 0) {
                    THIS.quizBack();
                } else if(CONTROLLER.activeViewName == 'TestSummary'){
                    THIS.quizBack();
                }
            }
        }
    });
    if(typeof this.parentView != 'undefined' &&  this.parentView == 'QuestionList'){
        this.setbodystyle();
    }
    $('#certificate_placeholder').show();
    $('#languageSelection').css('height', '50px');
    $('#countDown, #revise_back').hide();
    var body_height=$('body').height();
    $('#quizContainer_wrap').css('height',(body_height-145));
    this.bindLanguageChange();
    if ($('.skipped_for_eval').length == 0 && typeof THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'] != "undefined" && THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['is_skipped'] == 4) {
        $('#certificate_placeholder').append('<div class="skipped_for_eval">Skipped for evaluation</div>')
    }
    if (typeof MathJax != "undefined") {
        MathJax.Hub.Queue(["Typeset",MathJax.Hub,'quiz_wrapper']);
    }
};

SummaryView.prototype.bindLanguageChange = function () {
    var THIS = this;
    $('#selectedLang').on('change', function (e) {
        $('#selectedLang').off();
        var optionSelected = $("option:selected", this);
        THIS.activeQuizLanguage = this.value;
        CONTROLLER.setVariable('activeQuizLanguage', this.value);
        THIS.renderQuestion(true);
    });
};

SummaryView.prototype.getSelectedModeQuestions = function (id, questions, params) {
    var filterQuestions = questions;
    if (params && params['filter']) {
        var filterKey = params['filter']['key'];
        var filterType = params['filter']['type'];
        if (filterType == OBJECT_CATEGORY) {
            var filterId = params['filter']['id'];
            filterQuestions = filterQuestions.filter(function (value) {
                return value[filterKey].indexOf(filterId) > -1;
            });
        } else if (filterType == OBJECT_COURSE) {
            filterQuestions = filterQuestions.filter(function (value) {
                return params['filter']['questions'].indexOf(value[filterKey]) > -1;
            });
        }
    }
    var reports = [];
    this.modeCount = [];
    this.currentQuestionMode = [];
    reports['report'] = '';
    var count = 0;
    for (var i in questions) {
        if (!isNaN(i)) {
            var isPresent = false;
            if (params && params['filter']) {
                var index = findIndexByKeyValue(filterQuestions, 'nid', questions[i].nid);
                if (index != null) {
                    isPresent = true;
                }
            } else {
                isPresent = true;
            }
            if (isPresent) {
                if (id == 'answered') {
                    if (typeof questions[i].question_result !== 'undefined') {
                        if ((questions[i].question_result.is_correct == 0 && questions[i].question_result.is_skipped == IS_SKIPPED['answered']) || (questions[i].question_result.is_correct == 1 && questions[i].question_result.is_skipped == IS_SKIPPED['answered'])) {
                            reports[count++] = questions[i];
                        }
                    }
                } else if (id == 'revise') {
                    if (typeof questions[i].question_result !== 'undefined') {
                        if (questions[i].question_result.is_skipped == IS_SKIPPED['review_answered'] || questions[i].question_result.is_skipped == IS_SKIPPED['review_skipped']) {
                            reports[count++] = questions[i];
                            this.modeCount.push(parseInt(i));
                            this.currentQuestionMode.push(parseInt(i));
                        }
                    }
                } else if (id == 'unanswered') {
                    if (typeof questions[i].question_result === 'undefined') {
                        reports[count++] = questions[i];
                        this.modeCount.push(parseInt(i));
                        this.currentQuestionMode.push(parseInt(i));
                    } else {
                        if (questions[i].question_result.is_correct == 0 && (questions[i].question_result.is_skipped > IS_SKIPPED['answered']
                                && (questions[i].question_result.user_answer == null || questions[i].question_result.user_answer.length == 0 || Object.keys(questions[i].question_result.user_answer).length == 0))){
                            reports[count++] = questions[i];
                            this.modeCount.push(parseInt(i));
                            this.currentQuestionMode.push(parseInt(i));
                        }
                    }
                } else if (id == 'correct') {
                    if (typeof questions[i].question_result !== 'undefined') {
                        if ((questions[i].question_result.is_correct == 1)) {
                            reports[count++] = questions[i];
                            this.modeCount.push(parseInt(i));
                            this.currentQuestionMode.push(parseInt(i));
                        }
                    }
                } else if (id == 'wrong') {
                    if (typeof questions[i].question_result !== 'undefined') {
                        if ((questions[i].question_result.is_correct == 0 && (questions[i].question_result.is_skipped == IS_SKIPPED['answered'] || questions[i].question_result.is_skipped == 4))) {
                            reports[count++] = questions[i];
                            this.modeCount.push(parseInt(i));
                            this.currentQuestionMode.push(parseInt(i));
                        }
                    }
                } else if (id == 'attempted') {
                    if (typeof questions[i].question_result !== 'undefined') {
                        if ((questions[i].question_result.is_correct == 0 && (questions[i].question_result.is_skipped == IS_SKIPPED['answered'] || questions[i].question_result.is_skipped == 4) || questions[i].question_result.is_correct == 1 && (questions[i].question_result.is_skipped == IS_SKIPPED['answered'] || questions[i].question_result.is_skipped == 4))) {
                            reports[count++] = questions[i];
                            this.modeCount.push(parseInt(i));
                            this.currentQuestionMode.push(parseInt(i));
                        }
                    }
                }else if (id == 'all') {
                    this.modeCount.push(parseInt(i));
                    this.currentQuestionMode.push(parseInt(i));
                }
            }
        }
    }
    //this.currentQuestionMode=this.modeCount.length;
    return reports;
};

